import Joi from 'joi';
import i18nProvider from '../../../i18n';

export const joiTablesRestaurantSchema = Joi.object().keys({
    id_restaurant: Joi.required().messages({
        'any.required': `Le restaurant est obligatoire.`
    }),
    id_table: Joi.string().regex(/^[0-9]{1,4}$/).max(4).required().messages({
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.TABLE_NUM'),
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.TABLE_NUM'),
        'string.max': i18nProvider.translate('VALIDATION.TYPE.TABLE_NUM_MAX'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.TABLE_NUM'),
        'string.pattern.base': i18nProvider.translate('VALIDATION.TYPE.TABLE_NUM'),
    }),
  }).unknown();
