import * as React from "react";
import {
  TextField,
  EditButton,
  CreateButton,
  DeleteButton,
  DateField,
  Button, TopToolbar,
  useTranslate,
  useRecordContext,
  useNotify,
  useRefresh,
  usePermissions,
  useResourceContext,
  useCreate,
  useGetOne,
  useRedirect,
} from 'react-admin';
import {
  TableChartOutlined,
  CalendarTodayOutlined,
  Settings,
  CropFree, 
  SettingsOverscan,
  QrCode2,
  Info
} from '@mui/icons-material';
import { muiIconStyles, btnStyles, listStyles } from "../../styles/Styles";
import ListComponent from "../core/ListComponent";
import { Checkbox, Box, Select, MenuItem, Stack, IconButton } from '@mui/material';
import { useState, useEffect } from "react";
import DownloadMultipleQrCode from './DownloadMultipleQrCode';
import TemplateModal from "./template-modal";
import QrCodesModal from "./qr-codes-modal";
import { downloadBase64ToPDF } from "../../utils/pdf";
import { getRoleAndPermissions } from "../../utils/utils";
import ListCellHeader from "../core/ListCellHeader";
import { useSelectAppBarStyles, useMenuItemListStyles } from "../../styles/Classes";
import { listMenuItemStyle, colors } from "../../styles/Styles";
import RestrictionModal from "../Settings/restrictionModal";


const TablesRestaurantList = props => {
  const idRestaurant = localStorage.getItem('idRestaurant');
  const isLoggedInByRoleTech = (localStorage.getItem('loggedInByRole') === 'tech');
  const globalPermissions = usePermissions();
  const resource = useResourceContext();
  const { permissions } = getRoleAndPermissions(globalPermissions.permissions, resource);
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [openModalQRCode, setOpenModalQRCode] = useState(false);
  const [currentQRCode, setCurrentQRCode] = useState(false);
  const [selectedTables, setSelectedTables] = useState([]);
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(false);
  const [openModalRestrictions, setOpenModalRestrictions] = useState(false);
  const [modalRestrictionTitle, setModalRestrictionTitle] = useState('');
  const [hasRestrictions, setHasRestrictions] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState();
  const [downloadQrCodes, { isLoading: isLoadingDownloadQrCodes, error: errorDownloadQrCodes, data: dataDownloadQrCodes }] = useCreate();
  const { data: restrictions, isLoading: isLoadingRestrictions } = useGetOne(`settings/account`, { id: idRestaurant });

  const classesSelect= useSelectAppBarStyles();
  const classesMenuItem = useMenuItemListStyles();
  const MenuProps = {
    PaperProps: {
       style: {
         borderRadius: 0,
         '& ul.MuiList-root': { padding:'0 !important'}
       },
     },
  };

  const QRCODE_TEMPLATES = [
    {
      id: 'A4', 
      name: t('QRCODE.TEMPLATES.A4.TITLE'), 
      preview: {
        actif: true,
        fr: 'TEMPLATE_BANANA_A4.png',
        es: 'TEMPLATE_BANANA_A4-ES.png'
      },
      description: t('QRCODE.TEMPLATES.A4.DESCRIPTION')
    },
    {
      id: 'CARTE', 
      name: t('QRCODE.TEMPLATES.CARD.TITLE'), 
      preview: {
        actif: true,
        fr: 'TEMPLATE_BANANA_CARTE-VISITE.png',
        es: 'TEMPLATE_BANANA_CARTE-VISITE-ES.png'
      },
      description: t('QRCODE.TEMPLATES.CARD.DESCRIPTION')
    },
    {
      id: 'PLEXI', 
      name: t('QRCODE.TEMPLATES.PLEXI.TITLE'), 
      preview: {
        actif: true,
        fr: 'TEMPLATE_BANANA_PLEXI.png',
        es: 'TEMPLATE_BANANA_PLEXI-ES.png'
      },
      description: t('QRCODE.TEMPLATES.PLEXI.DESCRIPTION')
    },
    {
      id: 'QRCODE_POUR_ETIQUETTES', 
      name: t('QRCODE.TEMPLATES.QRCODE_FOR_STICKER.TITLE'), 
      preview: {
        actif: false,
        fr: '',
        es: ''
      },
      description: ''
    }
  ];

  
  useEffect(() => {
  }, [currentQRCode, selectedTables, currentTemplate ])

  useEffect(() => {
    if (!isLoadingDownloadQrCodes && !errorDownloadQrCodes && dataDownloadQrCodes) {
      const base64String = dataDownloadQrCodes.pdf
      notify('QRCODE.DOWNLOAD', 'success', { messageArgs: { name: t('QRCODE.DOWNLOAD') } });  // Fix warnings de trad
      // DL ALL QR -CODE
      downloadBase64ToPDF(base64String, `qrcode-${dataDownloadQrCodes.template}`);
    }
    else if (errorDownloadQrCodes)
      notify(t('QRCODE.ERRORS.DOWNLOAD_FAIL'), { type: 'error' });
  }, [isLoadingDownloadQrCodes]);

  useEffect(() => {
    if (!isLoadingRestrictions && restrictions) setHasRestrictions(restrictions?.actif);
  }, [isLoadingRestrictions]);

  if (!permissions) return null;
  if (isLoadingRestrictions) return null;

  
  const toggleOpenQRCodeModal = (record) => {
    const currentTable = {
      id_table: record.id_table,
      lien_unique: record.lien_unique
    }
    if(!openModalQRCode) setCurrentQRCode(currentTable)
    else setCurrentQRCode(false)
    setOpenModalQRCode(!openModalQRCode)
  }

  const toggleOpenModaleTemplate = (id) => {
    if(!openModalTemplate) setCurrentTemplate(id);
    else setCurrentTemplate(false);
    setOpenModalTemplate(!openModalTemplate);
  }

  const selectTable = (isChecked, idTable) => {
    if(isChecked)
      setSelectedTables((initial) => [...initial, idTable]);
    else if(!isChecked)
      setSelectedTables((initial) => initial.filter(t => t != idTable))
  }

  const refreshList = () => {
    setSelectedTables([]);
    refresh();
  }

  const handleCloseModalrestrictions = () => {
    setOpenModalRestrictions(false);
  };

  const handleSubmitModalrestrictions = () => {
    setOpenModalRestrictions(false);
    if (recordToEdit) { redirect('edit', resource, recordToEdit); setRecordToEdit(); }
    else redirect('create', resource);
  }

  const TablesRestaurantListActions = () => {
    
    const renderItem = ({name, id, preview}) => {
      const itemStyle = listMenuItemStyle(colors.primary);
      return <Box sx={itemStyle.box}>
        <Stack direction="row">
          <MenuItem value={id} classes={classesMenuItem} sx={btnStyles.primary} onClick={() => downloadQrCode(id)}>{name}</MenuItem>
          {preview.actif && <Box sx={itemStyle.boxLink}>
            <IconButton color="neutral" onClick={() => toggleOpenModaleTemplate(id)}>
              <Info sx={itemStyle.infoIcon} />
            </IconButton>
          </Box>}
        </Stack>
      </Box>
    };

    const handleCreate = (e) => {
      if (hasRestrictions) {
        setModalRestrictionTitle('MODAL_RESTRICTION.QRCODE_CREATE_TITLE');
        setOpenModalRestrictions(true);
        e.preventDefault();
      }
    };

    const items = QRCODE_TEMPLATES.map(t => renderItem(t));
    return (
      <TopToolbar sx={listStyles.topToolbar.leftSideButtons}>
        <CreateButton sx={{ ...btnStyles.secondary, textTransform: 'capitalize', fontSize: "15px" }} label={t('COMMON.QRCODE_ADD')} onClick={handleCreate} disabled={isLoggedInByRoleTech} />
        <Select MenuProps={MenuProps} classes={classesSelect} value="default" defaultValue={"default"} sx={{...btnStyles.primary, '& .MuiOutlinedInput-notchedOutline': {border: 'none' } }} disabled={isLoggedInByRoleTech}>
          <MenuItem value="default" sx={{ display: 'none' }} classes={classesMenuItem}  > <QrCode2 sx={{ marginRight: '8px' }} /> {t('QRCODE.DOWNLOAD_ALL')}</MenuItem>
            {items}
        </Select>
        {selectedTables.length ? <DownloadMultipleQrCode label={`${t('QRCODE.DOWNLOAD_SELECTED')} (${selectedTables.length})`} qrcodesTemplates={QRCODE_TEMPLATES} toggleOpenModaleTemplate={toggleOpenModaleTemplate} menuProps={MenuProps} selectedIds={selectedTables} refreshList={refreshList} disabled={isLoggedInByRoleTech} /> : null}
      </TopToolbar>
    )
  };

  const handleshowRestrictionsModal = (id) => {
    setRecordToEdit(id);
    setModalRestrictionTitle('MODAL_RESTRICTION.QRCODE_EDIT_TITLE')
    setOpenModalRestrictions(true);
  };

  const downloadQrCode = (template) => {
    downloadQrCodes(`qr-codes/get-pdf`, { data: { restaurant_id: idRestaurant, template } });
  };

  return (
    <div>
      <ModalQRCode open={openModalQRCode} onClose={toggleOpenQRCodeModal} currentQRCode={currentQRCode} t={t} />
      <ModalTemplateQRCode open={openModalTemplate} onClose={toggleOpenModaleTemplate} currentTemplate={currentTemplate} qrCodesTemplates={QRCODE_TEMPLATES} t={t} />
      <RestrictionModal title={t(modalRestrictionTitle)} open={openModalRestrictions} onClose={handleCloseModalrestrictions} handleSubmit={handleSubmitModalrestrictions} />
      <ListComponent {...props}
        actions={<TablesRestaurantListActions />}
        sort={{ field: 'id_table', order: 'ASC' }}
        perPage={25}
        id="tablesRestaurantList"
        filterDefaultValues={{ id_restaurant: localStorage.getItem('idRestaurant') }}
      >
        <CheckBoxInput selectedTables={selectedTables} label="" onChange={selectTable} />
        <TextField label={<ListCellHeader icon={<TableChartOutlined sx={muiIconStyles.primary} />} text={t('COMMON.TABLE')} />} source="id_table" textAlign="right" />
        {/* <StatusField label={<ListCellHeader icon={<Restaurant sx={muiIconStyles.secondary} />}  text={t('COMMON.STATUS')} />} source="reste_a_payer" t={t} /> */}
        <DateField locales="fr-FR" label={<ListCellHeader icon={<CalendarTodayOutlined sx={muiIconStyles.warning} />} text={t('COMMON.EDIT_DATE')} />} source="updated" showTime />
        <QRCodeButton label={<ListCellHeader icon={<CropFree sx={muiIconStyles.success} />} text={t('QRCODE.NAME')} />} onChange={toggleOpenQRCodeModal} disabled={isLoggedInByRoleTech} />
        {permissions.edit && <EditField label={<ListCellHeader icon={<Settings sx={muiIconStyles.primary} />} text={t('ACTIONS.EDIT')} />} showRestrictionsModal={handleshowRestrictionsModal} restrictions={restrictions} />}
      </ListComponent>
    </div>
  );
};

const ModalQRCode = (props) => {
  const url = `${process.env.REACT_APP_URL_PAYMENT_WEBSITE}?t=${props.currentQRCode.lien_unique}`;

  return <QrCodesModal
    open={props.open}
    onClose={props.onClose}
    url={url}
    id_table={props.currentQRCode.id_table}
    t={props.t}
  />
};

const ModalTemplateQRCode = (props) => {
  const id = props.currentTemplate;
  const template = props.qrCodesTemplates.find(t => t.id === id);
  if(!template) return null;
  return <TemplateModal
    open={props.open}
    onClose={props.onClose}
    template={template}
    t={props.t}
  />
};

const StatusField = ({ source, t }) => {
  const record = useRecordContext();
  const isOpenned = tableHasOpennedCommand(record[source]);
  const status = isOpenned ? t('QRCODE.OPENNED') : t('QRCODE.CLOSED');

  return (
    <div style={{ display: "flex", alignItems: "center", }}>
      <span style={{ whiteSpace: "nowrap" }}>{status}</span>
    </div>
  );
};

const EditField = ({ label, showRestrictionsModal, restrictions, ...props }) => {
  const record = useRecordContext();
  const disabled = tableHasOpennedCommand(record['reste_a_payer']);

  const onClick = (e) => {
    if (restrictions?.actif) {
      e.preventDefault();
      showRestrictionsModal(record.id);
    }
  };

  return <EditButton label="" {...props} disabled={disabled} onClick={onClick} />;
};
const DeleteField = ({ label, ...props }) => {
  const record = useRecordContext();
  const disabled = tableHasOpennedCommand(record['reste_a_payer']);

  return (
    <DeleteButton
      label=""
      {...props}
      confirmTitle={`${props.t('QRCODE.DELETE')} "${record.id_table}"`}
      confirmContent={props.t('QRCODE.DELETE_CONFIRMATION')}
      disabled={disabled}
    />
  );
};
const QRCodeButton = ({ label, disabled, ...props }) => 
{
  const record = useRecordContext();
  return <Button
    startIcon={<SettingsOverscan />}
    sx={muiIconStyles.success}
    onClick={() => props.onChange(record)}
    label="" {...props}
    disabled={disabled}
  />
};

const CheckBoxInput = (props) => {
  const record = useRecordContext();
  return <Checkbox label="" onChange={(event) => props.onChange(event.target.checked, Number(record.id))} />  
}

const tableHasOpennedCommand = (val) => {
  if (!val) return false;
  if (val == 0) return false;

  return true;
}

export default TablesRestaurantList;