import * as React from "react";
import { Typography, Modal, Button, Box } from '@mui/material';
import { modalBodyStyle, smallModalStyle } from '../../styles/Styles';

const ChangeActifMenuModal = ({ isOpen, carte, onClose, changeActifMenu, translate }) => {
  const action = translate((carte.actif === true) ? 'ACTIONS.DESACTIVATE' : 'ACTIONS.ACTIVATE');
  const message = `${translate('CARDS.CONFIRM_ACTIVATE', { action, nom: carte.com })}`;

  return <Modal
    open={isOpen}
    onClose={onClose}
  >
    <Box sx={smallModalStyle}>
      <Box sx={modalBodyStyle}>
        <Typography>{message}</Typography>
        {/* {(carte.actif === false) && <Typography>{translate('CARDS.WARNING_OTHER_CARDS_DESACTIVATE')} </Typography>} */}
        <Box>
          <Button size="small" color="success" variant="contained" sx={{ m: 1 }} onClick={changeActifMenu}>{translate('COMMON.YES')}</Button>
          <Button size="small" color="error" variant="contained" sx={{ m: 1 }} onClick={onClose}>{translate('COMMON.NO')}</Button>
        </Box>
      </Box>
    </Box>
  </Modal>
};

export default ChangeActifMenuModal;