import React from 'react';
import Box from '@mui/material/Box';
import { listTitleStyles } from '../../styles/Styles';

const ListTitle = (props) => {
  if(!props.content) return null;
  
  return <Box {...props} sx={{...listTitleStyles, ...props.extraStyle}}>
    {props.content}
  </Box>
};

export default ListTitle;