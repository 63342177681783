import Joi from 'joi';
import i18nProvider from '../../../i18n';

const nomSchema = Joi.string().max(50).required().messages({
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.NAME'),
    'string.max': i18nProvider.translate('VALIDATION.TYPE.NAME'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.NAME')
});
const emailUserSchema = Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
    'string.base': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
    'string.email': i18nProvider.translate('VALIDATION.TYPE.EMAIL'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL')
});
const idAgenceSchema = Joi.number().required().messages({
    'number.base': i18nProvider.translate('VALIDATION.MANDATORY.AGENCY'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.AGENCY')
});
const idCaisseSchema = Joi.number().required().messages({
    'number.base': i18nProvider.translate('VALIDATION.MANDATORY.TILL'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.TILL')
});
const clientNameSchema = Joi.string().regex(/^([A-Za-z\s]|[à-ü]|[À-Ü])*$/).allow(null).messages({
    'string.base': i18nProvider.translate('VALIDATION.MANDATORY.CLIENT_NAME'),
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.CLIENT_NAME'),
    'string.max': i18nProvider.translate('VALIDATION.TYPE.CLIENT_NAME_MAX'),
    'string.pattern.base': i18nProvider.translate('VALIDATION.TYPE.CLIENT_NAME'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.CLIENT_NAME'),
});
const raisonSocialeSchema = Joi.string().allow(null).messages({
    'string.base': i18nProvider.translate('VALIDATION.MANDATORY.BUSINESS_NAME'),
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.BUSINESS_NAME'),
    'string.max': i18nProvider.translate('VALIDATION.TYPE.BUSINESS_NAME'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.BUSINESS_NAME')
});
const signataireEmailSchema = Joi.string().email({ tlds: { allow: false } }).allow(null).messages({
    'string.base': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
    'string.email': i18nProvider.translate('VALIDATION.TYPE.EMAIL'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
});
const signatairePhoneSchema = Joi.string().regex(/^[0-9]+$/).allow(null).messages({
    'string.base': i18nProvider.translate('VALIDATION.MANDATORY.PHONE'),
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.PHONE'),
    'string.pattern.base': i18nProvider.translate('VALIDATION.TYPE.PHONE'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.PHONE'),
});
const siretSchema = Joi.string().regex(/^[0-9]{1,14}$/).max(14).allow(null).messages({
    'string.base': i18nProvider.translate('VALIDATION.MANDATORY.SIRET'),
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.SIRET'),
    'string.max': i18nProvider.translate('VALIDATION.TYPE.SIRET'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.SIRET'),
    'string.pattern.base': i18nProvider.translate('VALIDATION.TYPE.SIRET'),
});

export const editSchema = Joi.object({
    nom: nomSchema,
    adresse_mail_utilisateur: emailUserSchema,
    id_agence: idAgenceSchema,
    id_caisse: idCaisseSchema,
    metas: Joi.object({
        use_paygreen: Joi.boolean(),
        client_name: clientNameSchema,
        raison_social: raisonSocialeSchema,
        signataire_email: signataireEmailSchema,
        signataire_phone: signatairePhoneSchema,
        numero_siret: siretSchema,
        comment: Joi.string().allow(null).allow('').optional(),
    }),
}).unknown();