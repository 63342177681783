import React from 'react';
import ListTitle from '../core/ListTitle';
import GridItem from '../../styles/GridItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { muiIconStyles } from '../../styles/Styles';
import { CreditCardOutlined, PaymentsOutlined, EuroOutlined, PieChartOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Breadcrumb from '../core/BreadCrumb';
import { CustomTableHead, getComparator, stableSort } from '../../utils/ListFilter';
import { dashboardStyles } from '../../styles/Styles';
import { useTranslate, useGetList } from 'react-admin';
import { useState, useEffect } from 'react';
import { defaultWeekDateRangeFilter, formatNumber } from '../../utils/utils';

const TableauRepartitionMoyenPaiement = () => {
  const translate = useTranslate();
  const idRestaurant = localStorage.getItem('idRestaurant');
  const { startFilter, endFilter } = defaultWeekDateRangeFilter();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [startDate] = useState(localStorage.getItem('filterDateStart') || startFilter);
  const [endDate] = useState(localStorage.getItem('filterDateEnd') || endFilter);
  const [dataByType, setDataByType] = useState([]);

  const filter = {
    start: startDate,
    end: endDate,
    noPagination: true
  }
  const { data, isLoading } = useGetList(
    `dashboard/${idRestaurant}`,
    {
      filter
    }
  );

  useEffect(() => {
    if(!isLoading)
    setDataByType(data);
  }, [data, isLoading])

  const paymentsByType = computePaymentByType(dataByType);
  

  const _paymentModeMapping = [
    translate('COMMON.BANK_CARD_SHORT'),
    "AMEX",
    translate('COMMON.PAYMENT_TITLE_SHORT'),
    "SWILE",
    "RESTOFLASH",
    "EDENRED",
    "GOOGLE PAY",
    "APPLE PAY",
    translate('COMMON.OTHER'),
  ];

  const headCells = [
    {
      id: 'type_carte',
      numeric: true,
      disablePadding: true,
      label: translate('COMMON.PAYMENT_METHOD'),
      icon: <CreditCardOutlined sx={muiIconStyles.primary} />,
      align:'left'
    },
    {
      id: 'montant',
      numeric: true,
      disablePadding: false,
      label: translate('COMMON.AMOUNT_TTC'),
      icon: <EuroOutlined sx={muiIconStyles.secondary} />,
      align:'right'
    },
    {
      id: 'part',
      numeric: true,
      disablePadding: false,
      label: translate('COMMON.PART'),
      icon: <PieChartOutlined sx={muiIconStyles.danger} />,
      align:'right'
    },
    {
      id: 'pourboire',
      numeric: true,
      disablePadding: false,
      label: translate('COMMON.TIPS'),
      icon: <PaymentsOutlined sx={muiIconStyles.success} />,
      align:'right'
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const totalMontants = paymentsByType.reduce((p, k) => p += (k.montant) ? Number(k.montant) : 0, 0);
  const totalParts = paymentsByType.reduce((p, k) => p += (k.part) ? Number(k.part) : 0, 0);
  const totalPourboire = paymentsByType.reduce((p, k) => p += (k.pourboire) ? Number(k.pourboire) : 0, 0);

  return <Box>
    <Breadcrumb label={translate('COMMON.DASHBOARD')} path={`#/`}  />
    <ListTitle content={translate('COMMON.DISTRIBUTION_BY_MOYEN')} />
    <GridItem>
    <TableContainer component={Paper}>
      <Table>
        <CustomTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
        <TableBody className="bodyTableStats">
          {stableSort(paymentsByType, getComparator(order, orderBy, translate)).map((row) => {
            return (<TableRow
              key={row.id}
              sx={dashboardStyles.statsTables.row}
            >
              <TableCell component="th" scope="row" align="left">
                {_paymentModeMapping[row.type_carte]}
              </TableCell>
              <TableCell align="right">{formatNumber(row.montant)}</TableCell>
              <TableCell align="right">{formatNumber(row.part, 2, ' %')}</TableCell>
              <TableCell align="right">{formatNumber(row.pourboire)}</TableCell> 
            </TableRow>
          );
        })}
          <TableRow
              sx={dashboardStyles.statsTables.row}
            >
              <TableCell component="th" scope="row" align="left"><b>Total</b></TableCell>
              <TableCell align="right"><b>{formatNumber(totalMontants)}</b></TableCell>
              <TableCell align="right"><b>{formatNumber(totalParts, 2, ' %')}</b></TableCell>
              <TableCell align="right"><b>{formatNumber(totalPourboire)}</b></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </GridItem>
  </Box>;
};

/**
 * Retourne un liste de paiements par type de paiement
 * en calculant la somme des montants, pourboires et nb de paiements
 * 
 * @param {Array} data : liste des données de la DB
 * @returns Array
 */
 const computePaymentByType = (data) => {
  const nbTotalPayments = data.length;
  const paymentsByType = data.reduce((prev, item) => {
    const pourboire = (item.pourboire) ? Number(item.pourboire) : 0;
    const montant = (item.montant) ? Number(item.montant) - pourboire : 0;
    const payment = {
      type_carte: item.type_carte,
      montant,
      pourboire,
      nbPayment: 1,
      part: 0,
    };

    if (!prev[item.type_carte]) prev[item.type_carte] = payment;
    else {
      // cumul du nombre de paiement, du montant et du pourboire
      prev[item.type_carte].nbPayment += 1;
      prev[item.type_carte].montant += montant;
      prev[item.type_carte].pourboire += pourboire;
    }
    prev[item.type_carte].part = prev[item.type_carte].nbPayment / nbTotalPayments * 100;

    return prev;
  }, {});

  return Object.keys(paymentsByType).map(p => ({ id: p, ...paymentsByType[p] }));
};

export default TableauRepartitionMoyenPaiement;