import * as React from 'react';
import { useTranslate } from 'react-admin';
import EditComponent from "../core/EditComponent";
import CartesEditContent from './EditContent';


const CartesEdit = (props) => {
  const t = useTranslate();
  return (
    <EditComponent
      title={t('CARDS.TITLE_EDIT')}
      breadcrumb={t('CARDS.TITLE_EDIT')}
      notifyname={t('CARDS.NAME')}
      notifyitem="nom"
      mutationMode="optimistic"
      {...props}>
      <CartesEditContent />
    </EditComponent>
  );
};

export default CartesEdit;