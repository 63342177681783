import React, { useState } from "react";
import { useLocale, useTranslate, } from 'react-admin';
import { Grid, Stack, TextField } from '@mui/material';
import GridItem from '../../../styles/GridItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { defaultWeekDateRangeFilter, getLocale } from '../../../utils/utils';
import { dashboardStyles } from '../../../styles/Styles';
import TodayDateButton from "./TodayDateButton";
import ExportButton from "./ExportButton";
import SearchField from "./SearchField";

const DateRangeFilters = (props) => {
  const translate = useTranslate();
  const locale = useLocale();
  const localeLangage = getLocale(locale.toLowerCase());
  const { startFilter, endFilter } = defaultWeekDateRangeFilter();

  const [startDate, setStartDate] = useState(localStorage.getItem('filterDateStart') || startFilter);
  const [endDate, setEndDate] = useState(localStorage.getItem('filterDateEnd') || endFilter);


  const handleChangeStartDate = (date) => {
    localStorage.setItem('filterDateStart', new Date(date).toISOString().split('T')[0]);
    setStartDate(new Date(date).toISOString().split('T')[0]);
    props.handleChangeStartDate(date);
  };
  const handleChangeEndDate = (date) => {
    localStorage.setItem('filterDateEnd', new Date(date).toISOString().split('T')[0]);
    setEndDate(new Date(date).toISOString().split('T')[0]);
    props.handleChangeEndDate(date);
  };
  const handleToDayFilter = () => {
    const date = new Date();
    handleChangeStartDate(date);
    handleChangeEndDate(date);
  };
  const handleChangeKeyword = (val) => {
    props.handleChangeKeyword(val);
  };

  return (
    <Grid container sx={{padding: '8px'}}>
      <Grid item lg={6} xs={12}>
        <Stack direction="row" useFlexGap={true} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeLangage}>
            <Stack direction={{ xs: 'column', sm: 'row' }} useFlexGap={true} spacing={2}>
              <DesktopDatePicker
                label={translate('COMMON.START')}
                value={startDate}
                inputFormat="dd/MM/yyyy"
                onChange={handleChangeStartDate}
                renderInput={(params) => <TextField {...params} className="dashDateInput" />}
              />
              <DesktopDatePicker
                label={translate('COMMON.END')}
                value={endDate}
                inputFormat="dd/MM/yyyy"
                onChange={handleChangeEndDate}
                renderInput={(params) => <TextField {...params} className="dashDateInput" />}
              />
            </Stack>
          </LocalizationProvider>
          <TodayDateButton handleSubmit={handleToDayFilter} />
        </Stack>
      </Grid>
      <Grid item lg={6} xs={12}>
        <ExportButton show={props.handleExportGrid} isDisabled={props.disabledExportButton} handleSubmit={props.handleExportGrid} />
        <SearchField show={props.handleChangeKeyword} value={props.keyword} handleChangeKeyword={handleChangeKeyword} />
      </Grid>
    </Grid>
  );
};

export default DateRangeFilters;