import * as React from "react";
import { Create, useRedirect, useNotify, useResourceContext, usePermissions } from 'react-admin';
import { Box, Grid } from '@mui/material';
import Breadcrumb from './BreadCrumb';
import ListTitle from './ListTitle';
import GridItem from '../../styles/GridItem';
import { getRoleAndPermissions } from "../../utils/utils";
import i18n from '../../i18n';


const CreateComponent = props => {
  // handling side effects
  const redirect = useRedirect();
  const notify = useNotify();
  const resource = useResourceContext();
  const globalPermissions = usePermissions();
  const { permissions, country, role } = getRoleAndPermissions(globalPermissions.permissions, resource);

  const onSuccess = (res) => {
    if(res.success === false)
      return notify(res.message, { type: 'warning' });
    notify(`${i18n.translate(props.notifyname)} ${res[props.notifyitem]} ${i18n.translate('COMMON.STATUS_CREATED')}`, { type: 'success' });
    redirect(`/${resource}`);
  };

  const onError = (error) => {
    notify(`${i18n.translate(props.notifyname)} ${i18n.translate('COMMON.ERROR_NOT_CREATED')}`, { type: 'warning' });
  };

  if (!permissions?.create) return null;

  const childrenWithPerms = () => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { permissions, country, role });
      }
      return child;
    });
  };
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb label={props.title} path={`#/${resource}`} child={i18n.translate(props.breadcrumb)} />
      <ListTitle content={props.title} />
      <Grid container spacing={2} >
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <GridItem position="middle">
            <Create {...props} mutationOptions={{ onError, onSuccess }}>
              {childrenWithPerms()}
            </Create>
          </GridItem>
        </Grid>
      </Grid>
      <Box style={{ height: 300 }} />
    </Box>
  );
};

export default CreateComponent;