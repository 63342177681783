import * as React from 'react';
import { useTranslate } from 'react-admin';
import EditComponent from "../core/EditComponent";
import TablesRestaurantEditContent from './EditContent';

const TablesRestaurantEdit = (props) => {
  const t = useTranslate();
  
  return (
    <EditComponent
      title={t('QRCODE.TITLE_EDIT')}
      breadcrumb={t('QRCODE.TITLE_EDIT')}
      notifyname={t('QRCODE.NAME')}
      notifyitem="id_table"
      actions={false}
      {...props}>
      <TablesRestaurantEditContent />
    </EditComponent>
  );
};

export default TablesRestaurantEdit;