import * as React from "react";
import { useState, useEffect } from 'react';
import {
  Toolbar,
  SimpleForm,
  TextInput,
  SelectInput,
  SaveButton,
  Button,
  useNotify,
  useGetList,
  useEditContext,
  useCreate,
  Loading,
} from 'react-admin';
import { Grid } from "@mui/material";
import { btnStyles, disabledBtnStyles } from '../../styles/Styles';
import { SaveOutlined, ToggleOff, Lock } from '@mui/icons-material';
import { _ROLES, _ID_AGENCE_ALL } from '../../constantes';
import ConfirmResetPasswordModal from './ConfirmResetPassword';
import { joiUserSchema, joiEditUserSchema } from "../../utils/Validations/schemas/userSchema";
import errorSchemas from "../../utils/Validations/handleErrorSchemas/errorSchemas";
import ModalConfirmationEnableDisableAccount from "../core/ModalConfirmationEnableDisableAccount";

const EnableDisableAccountButton = (compte_actif, onClick, translate) => {
  if(!compte_actif)
    return (
      <Button label={translate('COMMON.ACCOUNT_ACTIVATE')} sx={btnStyles.secondary} onClick={onClick}>
        <ToggleOff />
      </Button>
    );
  else if(compte_actif)
  return (
    <Button label={translate('COMMON.ACCOUNT_DESACTIVATE')} sx={btnStyles.danger} onClick={onClick}>
      <ToggleOff />
    </Button>
  );
};

const CustomToolbar = (props) => {
  const handleShowModal = () => props.handleShowConfirmModal(true);
  const resetPassword = (!props.iscreating) ? ResetPasswordButton(handleShowModal, props.record.compte_actif, props.translate) : null;
  const enableDisableAccount = (!props.iscreating) ? EnableDisableAccountButton(props.record.compte_actif, props.handleOpenModalConfirmation, props.translate) : null;
  const style = (props.iscreating) ? btnStyles.success : (props.record?.compte_actif) ? btnStyles.success : disabledBtnStyles.success;
  const isDisabled = (props.iscreating) ? false : !props.record?.compte_actif;
  return (
    <Toolbar {...props}>
      <SaveButton label={props.translate('ACTIONS.SAVE')}  sx={style} icon={<SaveOutlined />} disabled={isDisabled} />
      {resetPassword}
      {enableDisableAccount}
    </Toolbar>
  )
};

const ResetPasswordButton = (showConfirmModal, flagCompteActif,  translate) => (
  <Button label={translate('COMMON.PASSWORD_RENEW')} sx={(flagCompteActif) ? btnStyles.primary : disabledBtnStyles.primary} onClick={showConfirmModal} disabled={!flagCompteActif}>
    <Lock />
  </Button>
);


const UtilisateurUpsertContent = props => {
  const { record } = useEditContext();
  const notify = useNotify();
  const _IS_ADMIN_ES = props.country === 'ES';
  const iscreating = (!record) ? 1 : 0;

  const { data, loading } = useGetList('utilisateurs/list-accounts', {}); // ajout object vide pour ne pas avoir de pagination ( = get all)
  const { data: dataAgences, isLoading: isLoadingAgences } = useGetList('agences', { filter: { user_creation: true } });
  const [resetPassword, { isLoading: isLoadingResetPassword, error: errorResetPassword, data: dataResetPassword }] = useCreate();
  
  const [emails, setEmails] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [operationAccount, setOperationAccount] = useState();
  const [role, setRole] = useState(record?.role);

  const handleCloseModalConfirmation = () => {
    setOpenModalConfirmation(false);
  };
  
  const handleOpenModalConfirmation = () => {
    setOpenModalConfirmation(true);
  };

  const handleShowConfirmModal = (value) => {
    setShowConfirmModal(value);
  };
  const handleResetPassword = () => {
    resetPassword(`authentication/forgot-password`, { data: { email: record.adresse_mail, role: record.role } });
  };
  const handleChangeRole = (e) => {
    setRole(e.target.value);
  };

  useEffect(() => {
    if (!loading) setEmails(data);
    setOperationAccount((record?.compte_actif) ? 'disable' : 'enable')
  }, [loading, data, record]);

  useEffect(() => {
    if (!isLoadingResetPassword && !errorResetPassword && dataResetPassword) {
      notify(`${props.translate('RESTAURANTS.EMAIL_SENT_TO')} ${record.adresse_mail}.`, { type: 'success' });
      setShowConfirmModal(false);
    }
  }, [isLoadingResetPassword]);

  const uniqEmailValidator = (email) => {
    if (!email || !emails) return 'VALIDATION.MANDATORY.MID';

    const existEmail = Object.values(emails).findIndex(p => p.adresse_mail?.trim().toLowerCase() === email.trim().toLowerCase()) > -1;
    if (existEmail) return 'VALIDATION.EXISTS.EMAIL';
    return undefined;
  };

  /**
   * Global validator
   * 
   * @param {Object} values : champs du formulaire
   * @returns tableau d'erreurs
   */
  const validateCreation = (values) => {
    if (!iscreating) {
      return errorSchemas(joiEditUserSchema, values);
    } else {
      let errors = {};
      const emailValidator = uniqEmailValidator(values.adresse_mail);
      if (emailValidator)
        errors.adresse_mail = emailValidator;
      return { ...errors, ...errorSchemas(joiUserSchema, values) };
    }
  };

  if (isLoadingAgences) return <Loading />;

  const roleChoices = _ROLES.map(p => ({ id: p.id, name: props.translate(p.name) }));

  return (
    <>
      {!iscreating && <ModalConfirmationEnableDisableAccount isOpen={openModalConfirmation} onClose={handleCloseModalConfirmation} nom={`${record.prenom} ${record.nom}`} operation={operationAccount} id={record.id} />}
      <SimpleForm
        mode="onChange"
        validate={validateCreation}
        toolbar={<CustomToolbar record={record} handleOpenModalConfirmation={handleOpenModalConfirmation} translate={props.translate} iscreating={iscreating} handleShowConfirmModal={handleShowConfirmModal} />}
      >
        <Grid container columnSpacing={{xs: 0, sm: 3}}>
          <Grid item xs={12} md={4}>
            <TextInput label={props.translate('COMMON.NAME')} source="nom" fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput label={props.translate('COMMON.FIRSTNAME')} source="prenom" fullWidth />
          </Grid>
          <Grid item xs={0} md={4}></Grid>
          <Grid item xs={12} md={8}>
            <TextInput label={props.translate('COMMON.EMAIL_ADDRESS')} source="adresse_mail" disabled={!iscreating} fullWidth />
          </Grid>
          <Grid item xs={0} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              label={props.translate('COMMON.ROLE')}
              source="role"
              choices={roleChoices}
              onChange={handleChangeRole}
              fullWidth
            />
          </Grid>
          <AgenceForm
            role={role}
            label={props.translate('COMMON.AGENCY')}
            agences={dataAgences}
            isAdminEs={_IS_ADMIN_ES}
            id_agence={record?.id_agence}
          />
        </Grid>
      </SimpleForm>
      {showConfirmModal && <ConfirmResetPasswordModal
        handleShowModal={handleShowConfirmModal}
        handleSubmit={handleResetPassword}
        email={record.adresse_mail}
        translate={props.translate}
      />}
    </>
  );
};

const agencesByRole = (role, dataAgences, isAdminEs) => {
  if (localStorage.getItem('isAdmin') === 'true' && role === 'admin') return dataAgences;
  if (isAdminEs) return dataAgences;
  if (role === 'admin') return dataAgences?.filter(p => p.id === _ID_AGENCE_ALL);

  return dataAgences?.filter(p => p.id !== _ID_AGENCE_ALL);
};

const AgenceForm = ({ role, label, agences, id_agence, isAdminEs }) => {
  if (!role) return null;

  const choices = agencesByRole(role, agences, isAdminEs);
  const defaultValue = choices.length === 1 ? choices[0].id : id_agence;

  return (
    <Grid item xs={12} md={4}>
      <SelectInput
        label={label}
        source="id_agence"
        optionText="nom"
        choices={choices}
        defaultValue={defaultValue}
        disabled={isAdminEs || choices.length === 1}
        fullWidth
      />
    </Grid>
  );
};



export default UtilisateurUpsertContent;