import { Select, MenuItem } from '@mui/material';
import { useTranslate } from 'react-admin';
import { LockOutlined, PersonOutline } from '@mui/icons-material';
import { muiIconStyles } from '../styles/Styles'; 
import { useMenuItemAppBarStyles, useSelectAppBarStyles } from '../styles/Classes';
import { clearSessionLocalStorage } from '../utils/utils';

const handleChange = (e) => {
  localStorage.setItem('idRestaurant', e.target.value);
  if(e.target.value !== "logout")
  {
    window.location.href="/";
  }
}

const AdminTopMenu = props => {
  const classesSelect= useSelectAppBarStyles();
  const classesMenuItem = useMenuItemAppBarStyles();
  // handle logout
  const handleLogout = () => {
    clearSessionLocalStorage();
    window.location.reload();
  };
  const translate = useTranslate();

  const username = localStorage.getItem('username');
  
  return (
    <Select classes={classesSelect} onChange={handleChange} defaultValue="username" {...props} className="selectResto">
      <MenuItem key="id" classes={classesMenuItem} value="username">
        <PersonOutline sx={muiIconStyles.default} /> {username}
      </MenuItem>
      <MenuItem key="logout" value="logout" classes={classesMenuItem} onClick={handleLogout}>
        <LockOutlined sx={muiIconStyles.primary} />{translate('ACTIONS.LOGOUT')}
      </MenuItem>
    </Select>
  );
};

export default AdminTopMenu;
