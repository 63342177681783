import * as React from 'react';
import ListTitle from '../../core/ListTitle';
import { Grid, Typography, Box, TextField, Button, Switch, Radio, RadioGroup, FormControlLabel, Stack } from '@mui/material';
import GridItem from '../../../styles/GridItem';
import { useNotify, useCreate, useGetOne, useTranslate } from 'react-admin';
import { useState, useEffect } from 'react';
import { btnStyles } from '../../../styles/Styles';
import { SaveOutlined } from '@mui/icons-material';
import RestrictionModal from '../../Settings/restrictionModal';
import { useLocation } from "react-router-dom";

const GestionPourboires = (props) => {
  const { state } = useLocation();
  const translate = useTranslate();
  const { config } = props;
  const { pourboire_active, type, valeurs_par_defaut} = config;
  const notify = useNotify();
  const idRestaurant = localStorage.getItem('idRestaurant');
  const [saveTipsConfig, { isLoading: isLoadingSaveTipsConfig, error: errorSaveTipsConfig, data: dataSaveTipsConfig }] = useCreate();
  const { data: restrictions, isLoading: isLoadingRestrictions } = useGetOne(`settings/account`, { id : idRestaurant  });

  const [tips, setTips] = useState((config) ? pourboire_active : false);
  const [tipsType, setTipsType] = useState((config && type) ? type : '');
  const [firstTipsValue, setFirstTipsValue] = useState((config && valeurs_par_defaut) ? valeurs_par_defaut.first : '');
  const [secondTipsValue, setSecondTipsValue] = useState((config && valeurs_par_defaut) ? valeurs_par_defaut.second : '');
  const [thirdTipsValue, setThirdTipsValue] = useState((config && valeurs_par_defaut) ? valeurs_par_defaut.third : '');
  const [saving, setSaving] = useState(false);
  const [hasRestrictions, setHasRestrictions] = useState(false);
  const [openModalRestrictions, setOpenModalRestrictions] = useState(true);
  const [forceAccess, setForceAccess] = useState(false || state?.forceAccess);

  const isLoggedInByRoleTech = (localStorage.getItem('loggedInByRole') === 'tech');
  const isDisabledTipsOptions =  ((!pourboire_active && !tips) || isLoggedInByRoleTech)

  useEffect(() => {
    if (config) {
      setTips(pourboire_active);
      setTipsType((type) ? type : 'euros');
      setFirstTipsValue((valeurs_par_defaut) ? valeurs_par_defaut.first : 1);
      setSecondTipsValue((valeurs_par_defaut) ? valeurs_par_defaut.second : 2);
      setThirdTipsValue((valeurs_par_defaut) ? valeurs_par_defaut.third : 3);
    }
  }, [config, config.type, config.valeurs_par_defaut]);

  useEffect(() => {
    if (!isLoadingSaveTipsConfig && !errorSaveTipsConfig && dataSaveTipsConfig) {
      setSaving(false)
      if (dataSaveTipsConfig && dataSaveTipsConfig.success === true)
        notify(props.translate('COMMON.CONFIG_SAVED'), { type: 'success' });
      else
        notify(props.translate('COMMON.CONFIG_SAVED_FAILED'), { type: 'error' });
    }
    else if (errorSaveTipsConfig) {
      setSaving(false);
      notify(props.translate('COMMON.CONFIG_SAVED_FAILED'), { type: 'error' });
    }
  }, [isLoadingSaveTipsConfig]);

  useEffect(() => {
    if (!isLoadingRestrictions && restrictions) setHasRestrictions(restrictions?.actif);
  }, [isLoadingRestrictions]);


  const handleActivateTips = (event) => {
    setTips(event.target.checked);
  }
    ;
  const handleChangeTipsType = (event) => {
    setTipsType(event.target.value)
  };

  const isValidTip = (str) => {
    const regex = /^([0-9]*(\,|.[0-9]{0,2})?)$/;
    const numberValue = Number(str.toString().replace(',','.'));
    return (!isNaN(numberValue) && regex.test(str)) ? true : false;
  }

  const handleFirstTipChange = (value) => {
    if(isValidTip(value))
      setFirstTipsValue(value);
  }

  const handleSecondTipChange = (value) => {
    if(isValidTip(value))
      setSecondTipsValue(value);
  }

  const handleThirdTipChange = (value) => {
    if(isValidTip(value))
      setThirdTipsValue(value);
  }

  const formatTipsValues = (firstTip, secondTip, thirdTip) => {
    const tipsValuesArray = [];
    tipsValuesArray.push(firstTip === '' ? null : parseFloat(firstTip.toString().replace(',', '.')));
    tipsValuesArray.push(secondTip === '' ? null : parseFloat(secondTip.toString().replace(',', '.')));
    tipsValuesArray.push(thirdTip === '' ? null : parseFloat(thirdTip.toString().replace(',', '.')));
    const tipsValuesArraySorted = tipsValuesArray.sort(function (a, b) { return a - b });
    const tipsValuesObject = tipsValuesArraySorted.includes(null) ? null : {
      first: tipsValuesArraySorted[0],
      second: tipsValuesArraySorted[1],
      third: tipsValuesArraySorted[2],
    }
    return tipsValuesObject;
  };

  const handleSave = (e) => {
    if (saving)
      return null;
    setSaving(true);
    const tipsValues = formatTipsValues(firstTipsValue, secondTipsValue, thirdTipsValue);
    const dataToSave = {
      restaurant_id: Number(idRestaurant),
      tips,
      tipsType: (tipsType === '') ? null : tipsType,
      tipsValues
    };
    saveTipsConfig(`settings/tips/save`, { data: dataToSave });
  };

  const handleCloseModalrestrictions = () => {
    setOpenModalRestrictions(false);
  };

  const handleSubmitModalrestrictions = () => {
    setOpenModalRestrictions(false);
    setForceAccess(true);
  };

  if(isLoadingRestrictions) return null;
  if(!forceAccess && hasRestrictions) return <RestrictionModal title={translate('MODAL_RESTRICTION.TIPS_TITLE')} open={openModalRestrictions} onClose={handleCloseModalrestrictions} handleSubmit={handleSubmitModalrestrictions} />;

  return (
    <Box>
      <ListTitle content={props.translate('TIPS.TITLE')} />
      <Grid container spacing={2} >
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <GridItem position="middle">
            <Stack direction="row" spacing={2}>
              <Typography sx={{ padding: '8px' }}>{props.translate('TIPS.ACTIVATE')}</Typography>
              <Switch label="" onChange={handleActivateTips} checked={tips} disabled={isLoggedInByRoleTech} />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ padding: '12px' }}>{props.translate('TIPS.TYPE_USED')}</Typography>
              <RadioGroup value={tipsType} onChange={handleChangeTipsType}>
                <Stack direction="row" spacing={2}>
                  <FormControlLabel value="euros" control={<Radio />} label='Euros (€)' disabled={isDisabledTipsOptions} />
                  <FormControlLabel value="pourcentage" control={<Radio />} label={`${props.translate('COMMON.PERCENTAGE')} (%)`} disabled={isDisabledTipsOptions} />
                </Stack>
              </RadioGroup>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Box>
                <Typography sx={{ padding: '8px' }}>{props.translate('TIPS.DEFAULT_SETTINGS')}</Typography>
                <Typography sx={{ padding: '0 8px', fontSize: '12px' }}>** {props.translate('TIPS.VALIDATION_MESSAGE')}</Typography>
              </Box>
                
              <TextField label={`${props.translate('COMMON.VALUE')} 1`} className="raTextInput" onChange={e => handleFirstTipChange(e.target.value)} fullWidth={false} disabled={isDisabledTipsOptions} value={firstTipsValue} />
              <TextField label={`${props.translate('COMMON.VALUE')} 2`} className="raTextInput" onChange={e => handleSecondTipChange(e.target.value)} fullWidth={false} disabled={isDisabledTipsOptions} value={secondTipsValue} />
              <TextField label={`${props.translate('COMMON.VALUE')} 3`} className="raTextInput" onChange={e => handleThirdTipChange(e.target.value)} fullWidth={false} disabled={isDisabledTipsOptions} value={thirdTipsValue} />
                
            </Stack>
            <Button sx={btnStyles.success} startIcon={<SaveOutlined />}  disabled={isDisabledTipsOptions} onClick={handleSave}>{props.translate('ACTIONS.SAVE')}</Button>
          </GridItem>
        </Grid>
      </Grid>
      <Box style={{ height: 300 }} />
    </Box>
  );
}

export default GestionPourboires;
