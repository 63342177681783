import * as React from 'react';
import {
  SimpleForm,
  useTranslate,
  useUpdate,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { Typography } from '@mui/material';
import { useState } from 'react';
import EditComponent from '../../../core/EditComponent';
import TransactionsStatementForm from './Form';
import CustomToolbar from './Toolbar';
import errorSchemas from '../../../../utils/Validations/handleErrorSchemas/errorSchemas';
import { statementSchema } from '../../../../utils/Validations/schemas/statementSchema';

const TransactionsStatement = (props) => {
  const isLoggedInByRoleTech = (localStorage.getItem('loggedInByRole') === 'tech');
  const t = useTranslate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const idRestaurant = localStorage.getItem('idRestaurant');
  const [updateConfig] = useUpdate();
  const [record, setRecord] = useState({})


  const handleValidate = (values) => {
    const errors = errorSchemas(statementSchema, values);
    const { actifStatement, period } = values;
    setRecord({ actif: actifStatement, period });
    return errors;
  };

  const handleSubmit = () => {
   const data = {id: idRestaurant, ...record};
   if(data.actif && !data.period ) return;
   
   updateConfig(
    'settings/statement',
    { id: idRestaurant, data },
    {
      onSuccess: () => {
        refresh();
        redirect('edit', '/settings/account', idRestaurant);
      }
    });
  };

  return (
    <>
      <EditComponent
        mutationMode="optimistic"
        redirect=""
        heightAuto={true}
        noMarginBottom="true"
      >
        <SimpleForm
          mode="onChange"
          validate={handleValidate}
          toolbar={<CustomToolbar t={t} handleSubmit={handleSubmit} isLoggedInByRoleTech={isLoggedInByRoleTech} />}
        >
          <Typography variant="h5" gutterBottom>{props.title}</Typography>
          <TransactionsStatementForm isLoggedInByRoleTech={isLoggedInByRoleTech} />
        </SimpleForm>
      </EditComponent>
    </>
  );
};

export default TransactionsStatement;