import React from 'react';
import ListTitle from '../core/ListTitle';
import GridItem from '../../styles/GridItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { muiIconStyles } from '../../styles/Styles';
import { EuroOutlined, ReceiptOutlined, Brightness6Outlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { stylesTableCell, dashboardStyles } from '../../styles/Styles';
import Box from '@mui/material/Box';
import Breadcrumb from '../core/BreadCrumb';
import { CustomTableHead, getComparator, stableSort } from '../../utils/ListFilter';
import { useTranslate, useGetList } from 'react-admin';
import { useState, useEffect } from 'react';
import { defaultWeekDateRangeFilter, formatNumber, getKeyPartNoonNight } from '../../utils/utils';

const TableauRepartitionMidiSoir = () => {
  const translate = useTranslate();
  const idRestaurant = localStorage.getItem('idRestaurant');
  const { startFilter, endFilter } = defaultWeekDateRangeFilter();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [startDate] = useState(localStorage.getItem('filterDateStart') || startFilter);
  const [endDate] = useState(localStorage.getItem('filterDateEnd') || endFilter);
  const [dataInDays, setDataInDays] = useState([]);

  const filter = {
    start: startDate,
    end: endDate,
    noPagination: true
  }
  const { data, isLoading } = useGetList(
    `dashboard/${idRestaurant}`,
    {
      filter
    }
  );

  useEffect(() => {
    if(!isLoading)
    setDataInDays(data);
  }, [data, isLoading])

  const paymentsInDays = computePaymentsInDay(dataInDays);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: translate('COMMON.PERIOD'),
      icon: <Brightness6Outlined sx={muiIconStyles.primary} />,
      align: 'left'
    },
    {
      id: 'nbPayment',
      numeric: true,
      disablePadding: false,
      label: translate('COMMON.NUMBER'),
      icon: <ErrorOutlineOutlined sx={muiIconStyles.secondary} />,
      align: 'right'
    },
    {
      id: 'montant',
      numeric: true,
      disablePadding: false,
      label: translate('COMMON.AMOUNT_TTC'),
      icon: <EuroOutlined sx={muiIconStyles.warning} />,
      align: 'right'
    },
    {
      id: 'ticketMoyen',
      numeric: true,
      disablePadding: false,
      label: translate('COMMON.AVERAGE_TICKET'),
      icon: <ReceiptOutlined sx={muiIconStyles.success} />,
      align: 'right'
    }
  ];
  const totalNbPayments = paymentsInDays.reduce((p, k) => p += (k.nbPayment) ? Number(k.nbPayment) : 0, 0);
  const totalMontants = paymentsInDays.reduce((p, k) => p += (k.montant) ? Number(k.montant) : 0, 0);
  const totalTicketMoyen = paymentsInDays.reduce((p, k) => p += (k.ticketMoyen) ? Number(k.ticketMoyen) : 0, 0);

  return <Box>
    <Breadcrumb label={translate('COMMON.DASHBOARD')} path={`#/`} />
    <ListTitle content={translate('COMMON.DISTRIBUTION_BY_TIME')} />
    <GridItem>
    <TableContainer component={Paper}>
      <Table>
        <CustomTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
        <TableBody className="bodyTableStats">
           {stableSort(paymentsInDays, getComparator(order, orderBy, translate)).map((row) => {
            return (<TableRow
              key={row.id}
              sx={dashboardStyles.statsTables.row}
            >
              <TableCell component="th" scope="row" align="left">
                <span sx={stylesTableCell.span}>{(row.id === 'AM') ? translate('COMMON.NOON') : translate('COMMON.NIGHT') }</span>
              </TableCell>
              <TableCell align="right">{row.nbPayment}</TableCell>
              <TableCell align="right">{formatNumber(row.montant)}</TableCell>
              <TableCell align="right">{formatNumber(row.ticketMoyen)}</TableCell>
            </TableRow>
          );
        })}
        <TableRow
            sx={dashboardStyles.statsTables.row}
          >
            <TableCell component="th" scope="row" align="left"><b>Total</b></TableCell>
            <TableCell align="right"><b>{totalNbPayments}</b></TableCell>
            <TableCell align="right"><b>{formatNumber(totalMontants)}</b></TableCell>
            <TableCell align="right"><b>{formatNumber(totalTicketMoyen)}</b></TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </GridItem>
  </Box>;
};

const computePaymentsInDay = (data) => {
  // TODO: revoir heures services AM/PM
  // ici AM entre 5h et 15h
  const nbTotalPayments = data.length;
  const totalTtcPayment = data.reduce((p, k) => p += (k.montant) ? Number(k.montant) : 0, 0);

  const paymentsInDay = data.reduce((prev, item) => {
    const key = getKeyPartNoonNight(item.date_ticket);
    const pourboire = (item.pourboire) ? Number(item.pourboire) : 0;
    const montant = (item.montant) ? Number(item.montant) : 0;
    if (montant === 0) return prev;
    const payment = {
      montant,
      pourboire,
      nbPayment: 1,
      partNombre: 0,
      partMontant: 0,
      ticketMoyen: 0,
    };
    if (!prev[key]) prev[key] = payment;
    else {
      prev[key].nbPayment += 1;
      prev[key].montant += montant;
      prev[key].pourboire += pourboire;
    }
    prev[key].partNombre = prev[key].nbPayment / nbTotalPayments * 100;
    prev[key].partMontant = prev[key].montant / totalTtcPayment * 100;
    prev[key].ticketMoyen = prev[key].montant / prev[key].nbPayment;

    return prev;
  }, {});

  return Object.keys(paymentsInDay).map(p => ({ id: p, ...paymentsInDay[p] }));
};

export default TableauRepartitionMidiSoir;