import * as React from 'react';
import {
  BooleanInput,
  useTranslate,
  RadioButtonGroupInput,
  useRecordContext
} from 'react-admin';
import {
  Grid,
  Typography
} from '@mui/material';
import { useState } from 'react';
import i18n from '../../../../i18n';

const _PERIODS = [
    { id: 'daily', label: i18n.translate('SETTINGS.STATEMENT.DAILY') },
    { id: 'weekly', label: i18n.translate('SETTINGS.STATEMENT.WEEKLY') },
    { id: 'monthly', label: i18n.translate('SETTINGS.STATEMENT.MONTHLY') },
    // { id: 'annual', label: i18n.translate('SETTINGS.STATEMENT.ANNUAL') },
]

const TransactionsStatementForm = ({ isLoggedInByRoleTech }) => {
  const t = useTranslate();
  const record = useRecordContext();
  const [actif, setActif] = useState(record?.actifStatement ? true : false);

  const handleChangeActif = () => {
    setActif(!actif);
  }

  return (<Grid container spacing={2} >
        <Grid item xs={12} >
        <BooleanInput source="actifStatement" label={t('SETTINGS.STATEMENT.TOGGLE_TR_STATEMENT')} onChange={handleChangeActif} disabled={isLoggedInByRoleTech} />
        <Typography sx={{ padding: '0 8px', fontSize: '12px' }}>{t('SETTINGS.STATEMENT.MESSAGE')}</Typography>
        </Grid>
        <Grid item xs={5}>
          { actif && <Typography sx={{fontSize: '1rem', color: '#656565' }}>Période</Typography> }
          { actif && <RadioButtonGroupInput source="period" label="" optionText="label" optionValue="id" disabled={isLoggedInByRoleTech} choices={_PERIODS} row={false} /> }
        </Grid>
    </Grid>
  );
};


export default TransactionsStatementForm;