import * as React from 'react';
import CreateComponent from "../core/CreateComponent";
import RestaurantUpsertContent from './UpsertContent';
import { useTranslate, useGetList, Loading } from 'react-admin';

const RestaurantCreate = (props) => {
  const translate = useTranslate();
  const { data: agences, isLoading: isLoadingAgences } = useGetList('agences');
  const { data: caisses, isLoading: isLoadingCaisses } = useGetList('caisses');
  const { data: restaurateurs, isLoading: isLoadingRestaurants } = useGetList('utilisateurs/list-restaurants');

  if (isLoadingAgences || !agences) return <Loading />;
  if (isLoadingCaisses || !caisses) return <Loading />;
  if (isLoadingRestaurants || !restaurateurs) return <Loading />;

  /**
   * Permet de modifier/ajouter le payload qui doit être envoyer pour la création d'un restaurant
  
   * @param {Object} data : données récupérés du formulaire de création
   * @returns {Object} données à envoyer au datasProvider pour POST
   */
  const transform = (data) => {
    const nom_agence = agences.find(p => p.id === data.id_agence)?.nom;
    const nom_caisse = caisses.find(p => p.id === data.caisse)?.nom;
    const client_email = data.adresse_mail;

    return {
      ...data,
      nom_agence,
      nom_caisse,
      client_email,
    };
  }

  return (
    <CreateComponent
      title={translate('SIDEBAR.CREATE_RESTAURANT')}
      breadcrumb="RESTAURANTS.TITLE_CREATE"
      notifyname="RESTAURANTS.NAME"
      notifyitem="nom"
      transform={transform}
      {...props}
    >
      <RestaurantUpsertContent
        isCreate={true}
        translate={translate}
        agences={agences}
        caisses={caisses}
        restaurateurs={restaurateurs}
      />
    </CreateComponent>
  )
};

export default RestaurantCreate;