import React from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { Box } from '@mui/material';
import ListTitle from '../core/ListTitle';
import Breadcrumb from '../core/BreadCrumb';
import { ListeRestaurantsByUser } from '../../layout/ListRestaurants';
import ContentUser from './content-user';
import ContentAdmin from './content-admin';

const Dashboard = (props) => {
  const translate = useTranslate();
  const idRestaurant = localStorage.getItem('idRestaurant');
  const isMultipleRestaurants = localStorage.getItem('multipleRestaurants');
  const [openModal, setOpenModal] = React.useState(idRestaurant === null ? true : false);
  const { data: restaurants, isLoading: isLoadingRestaurants } = useGetList(
    'restaurants/list-by-user'
  );
  const role = props.permissions?.name ?? false;
  const isNotAdmin = (role === 'resp' || role === 'tech')
  if (!role || isNotAdmin) return null;

  const handleCloseModal = () => {
    if (idRestaurant !== null)
      return setOpenModal(false);
  };

  if (idRestaurant === null && isMultipleRestaurants === 'true' && !isLoadingRestaurants)
    return <ListeRestaurantsByUser open={openModal} onClose={handleCloseModal} restaurants={restaurants} />

  return (
    <Box>
      <Breadcrumb label={translate('COMMON.DASHBOARD')} path="/#" />
      <ListTitle content={translate('COMMON.DASHBOARD')} />
      {role !== 'user' ? <ContentAdmin t={translate} /> : <ContentUser t={translate} />}
    </Box>
  );
}

// const computePourboires = (data) => {
//     const grouped = data.sort((a, b)  => a.date_ticket.localeCompare(b.date_ticket))
//     .map(d => {
//         d.date_ticket = d.date_ticket.substr(0,10);
//         return d;
//     })
//     .reduce((prev, item, i) => {
//       prev[item.date_ticket] = ((prev[item.date_ticket]) ? parseFloat(prev[item.date_ticket]) : 0) + parseFloat(item.pourboire);
//       return prev;
//     }, []);
//     return Object.keys(grouped).map(p => ({ id: p, value: grouped[p] }));
// }

export default Dashboard;