import React from 'react';
import TableCell from '@mui/material/TableCell';
import { listDetailsStyles } from '../../styles/Styles';
import { stylesTableCell } from '../../styles/Styles';
import { Box } from '@mui/material';

const TableCellHeader = (props) => {
    const padding = `${props.padding}px`;
    const flexDirection = (props.align === "right") ? "row-reverse" : "row";
    return (<TableCell sx={{...listDetailsStyles.tableCellHeader, padding :padding}} align={props.align} >
        <Box sx={{ ...stylesTableCell.listHeaderCell, flexDirection: flexDirection }}>{props.icon} {props.text}</Box>
            </TableCell>)
}

export default TableCellHeader;