import Joi from 'joi';
import i18nProvider from '../../../i18n';

export const statementSchema = Joi.object().keys({
  period: Joi.string().when('actifStatement', {
    is: true,
    then: Joi.required().messages({
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.STATEMENT_PERIOD'),
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.STATEMENT_PERIOD'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.STATEMENT_PERIOD')
    }), 
    otherwise: Joi.any().optional().allow(null)
}),
}).unknown();
