import * as React from "react";
import {
  Toolbar,
  SaveButton,
  SimpleForm,
  TextInput,
  SelectInput,
  useTranslate,
  useGetList,
  Loading
} from 'react-admin';
import { btnStyles, inputStyles } from '../../styles/Styles';
import { SaveOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import errorSchemas from "../../utils/Validations/handleErrorSchemas/errorSchemas";
import { joiTablesRestaurantSchema } from "../../utils/Validations/schemas/tablesRestaurantSchema";
import { useState, useEffect } from "react";

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label={props.translate('ACTIONS.SAVE')} sx={btnStyles.success} icon={<SaveOutlined />} disabled={props.disabled} />
    </Toolbar>
  )
};

const NonInput = React.memo(function NonInput({ children }) {
  return children;
});

const TablesRestaurantEditContent = props => {
  const t = useTranslate();
  const idRestaurant = localStorage.getItem('idRestaurant');
  const isLoggedInByRoleTech = (localStorage.getItem('loggedInByRole') === 'tech');
  const [tables, setTables] = useState();
  
  const filter = { restaurant_id: idRestaurant }
  const { data, isLoading } = useGetList('qr-codes/list-all', { filter });
  

  useEffect(() => {
    if (!isLoading) setTables(data);
  },[isLoading, data]);
  

  const convertStringToNumber = value => {
    const float = parseFloat(value);
    return isNaN(float) ? null : float;
  };

  const uniqTableValidator = (table) => {
    if (!table || !tables) return 'VALIDATION.MANDATORY.TABLE_NUM';
    const tableExist = tables.some((t) => t.id_table === table);
    if (tableExist) return 'VALIDATION.EXISTS.TABLE_NUM';
    return undefined;
  };

  const validateEdition = (values) => {
    const idTableToString = values?.id_table?.toString();

    const isTableExist = uniqTableValidator(idTableToString);
    if(isTableExist) return { id_table: isTableExist };

    const vals = {...values, id_table: idTableToString};
    return errorSchemas(joiTablesRestaurantSchema, vals);
  }

  return (
    <SimpleForm mode="onChange" toolbar={<CustomToolbar translate={t} disabled={isLoggedInByRoleTech} />} {...props} validate={validateEdition}>
      <NonInput>
        <TextInput label="" source="id_restaurant" required defaultValue={idRestaurant} sx={inputStyles.hidden} />
        <Typography>{t('COMMON.TABLE_NUM_QR_CODE')}</Typography>
        <TextInput label="" source="id_table" required parse={convertStringToNumber} disabled={isLoggedInByRoleTech} />
      </NonInput>
    </SimpleForm>
  );
};

export default TablesRestaurantEditContent;