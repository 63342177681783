import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import {Layout, useSetLocale } from 'react-admin';
import { theme } from "./Theme";
import SessionTimer from '../utils/SessionTimer';
import CustomLayout from './CustomLayout';


const CustomLayoutContainer = ({ children }) => {
    const setLocale = useSetLocale();

    useEffect(() => {
        const locale = localStorage.getItem('country') || 'fr';
        setLocale(locale);
    }, [setLocale]);

    SessionTimer();

    return (
        <ThemeProvider theme={theme}>
            <CustomLayout children={children} />
        </ThemeProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default CustomLayoutContainer;