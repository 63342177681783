import Joi from 'joi';
import i18nProvider from '../../../i18n';

export const joiUserSchema = Joi.object().keys({
    adresse_mail: Joi.string().email({ tlds: {allow: false} }).required().messages({
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
        'string.email': i18nProvider.translate('VALIDATION.TYPE.EMAIL'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL')
    }),
    nom: Joi.string().max(50).required().messages({
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.NAME'),
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.NAME'),
        'string.max': i18nProvider.translate('VALIDATION.TYPE.NAME'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.NAME')
    }),
    prenom: Joi.string().max(50).required().messages({
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.FIRSTNAME'),
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.FIRSTNAME'),
        'string.max': i18nProvider.translate('VALIDATION.TYPE.FIRSTNAME'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.FIRSTNAME')
    }),
    role: Joi.string().required().messages({
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.ROLE'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.ROLE')
    }),
    id_agence: Joi.number().required().messages({
        'number.base': i18nProvider.translate('VALIDATION.MANDATORY.AGENCY'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.AGENCY')
    }),
}).unknown();

export const joiEditUserSchema = Joi.object().keys({
    adresse_mail: Joi.string().email({ tlds: {allow: false} }).allow('').messages({
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL'),
        'string.email': i18nProvider.translate('VALIDATION.TYPE.EMAIL'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.EMAIL')
    }),
    nom: Joi.string().max(50).required().messages({
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.NAME'),
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.NAME'),
        'string.max': i18nProvider.translate('VALIDATION.TYPE.NAME'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.NAME')
    }),
    prenom: Joi.string().max(50).required().messages({
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.FIRSTNAME'),
        'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.FIRSTNAME'),
        'string.max': i18nProvider.translate('VALIDATION.TYPE.FIRSTNAME'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.FIRSTNAME')
    }),
    role: Joi.string().required().messages({
        'string.base': i18nProvider.translate('VALIDATION.MANDATORY.ROLE'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.ROLE')
    }),
    id_agence: Joi.number().required().messages({
        'number.base': i18nProvider.translate('VALIDATION.MANDATORY.AGENCY'),
        'any.required': i18nProvider.translate('VALIDATION.MANDATORY.AGENCY')
    }),
}).unknown();