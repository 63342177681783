import React from "react";
import Chart from "react-apexcharts";
import { useTranslate } from "react-admin";
import { formatNumber } from "../../utils/utils";

const formatDataForMidiSoirGraph = (data, translate) => {
    const labels = [];
    const nbPayments = [];
    const montants = [];
    const ticketMoyens = [];

    if(data) {
        data.forEach( d => {
          labels.push((d.id === 'AM') ? translate('COMMON.NOON') : translate('COMMON.NIGHT'));
            nbPayments.push(d.nbPayment);
            montants.push(d.montant);
            ticketMoyens.push(d.ticketMoyen);
        });
        return {
            labels: labels,
            series: {
                "nbPayments": nbPayments,
                "montants": montants,
                "ticketMoyens": ticketMoyens
            }
        }
    }
    else return null;
}

const Bar = (props) =>  {
  const translate = useTranslate();
  const formatedData =  formatDataForMidiSoirGraph(props.data, translate);
  const data = {
    options: 
    {
      labels:formatedData.labels,
      noData: {
        text: translate('COMMON.NO_DATA_ON_RANGE'),
        align: 'center',
        verticalAlign: 'top',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
        chart: {
          type: 'line',
          stacked: false,
          toolbar: {
              show: false
          },
        },
        tooltip: {
            shared: false,
          },
        markers: {
            size: 6,
            hover: {
              sizeOffset: 2
            }
        },
        xaxis: {
            categories: formatedData.labels,
            labels: {
              style: {
                colors: ["#41a0fc", "#f86178"],
              }
            }
        },
         yaxis:{ 
               labels: {
                 formatter: function (val) {
                     return formatNumber(val)
                 }
               }
              },
        plotOptions: {
            bar: {
                columnWidth: 10,
                borderRadius: 5,
                distributed: true
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#41a0fc", "#f86178"],
    },
    series: [
        {
            name: translate('COMMON.AMOUNT_TTC'),
            data: formatedData.series.montants
        }
    ]
    };

    return (
        <Chart
            options={data.options}
            series={data.series}
            type="bar"
            width="100%"
            className="graphContentBar"
        />
    );
}

export default Bar;