import React, { useState } from 'react';
import { Link, useGetList, useLocaleState, Loading, useRedirect, useGetOne } from 'react-admin';
import { Typography, Box, Grid, Button, IconButton, Icon } from '@mui/material';
import { EuroOutlined, Payments, ReceiptOutlined } from '@mui/icons-material';
import DateRangeFilters from '../common/DateRangeFilters';
import GridItem from '../../styles/GridItem';
import ListTitle from '../core/ListTitle';
import { dashboardStyles } from '../../styles/Styles';
import Donut from '../Chartist/Donut';
import Bar from '../Chartist/Bar';
import Pie from '../Chartist/Pie';
import { defaultWeekDateRangeFilter } from '../../utils/utils';
import { formatNumber, getKeyPartNoonNight } from '../../utils/utils';


const ContentUser = props => {
  // on définit la plage par default
  const [locale] = useLocaleState();
  const { startFilter, endFilter } = defaultWeekDateRangeFilter();
  const [startDate, setStartDate] = useState(localStorage.getItem('filterDateStart') || startFilter);
  const [endDate, setEndDate] = useState(localStorage.getItem('filterDateEnd') || endFilter);
  const redirect = useRedirect();

  const handleChangeStartDate = (newValue) => {
    setStartDate(new Date(newValue).toISOString().split('T')[0]);
  };
  const handleChangeEndDate = (newValue) => {
    setEndDate(new Date(newValue).toISOString().split('T')[0]);
  };

  const handleNavToQrCodes = () => {
    redirect('/qr-codes/:id');
  };

  const idRestaurant = localStorage.getItem('idRestaurant');
  const filter = {
    start: startDate,
    end: endDate,
    noPagination: true
  }
  const { data, isLoading } = useGetList(
    `dashboard/${idRestaurant}`,
    {
      filter
    }
  );
  const { data: dataTips, isLoading: isLoadingTips } = useGetList(
    `dashboard/${idRestaurant}/tips`,
    {
      filter
    }
  );
  const { data: dataRestaurant, isLoading: isLoadingRestaurant } = useGetOne(`restaurants`, { id: idRestaurant });
  if (isLoading || !data || isLoadingTips || !dataTips || isLoadingRestaurant || !dataRestaurant ) return <Loading />;

  const totalTips = dataTips.reduce((prev, item) => {
    prev += item.pourboire ? Number(item.pourboire) : 0;
    return prev;
  }, 0);

  const paymentsByType = computePaymentByType(data);
  const paymentsInDay = computePaymentsInDay(data);
  const repartitionMidi = paymentsInDay.filter(p => p.id === "AM")[0];
  const repartitionSoir = paymentsInDay.filter(p => p.id === "PM")[0];
  const repartitionMidiTicketMoyen = repartitionMidi ? repartitionMidi.ticketMoyen : 0;
  const repartitionSoirTicketMoyen = repartitionSoir ? repartitionSoir.ticketMoyen : 0;
  const ticketMoyen = repartitionMidiTicketMoyen + repartitionSoirTicketMoyen;
  const ca = paymentsByType.reduce((p, k) => p += (k.montant) ? Number(k.montant) : 0, 0);
  const imgPath = (locale?.toLowerCase() === 'es') ? '/qrcode/TEMPLATE_QRCODE_CREATION-ES.png' : '/qrcode/TEMPLATE_QRCODE_CREATION.png';

  return (
    <Grid container spacing={2} sx={dashboardStyles.container}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={dashboardStyles.title}>{props.t('DASHBOARD.HEADING')}</Typography>
        <GridItem sx={dashboardStyles.qrCodes.container}>
          <Box sx={dashboardStyles.qrCodes.image}>
            <img src={imgPath} />
          </Box>
          <Box sx={dashboardStyles.qrCodes.arrow}>
            <img src={'/dashboard-arraow.png'} />
            <Box sx={dashboardStyles.qrCodes.arrowBody}></Box>
          </Box>
          <Box marginLeft={8}>
            <Button variant="contained" color="warning" sx={dashboardStyles.qrCodes.button} onClick={handleNavToQrCodes}>
              {props.t('DASHBOARD.QRCODES_BUTTON')}
            </Button>
          </Box>
        </GridItem>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridItem nomargin="true" minMarginBottom={true}>
          <Typography sx={dashboardStyles.title}>{props.t('DASHBOARD.TITLE')}</Typography>
          <DateRangeFilters handleChangeStartDate={handleChangeStartDate} handleChangeEndDate={handleChangeEndDate} />
        </GridItem>
      </Grid>

      {/* Stats */}
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <TurnOverComponent styles={dashboardStyles} total={ca ? formatNumber(ca) : formatNumber(0)} t={props.t} />
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <TicketAverageComponent styles={dashboardStyles} average={ticketMoyen ? formatNumber(ticketMoyen) : formatNumber(0)} t={props.t} />
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <TipsComponent styles={dashboardStyles} total={formatNumber(totalTips)} show={dataRestaurant.pourboire_active} t={props.t} />
      </Grid>

      {/* Graph Répartition par moyen paiement */}
      {/* <Grid item lg={4} md={12} sm={12} xs={12} className="graphContainer">
        <DistributionComponent styles={dashboardStyles} data={paymentsByType} t={props.t} />
      </Grid> */}

      <Grid item lg={4} md={12} sm={12} xs={12} className="graphContainer">
        <GridItem nomargin="true" nomarginbottom="true" sx={dashboardStyles.statsGraph}>
          <ListTitle content={props.t('COMMON.DISTRIBUTION_BY_TYPE')} extraStyle={dashboardStyles.statsGraph.listTitle} />
          <Donut data={paymentsByType} />
        </GridItem>
      </Grid>

      {/* Graph Répartition midi / soir */}
      <Grid item lg={4} md={12} sm={12} xs={12} className="graphContainer">
        <GridItem nomargin="true" nomarginbottom="true" sx={dashboardStyles.statsGraph}>
          <Link to={{ pathname: 'repartitionMidiSoir', data: paymentsInDay }} sx={dashboardStyles.statsGraph.link}>
            <ListTitle content={props.t('COMMON.DISTRIBUTION_BY_TIME')} extraStyle={dashboardStyles.statsGraph.listTitle} />
            <Bar data={paymentsInDay} />
          </Link>
        </GridItem>
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
    </Grid>
  );
};

const TurnOverComponent = ({styles, total, t}) => {
  return <GridItem nomargin="true" nomarginbottom="true" sx={styles.statsText}>
  <Box>
    <ListTitle content={t('COMMON.TURNOVER')} extraStyle={styles.statsText.listTitle} />
    <Icon fontSize="medium" sx={{ background: 'rgb(251, 150, 120)', ...styles.statsText.iconBtn }}>
      <EuroOutlined sx={styles.statsText.icon} />
    </Icon>
  </Box>
  <Box>
    <Typography sx={styles.statsText.text}>{total}</Typography>
  </Box>
</GridItem>
}

const TicketAverageComponent = ({styles, average, t }) => {
  return <GridItem nomargin="true" nomarginbottom="true" sx={styles.statsText}>
    <Box>
      <ListTitle content={t('COMMON.AVERAGE_TICKET')} extraStyle={styles.statsText.listTitle} />
      <Icon fontSize="medium" sx={{ background: 'rgb(208, 208, 208)', ...styles.statsText.iconBtn }}>
        <ReceiptOutlined sx={styles.statsText.icon} />
      </Icon>
    </Box>
    <Box>
      <Typography sx={styles.statsText.text}>{average}</Typography>
    </Box>
  </GridItem>
}

const TipsComponent = ({styles, total, show, t}) => {
  if(!show) return null;

  return <Link to='pourboires' sx={styles.statsGraph.link}>
    <GridItem nomargin="true" nomarginbottom="true" sx={styles.statsText}>
      <Box>
        <ListTitle content={t('COMMON.TIPS')} extraStyle={styles.statsText.listTitle} />
        <IconButton size="large" edge="start" sx={{ background: '#f2c000', ...styles.statsText.iconBtn }}>
          <Payments sx={styles.statsText.icon} />
        </IconButton>
      </Box>
      <Box>
        <Typography sx={styles.statsText.text}>{total}</Typography>
      </Box>
    </GridItem>
  </Link>
}

const DistributionComponent = ({styles, data, t}) => {
  return <GridItem nomargin="true" nomarginbottom="true" sx={styles.statsGraph}>
  <Link to={{ pathname: 'repartitionMoyenPaiement', data: data }} extraStyle={styles.statsGraph.link}>
      <ListTitle content={t('COMMON.DISTRIBUTION_BY_MOYEN')} extraStyle={styles.statsGraph.listTitle} />
      <Pie data={data} />
    </Link>
  </GridItem>
}

/**
 * Retourne un liste de paiements par type de paiement
 * en calculant la somme des montants, pourboires et nb de paiements
 * 
 * @param {Array} data : liste des données de la DB
 * @returns Array
 */
const computePaymentByType = (data) => {
  const nbTotalPayments = data.length;
  const paymentsByType = data.reduce((prev, item) => {
    const pourboire = (item.pourboire) ? Number(item.pourboire) : 0;
    const montant = (item.montant) ? Number(item.montant) : 0;
    const payment = {
      type_carte: item.type_carte,
      montant,
      pourboire,
      nbPayment: 1,
      part: 0,
    };

    if (!prev[item.type_carte]) prev[item.type_carte] = payment;
    else {
      // cumul du nombre de paiement, du montant et du pourboire
      prev[item.type_carte].nbPayment += 1;
      prev[item.type_carte].montant += montant;
      prev[item.type_carte].pourboire += pourboire;
    }
    prev[item.type_carte].part = prev[item.type_carte].nbPayment / nbTotalPayments * 100;

    return prev;
  }, {});

  return Object.keys(paymentsByType).map(p => ({ id: p, ...paymentsByType[p] }));
};
/**
 * Retourne la somme de montants, pourboires et nb paiement
 * répartit dans la journée entre AM et PM
 * 
 * @param {Array} data : lis
 * @returns Array
 */
const computePaymentsInDay = (data) => {
  // TODO: revoir heures services AM/PM
  // ici AM entre 5h et 15h
  const nbTotalPayments = data.length;
  const totalTtcPayment = data.reduce((p, k) => p += (k.montant) ? Number(k.montant) : 0, 0);

  const paymentsInDay = data.reduce((prev, item) => {
    const key = getKeyPartNoonNight(item.date_ticket);
    const pourboire = (item.pourboire) ? Number(item.pourboire) : 0;
    const montant = (item.montant) ? Number(item.montant) : 0;
    const payment = {
      montant,
      pourboire,
      nbPayment: 1,
      partNombre: 0,
      partMontant: 0,
      ticketMoyen: 0,
    };
    if (!prev[key]) prev[key] = payment;
    else {
      prev[key].nbPayment += 1;
      prev[key].montant += montant;
      prev[key].pourboire += pourboire;
    }
    prev[key].partNombre = prev[key].nbPayment / nbTotalPayments * 100;
    prev[key].partMontant = prev[key].montant / totalTtcPayment * 100;
    prev[key].ticketMoyen = prev[key].montant / prev[key].nbPayment;

    return prev;
  }, {});

  return Object.keys(paymentsInDay).map(p => ({ id: p, ...paymentsInDay[p] }));
};

export default ContentUser;