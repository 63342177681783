import raMessages from 'ra-language-spanish';

const ra = {
  ...raMessages.ra,
  action: {
    ...raMessages.ra.action,
    confirm: 'Confirmar',
  },
  auth: {
    auth_check_error: 'Por favor inicie sesión para continuar'
  },
  navigation: {
    ...raMessages.navigation,
    page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
    next: 'Siguiente',
    prev: 'Anterior',
    no_results: 'Ningún resultado',
    page_rows_per_page: 'Líneas por página :',
  }
};

const customSpanishMessages = {
  ra,
  ACTIONS: {
    ACTIVATE: 'activar',
    CANCEL: 'Cancelar',
    CREATE: 'Crear',
    DELETE: 'Eliminar',
    DESACTIVATE: 'desactivar',
    DOWNLOAD: "Descargar",
    EDIT: 'Editar',
    LOGIN: 'Conectarse',
    LOGOUT: 'Desconexión',
    REFUND: "Reembolsar",
    RENEW: 'Restablecer',
    SAVE: 'Registrar',
    SEARCH: "Buscar",
    SEND: 'Enviar',
    SUBMIT: "Validar",
  },
  COMMON: {
    ACCOUNT_ACTIVATE: "Activar la cuenta",
    ACCOUNT_DESACTIVATE: "Desactivar la cuenta",
    ACCOUNT_SETTINGS: 'Configuración de la cuenta',
    ACTIVE: "activo",
    ADDRESS: "Dirección",
    AGENCY: 'Agencia',
    AGENCY_SELECT: 'Seleccione una agencia',
    AMOUNT: "Importe",
    AMOUNT_TTC: "Importe con IVA",
    AVERAGE_TICKET: "Tickete medio",
    BANK_CARD: "Tarjeta Bancaria",
    BANK_CARD_SHORT: 'TB',
    CARD_ADD: 'Añadir una tarjeta',
    CITY: "Ciudad",
    CLICK_TO_CHANGE_FILE: "Haga clic para cambiar el archivo",
    CLICK_TO_SELECT_FILE: "Haga clic para seleccionar un archivo (.pdf)",
    CLIENT_CODE: 'Código de cliente',
    CLIENT_WISH_USE_PAYGREEN: "¿El cliente quiere usar los tickets de restaurante?",
    CODE: "Codigo",
    COMMAND_ID: "ID Pedido",
    COMMENT: "Comentario",
    CONFIG_SAVED: "Configuración guardad",
    CONFIG_SAVED_FAILED: "Configuración no guardada, se ha producido un error",
    DASHBOARD: 'Panel de control',
    DATE: 'Fecha',
    DATE_AUTHORIZATION: "Fecha autorización",
    DATE_CAPTURE: "Fecha captura",
    DATE_CREATED: "Fecha de creación",
    DEVICE_USED: "Aparato utilizado",
    DISABLED: 'desactivado',
    DISTRIBUTION_BY_MOYEN: "Reparto por modos de pagos",
    DISTRIBUTION_BY_TIME: "Reparto Tarde/Noche",
    DISTRIBUTION_BY_TYPE: "Reparto por tipos de pagos",
    EDENRED_ID: "Identificador Edenred",
    EDIT_DATE: 'Modificación',
    EMAIL_ADDRESS: 'Dirección de correo electrónico',
    EMAIL_WILL_BE_SENT_ADDRESS: "Se enviará un correo electrónico a",
    END: "Fin",
    ERROR_LOGIN_FAIL: "Correo electrónico o contaseña no válida, o cuenta desactivada",
    ERROR_NOT_CREATED: "no creada, se ha producido un error",
    ERROR_PASSWORD_RESET: "Ha surgido un problema, por favor, renueve su solicitud de nueva contraseña",
    ETABLISHMENT_BUSINESS_NAME: "Nombre del establecimiento",
    ETABLISHMENT_NAME: 'Nombre Establecimiento',
    FEAT_CHARGEABLE_SEND_MAIL_TO: "Esta función tiene coste. Para disfrutarla, envíe un correo electrónico a ",
    FIRSTNAME: 'Nombre',
    FURTHER_INFORMATIONS: "Informaciones complementarias",
    HOME: 'Inicio',
    INACTIVE: 'inactivo',
    ITEMS: "Artículos",
    INFORMATIONS: "Informaciones",
    KEY: "Llave",
    KEY_API: "Llave api",
    KEY_PUBLIC: "Llave pública",
    KEY_SECRET: "Llave secreta",
    LINKED_TRANSACTIONS: "Transacciones asociadas",
    MENU_NAME: "Nom menu",
    MESSAGE: "Mensaje",
    NAME: 'Apellido',
    NIGHT: "NOCHE",
    NO: "No",
    NO_DATA_ON_RANGE: "NO HAY DATOS PARA LAS FECHAS ELEGIDAS",
    NOON: "TARDE",
    NOT_SUBSCRIBE: "No abonado",
    NP_SCORE: "NP Score",
    NUMBER: "Cantidad",
    OPERATION: "Operación",
    OTHER: 'Otro',
    PART: "PARTE",
    PASSWORD: 'Contraseña',
    PASSWORD_CONFIRM: 'Confirmar la contraseña',
    PASSWORD_FORGOT: '¿Ha olvidado su contraseña?',
    PASSWORD_NEW: "Nueva contraseña",
    PASSWORD_RENEW: 'Restablecer la contraseña',
    PASSWORD_UPDATED: "Su contraseña ha sido modificada",
    PAYGREEN_ID: "Identificador PayGreen",
    PAYMENT_INFO: "Información pago",
    PAYMENT_METHOD: "MODO DE PAGO",
    PAYMENT_TITLE_SHORT: 'TRD',
    PAYMENT_TYPE: "Tipo de pago",
    PAYMENTS_ID: "Identificadores de pagos",
    PAYPLUG_ID: "Identificador Payplug",
    PAYXPERT_ID: "Identificador PayXpert",
    PERCENTAGE: "Porcentaje",
    PERIOD: "Período",
    PREVIEW: 'Aperçu',
    PREVIEW_OF: 'Vista previa de',
    QRCODE_ADD: 'Añadir un QRCode',
    REFUNDED_AMOUNT: "Importe reembolsado",
    NOT_DEFINED_DATE: "",
    REFUNDS: "Reembolso",
    REFUNDS_ABR: "Reemb.",
    RESET_PASSWORD_REQUEST: 'Solicitud de restablecimiento de contraseña',
    RESTAURANT_CREATOR: "Creador del restaurante",
    RESTAURANT_TICKET: "Tickete Restaurante",
    ROLE: 'Papel',
    SELLER: "Vendedor",
    SERVICE: 'Servicio',
    SHOP_ID: "Id tienda",
    SIGNATORY_EMAIL: "Dirección de correo electrónico del firmante",
    SIGNATORY_FULLNAME: "Apellido y nombre del firmante",
    SIGNATORY_MOBILE_PHONE: "Número de teléfono móvil portable del firmante",
    SIRET_NUM: 'N° CIF',
    START: "Comienzo",
    STATUS: 'Statuto',
    STATUS_ACTIVE: 'activado',
    STATUS_CREATED: "creada",
    STATUS_INACTIVE: 'desactivado',
    STATUS_NOT_USED: 'No utilizado',
    STATUS_PENDING: 'En curso',
    STATUS_REFUNDED: "Reembolsada",
    STATUS_REFUNDED_PARTIAL: "Reembolsado parcialmente",
    STATUS_REFUSED: "Denegada",
    STATUS_SUCCESS: "Éxito",
    SUBMIT_RESET_PASSWORD: 'Enviar la solicitud',
    TABLE: 'Mesa',
    TABLE_NUM: "N°Mesa",
    TABLE_NUM_FULL: 'Numero de mesa',
    TABLE_NUM_QR_CODE: 'Numero de mesa asociado al QRCode',
    TICKET_DATE: 'Fecha tickete',
    TICKET_NUM: "N°Tickete",
    TILL_ID: "Identificador Caja",
    TILL_NAME: 'Caja registradora',
    TIPS: "Propinas",
    TIPS_ACTIVE: "Propina activa",
    TO_CONTINUE: "¿Quieres seguir con esto?",
    TODAY: "Hoy",
    TRANSACTION: "Transacción",
    TRANSACTION_DETAILS: 'Detalles de la transaccion',
    TRANSACTION_ID: "ID Transacción",
    TRANSACTION_NUM: "N°Transacción",
    TRANSACTIONS_ALL: "Todas las transacciones",
    TRANSACTIONS_EDENRED: 'Transacciones Edenred',
    TRANSACTIONS_PAYGREEN: 'Transacciones PayGreen',
    TRANSACTIONS_PAYPLUG: 'Transacciones PayPlug',
    TRANSACTIONS_PAYXPERT: 'Transacciones PayXpert',
    TURNOVER: "Volumen de negocios",
    TYPE_EMAIL: 'Introduzca su correo electrónico',
    VALUE: "Valor",
    YES: "Sí",
    YOU_ARE: 'Eres ?',
    ZIP_CODE: "Código postal",
    GENERAL_ERROR: "Se ha producido un error"
  },
  CARDS: {
    ACTIVATED: 'Tarjeta activada',
    CONFIRM_ACTIVATE: "¿Estás seguro de que quieres %{action} la tarjeta?",
    ACTIF_CARD: 'El menú %{nom} está activo.',
    CONFIRM_DELETE: '¿Estás seguro de que quieres eliminar esta tarjeta?',
    DELETE: 'Eliminar la tarjeta',
    DESACTIVATED: 'Tarjeta desactivada',
    NAME: 'Tarjeta',
    TITLE: 'Gestión de menús',
    TITLE_CREATE: 'Crear una tarjeta',
    TITLE_EDIT: 'Modificar una tarjeta',
    WARNING_OTHER_CARDS_DESACTIVATE: 'Attention: Tous les autres cartes seront désactivées automatiquement',
  },
  DASHBOARD: {
    HEADING: '¡piense en crear e imprimir sus códigos QR!',
    TITLE: 'Mis actividades',
    TIPS:{
      CSV_EXPORT_BTN: "Exportación CSV",
      CSV_EXPORT_FILENAME: "Propinas_%{start}_%{end}",
      CSV_EXPORT_PERIOD: "para el período : %{start} al %{end}",
      CSV_EXPORT_TITLE: "Exportación CSV de propinas",
      TOTAL: "Total"
    },
    QRCODES_BUTTON: '¡Crear mi QrCode !',
  },
  MODAL_BO_ACCESS: {
    MESSAGE: 'Confirmar la conexión con el Back-Office del restaurante',
    TITLE: 'Acceso BO impersonal'
  },
  MODAL_ONBOARDING: {
    MESSAGE: 'Avez-vous bien envoyé une demande d’onboarding à PayGreen pour le compte %{nom} ?',
    TITLE: 'Demande Onboarding PayGreen'
  },
  MODAL_REMINDER: {
    MESSAGE: 'Hizo un seguimiento del cliente %{nom} ?',
    TITLE: '%{service} - Por relanzar'
  },
  MODAL_RESTRICTION: {
    QRCODE_CREATE_TITLE: 'Ingrese su código para agregar un código QR',
    QRCODE_EDIT_TITLE: 'Ingrese su código para modificar un código QR',
    TIPS_TITLE: '',
    RESTRICT_TITLE: 'Ingrese su código para desactivar las restricciones',
    REFUND_TITLE: 'Ingrese su código para hacer un reembolso',
    MENU_CARDS_TITLE: '',
  },
  QRCODE: {
    CLOSED: 'Cerrado',
    DELETE: 'Eliminar el QRCode',
    DELETE_CONFIRMATION: '¿Estás seguro de que quieres eliminar este QRCode?',
    DOWNLOAD: 'Descargar los QrCode',
    DOWNLOAD_ALL: 'Descargar todos los QrCode',
    DOWNLOAD_SELECTED: 'Descargar los QrCode seleccionados',
    ERRORS: {
      DOWNLOAD_FAIL: 'Error al descargar los QrCodes',
      GENERATE_FAIL: 'Error de generación del QRCode'
    },
    MODAL_TITLE: 'QRCode de la mesa',
    NAME: 'QRCode',
    OPENNED: 'Abrió',
    TEMPLATES: {
      A4: {
        DESCRIPTION: "Esta exportación a PDF genera tus archivos en formato A4 para que puedas imprimirlos en tu impresora de oficina. Solo tiene que recortar los códigos QR y plastificarlos si lo desea.",
        TITLE: 'FORMATO A4'
      },
      CARD: {
        DESCRIPTION: "Esta exportación a PDF genera sus archivos en formato « tarjeta de visita », es decir, 85X55 mm (tamaño estándar), que puede imprimir su imprenta local.",
        TITLE: 'FORMATO DE TARJETA DE VISITA'
      },
      PLEXI: {
        DESCRIPTION: "Esta exportación PDF genera sus archivos en formato 6x6 cm para imprimir el código QR en vinilo blanco pegado sobre un plexiglás de 3 mm de grosor.",
        TITLE: 'FORMATO PLEXIGLÁS'
      },
      QRCODE_FOR_STICKER: {
        TITLE: ""
      }
    },
    TITLE: 'Gestión QRCodes',
    TITLE_CREATE: 'Crear un QRCode',
    TITLE_EDIT: 'Modificar QRCode',
    UNIQ_LINK: 'QRCode del enlace único de la mesa',
  },
  RESTAURANTS: {
    ADD_NEW: 'Añadir un nuevo restaurante',
    AUTHENT_DATA: 'Datos de autenticación',
    BO_ACCESS: 'Acceso BO',
    DO_SEND_ID: '¿Seguro que quieres reenviar las identificaciones?',
    EMAIL_ASSOCIATED: "Dirección de correo electrónico asociada",
    EMAIL_SENT_TO: "Se ha enviado un correo electrónico a",
    INFOS: 'Información del restaurante',
    NAME: 'Restaurante',
    NUM: 'Número de restaurante',
    ONBOARDING_DATE: 'Date demande onboarding',
    PLEASE_SELECT: "Por favor, seleccione un establecimiento",
    SEND_IDS: 'Reenviar las identificaciones',
    STATUS_ACTIVE: 'Restaurantes activos',
    STATUS_ENABLED: 'Restaurantes activados',
    STATUS_DISABLED: 'Restaurantes Desactivado',
    STATUS_INACTIVE: 'Restaurantes inactivos',
    TILL_TYPE_SELECT: 'Seleccione el tipo de caja',
    TITLE_CREATE: 'Nuevo restaurante',
    TITLE_EDIT: 'Modificar restaurante',
  },
  ROLES: {
    ADMIN: 'Administrador',
    MANAGER: 'Manager',
    TECH: 'Técnico'
  },
  SCORES: {
    AVERAGE: 'Puntuación media',
    NOTE: 'Puntuación',
    OS: 'OS',
    VERSION: 'Versión de aplicación',
  },
  SETTINGS: {
    ACCOUNT: {
      BTN_RETREIVE_CODE: 'Recuperar contraseña',
      MESSAGE: '** Se solicitará un código para realizar reembolsos y acceder a la configuración de propinas y códigos QR',
      FIELD_PIN: 'Ingrese el código de restricción',
      FIELD_CONFIRM_PIN: 'Confirme el código de restricción',
      SET_CODE_FAIL: 'El código de restricción es incorrecto',
      SET_CODE_SUCCESS: 'Código correcto, ahora puede desactivar las restricciones',
      TOGGLE_RESTRICTIONS: 'Activar las restricciones',
      RESTRICTIONS: 'Restricciones'
    },
    STATEMENT: {
      TRANSACTIONS_STATEMENT: 'Informe de operaciones',
      TOGGLE_TR_STATEMENT: 'Activar el informe de transacciones',
      DAILY: 'Diario',
      WEEKLY: 'Semanalmente',
      MONTHLY: 'Mensualmente',
      ANNUAL: 'Anual',
      MESSAGE: '**El extracto de transacciones se enviará a la dirección de correo electrónico asociada a la cuenta Banana Split'
    }
  },
  SIDEBAR: {
    CONSULTING: 'Consultas',
    CREATE: 'Creación',
    CREATE_ADMIN_USER: 'Crear Administrador de Usuarios',
    CREATE_RESTAURANT: 'Crear Restaurante',
    MANAGEMENT: 'Gestión',
    MENU_CARDS: 'Tarjetas de menú',
    MENU_SETTINGS: 'Gestión de menús',
    QRCODES: 'QRCode',
    RESTAURANTS: 'Restaurantes',
    SETTINGS: 'Ajustes',
    STATISTIQUES: 'Estadísticas',
    USERS: 'Usuarios',
  },
  TIPS: {
    ACTIVATE: "Activar las propinas",
    DEFAULT_SETTINGS: "Personalizar las propinas por defecto",
    TITLE: "Gestión Propinas",
    TRACKING: "Seguimiento de las propinas",
    TYPE_USED: "Tipo de propina que se utiliza",
    VALIDATION_MESSAGE: "La propina debe ser mayor que cero y no más de 2 dígitos después de la coma",
  },
  TRANSACTIONS: {
    AMOUNT_REFUND: "Importe por reembolsar",
    BLOCKED_REFUNDS_REASONS: {
      NOT_REFUNDED_AFTER_CAPTURE: '',
      REFUNDED_ONCE: '',
      REFUND_IMPOSSIBLE: ''
    },
    CONFIRM_REFUND: "¿Estás seguro de que quieres reembolsar %{amountToRefund} ?",
    DETAILS: "Detalles de las transacciones",
    ONBOARDING_REQUEST: 'Demande OnBoarding',
    PARTIAL_REFUND_DETAILS: '',
    REFUND: "Reembolso Transacción",
    REFUND_CONFIRM: {
      MESSAGE1: "¿Confirmar el reembolso de",
      MESSAGE2: "de la transacción",
      WARNING_UNIQ: "Atención: Solo puede hacer un reembolso en esta transacción",
      WARNING_TOTAL: "Attention: Vous ne pouvez rembourser que la totalité de la transaction avec Edenred",
    },
    REFUND_FAIL: "Nô se hace reembolso",
    REFUND_NOT_POSSIBLE: "La transacción no puede ser reembolsada",
    REFUND_NOT_POSSIBLE_ANYMORE: "La transacción ya no puede ser reembolsada",
    REFUND_PARTIAL: "Reembolsar parte de la transacción",
    REFUND_SUCCESS: "El reembolso se ha realizado.",
    REFUND_TOTAL: "Reembolsar la totalidad de la transacción",
    REFUNDED: "Reembolsada",
    REFUNDED_TOTAL: "",
    REMINDER_REQUEST: "Seguimiento",
    REST_TO_REFUND: "",
    TITLE: "Mis transacciones",
  },
  USERS: {
    ACCOUNT_ACTIVATED: 'la cuenta %{account} se ha activado',
    ACCOUNT_DESACTIVATED: 'la cuenta %{account} se ha desactivado',
    ACCOUNT_NOMORE_SHOW: 'Dejar de mostrar la cuenta en la lista',
    CONFIRM_ACTION_ACCOUNT: '¿Estás seguro de que quieres %{action} la cuenta?',
    NAME: 'Usuario',
    TITLE: 'Crear usuario',
    TITLE_CREATE: 'Nuevo usuario',
    TITLE_EDIT: 'Modificar usuario',
  },
  VALIDATION: {
    EXISTS: {
      CLIENT_CODE: "Este código de cliente ya existe",
      EMAIL: "Esta dirección de correo electrónico ya está en uso",
      NAME: "Este nombre ya existe",
      TABLE_NUM: "Este número de tabla ya existe.",
    },
    MANDATORY: {
      ADDRESS: "La dirección es obligatoria.",
      AGENCY: "La agencia es obligatoria",
      API_KEY: "La llave de la API de PayXpert es obligatoria",
      API_KEY_PAYPLUG: "La llave de la API de Payplug es obligatoria",
      BUSINESS_NAME: "La raison sociale est obligatoire.",
      CITY: "La ciudad es obligatoria.",
      CLIENT_NAME: "L'identité du client est obligatoire.",
      EMAIL: "El correo electrónico es obligatoria.",
      FIRSTNAME: "El nombre es obligatorio.",
      MID: "El MID de Edenred es obligatorio",
      NAME: "El apellido es obligatorio.",
      PASSWORD: "La contraseña es obligatoria.",
      PASSWORD_CONFIRM: "La confirmación de la contraseña es obligatoria.",
      PAYXPERT_ID: "El identificador PayXpert es obligatorio",
      PHONE: "`Le numéro de téléphone est obligatoire.",
      PRIVATE_KEY: "La llave privada de PayGreen es obligatoria",
      PUBLIC_KEY: "La llave pública de PayGreen es obligatoria",
      RESTRICTION_CODE: 'Un código PIN es obligatorio',
      ROLE: "El papel es obligatorio.",
      SECRET_KEY: "La clave secreta de PayGreen es obligatoria",
      SIRET: "El CIF es obligatorio.",
      SHOP_ID: "El id de la tienda PayGreen es obligatoria",
      TABLE_NUM: "El número de mesa es obligatorio.",
      TILL: "La caja es obligatoria.",
      ZIP_CODE: "El código postal es obligatorio.",
      STATEMENT_PERIOD: "El período es obligatorio."
    },
    SELECT: {
      ROLE: "Por favor, elija una función",	
    },
    TYPE: {
      BUSINESS_NAME: "La raison sociale doit contenir au maximum 100 caractères.",
      CLIENT_CODE: "El código de cliente sólo debe contener números",
      CLIENT_NAME: "L'identité du client ne doit contenir que des lettres",
      CLIENT_NAME_MAX: "L'identité du client doit contenir au maximum 100 caractères.",
      EMAIL: "El correo electrónico debe ser de tipo correo electrónico.",
      FIRSTNAME: "El nombre debe contener un máximo de 50 caracteres.",
      NAME: "El apellido debe contener un máximo de 50 caracteres.",
      PASSWORD_DIFFERENT: "La contraseña es diferente",
      PASSWORD_LENGHT: "entre 6 y 50 caracteres",
      PASSWORD_MIN: "La contraseña debe contener al menos 6 caracteres",
      PASSWORD_MAX: "La contraseña no debe superar los 50 caracteres",
      PHONE: "Le numéro de téléphone ne doit contenir que des chiffres.",
      RESTRICTION_CODE_CONFIRM: 'El campo de confirmación es diferente del código',
      RESTRICTION_CODE_LENGTH: 'El código PIN debe contener 4 dígitos',
      RESTRICTION_CODE_PATTERN: 'El código PIN solo debe contener números',
      SIRET: "Le numéro de SIRET doit contenir au maximum 14 chiffres.",
      TABLE_NUM: "El número de mesa debe contener solo números.",
      TABLE_NUM_MAX: "El número de mesa debe contener como máximo 4 dígitos.",
      TIPS: "La propina debe ser superior al 0"
    },
  },
};

export default customSpanishMessages;