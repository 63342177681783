import * as React from "react";
import { Edit, useResourceContext, usePermissions } from 'react-admin';
import { Box, Grid } from '@mui/material';
import { getRoleAndPermissions } from "../../utils/utils";
import GridItem from '../../styles/GridItem';
import Breadcrumb from './BreadCrumb';
import ListTitle from './ListTitle';
import i18n from '../../i18n';


const EditComponent = props => {
  const resource = useResourceContext();
  const globalPermissions = usePermissions();
  const { permissions, country, role } = getRoleAndPermissions(globalPermissions.permissions, resource);
  
  if (!permissions?.edit) return null;

  const childrenWithPerms = () => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { permissions, country, role });
      }
      return child;
    });
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomBreadcrumb resource={resource} title={props.title} breadcrumb={props.breadcrumb} />
      <ListTitle content={props.title} />
      <Grid container spacing={2} >
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <GridItem position="middle" nomarginbottom={props.noMarginBottom}>
            <Edit {...props} >
              {childrenWithPerms()}
            </Edit>
          </GridItem>
        </Grid>
      </Grid>
      {!props.heightAuto && <Box style={{ height: 300 }} />}
    </Box>
  );
};


const CustomBreadcrumb = ({ resource, title, breadcrumb }) => {
  if (!breadcrumb) return false;
  const path = (breadcrumb === '_self') ? '' : `#/${resource}`;
  const child = (breadcrumb === '_self') ? null : i18n.translate(breadcrumb);

  return <Breadcrumb label={title} path={path} child={child} />;
};

export default EditComponent;