import React, { useState, useEffect } from "react";
import { useTranslate, TextField, DateField, useResourceContext, usePermissions, 
  useGetList, useRecordContext, useResourceDefinitionContext, Loading
} from 'react-admin';
import { Grid } from "@mui/material";
import { StarHalf, Event, InfoOutlined, ChatBubbleOutline, Devices } from '@mui/icons-material';
import ListComponent from "../core/ListComponent";
import { muiIconStyles } from '../../styles/Styles';
import GridItem from '../../styles/GridItem';
import ScoresDonut from "../Chartist/ScoresDonut";
import Breadcrumb from "../core/BreadCrumb";
import ListTitle from "../core/ListTitle";
import { defaultMonthDateRangeFilter, getRoleAndPermissions } from "../../utils/utils";
import ListCellHeader from "../core/ListCellHeader";
import DateRangeFilters from "../common/DateRangeFilters";


const getScoreAverage = (data) => {
  const count = data.length;
  if(count)
  {
    let sum = 0;
    data.map(score => 
      sum += score.note
    );
    const avg = sum / count;
    return avg;
  }
  else
    return 0;
}

const ScoreList = props => {
  const idRestaurant = localStorage.getItem("idRestaurant");
  const translate = useTranslate();
  const globalPermissions = usePermissions();
  const role = globalPermissions?.permissions?.name;
  const resource = useResourceContext();
  const resourcesDefinitions = useResourceDefinitionContext();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
   const currentResource = resources[0][resource];
   const options =  currentResource?.options;
  const { startFilter, endFilter } = defaultMonthDateRangeFilter();
  const [startDate, setStartDate] = useState(localStorage.getItem('filterDateStart') || startFilter);
  const [endDate, setEndDate] = useState(localStorage.getItem('filterDateEnd') || endFilter);
  const [keyword, setKeyword] = useState('');
  const [average, setAverage] = useState(0);
  const { permissions } = getRoleAndPermissions(globalPermissions.permissions, resource);
  const filter =  {start: startDate, end: endDate};
  const { data: dataScores, isLoading: isLoadingScores } = useGetList(
    `statistiques/scores/${idRestaurant}`,
    {
      filter,
    }
  );

  useEffect(() => { 
 }, [startDate, endDate])
 

  useEffect(() => {
    if (!isLoadingScores && dataScores) {
      const avg = getScoreAverage(dataScores);
      setAverage(avg);
    } else setAverage(0);
  }, [isLoadingScores, dataScores]);
  

  const handleChangeStartDate = (date) => {
    setStartDate(new Date(date).toISOString().split('T')[0]);
  }
  const handleChangeEndDate = (date) => {
    setEndDate(new Date(date).toISOString().split('T')[0]);
  }
  const handleChangeKeyword = (val) => {
    setKeyword(val);
  };

  // const keywordFilter = [
  //   <TextInput
  //     source="keyword"
  //     label={translate('ACTIONS.SEARCH')}
  //     alwaysOn
  //     sx={transactionsListStyles.searchInput}
  //     className="raTextInput"
  //     id="generalSearch"
  //     key="keyword"
  //   />
  // ];
  if (!permissions) return null;
  if (isLoadingScores || !dataScores) return <Loading />;
  const label = translate(options.label);

  return (
    <>
      <Breadcrumb label={label} path={`/${resource}`} />
      <ListTitle content={label} />

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GridItem nomargin="true" minMarginBottom={true}>
          <DateRangeFilters
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeKeyword={handleChangeKeyword}
          />
        </GridItem>
      </Grid>

      <ListComponent
        {...props}
        translate={translate}
        filter={{ start: startDate, end: endDate, keyword: keyword }}
        perPage={25}
        sort={{ field: 'created', order: 'DESC' }}
        filterDefaultValues={{ start: startDate, end: endDate, keyword: keyword }}
        statsComponent={<ScoresDonut data={average} />}
        hideTitle={true}
      >
        <DateField
          locales="fr-FR"
          source="created"
          showTime
          label={<ListCellHeader icon={<Event style={muiIconStyles.primary} />} text={translate('COMMON.DATE')} />}
        />
        <TextField source="note" label={<ListCellHeader icon={<StarHalf style={muiIconStyles.secondary} />} text={translate('SCORES.NOTE')} />} />
        <CommentField width={'40%'} label={<ListCellHeader icon={<ChatBubbleOutline style={muiIconStyles.success} />} text={translate('COMMON.COMMENT')} />} />
        {role !== 'user' && (
          <TextField source="infos_os" label={<ListCellHeader icon={<Devices style={muiIconStyles.danger} />} text={translate('SCORES.OS')} />} />
        )}
        {role !== 'user' && (
          <TextField source="version_app" label={<ListCellHeader icon={<InfoOutlined style={muiIconStyles.warning} />} text={translate('SCORES.VERSION')} />} />
        )}
      </ListComponent>
    </>
  );
};

const CommentField = () => {
  const record = useRecordContext();
  return <span style={{ whiteSpace: "normal" }}>{record.comment}</span>
}


export default ScoreList;