import * as React from 'react';
import GridItem from '../styles/GridItem';
import Grid from '@mui/material/Grid';
import CustomAppBar from './CustomAppBar';
import { Notification, useGetPermissions } from 'react-admin';
import CustomSidebar from './CustomSidebar';
import { sideBarResponsiveStyles } from '../styles/Styles';
import Box from '@mui/material/Box';
import { layoutStyles } from '../styles/Styles';
import { useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';


const CustomLayout = ({children}) => {
    const [role, setRole] = useState();

    const getPermissions = useGetPermissions();
    useEffect(() => {
        getPermissions().then(p => {
            setRole(p.name);
        });
        // Cleanup function de useEffect: utilisé pour empecher memory leak qui peut etre causé par le warning 'Can't perform a React state update on an unmounted component' dans la console
        return () => {
            setRole();
        };
    }, [getPermissions]);
    const isSM = useMediaQuery('(max-width:899px)');

    const isNotAdmin = (role === 'resp' || role === 'tech');
    const showSideBarStyle = (isNotAdmin) ? 'none' : ['none', 'none', 'block', 'block'];
    return (
            <Grid container spacing={2} sx={layoutStyles.topContainer} >
                { isSM && 
                    <Grid item lg={12} md={12} sm={12} xs={12}  sx={layoutStyles.mobileSideBarContainer} id="mobileMenu" state="close" onClick={window['closeMenuMobile']}>
                        <Box className='sidebarContent' sx={layoutStyles.mobileSideBarContent}>
                            <CustomSidebar/>
                        </Box>
                    </Grid> }
                <Grid item lg={12} md={12} sm={12} xs={12}  >
                    <CustomAppBar />
                </Grid> 
                <Grid container id="mainContainer" sx={layoutStyles.mainContainer}> 
                { !isSM && 
                    <Grid item lg={2.2} md={4} sm={12} xs={12} id="mainMenu" state={isNotAdmin ? "close" : "open"} sx={{...layoutStyles.sidebar, display: showSideBarStyle}}>
                        <GridItem position="middle" sx={sideBarResponsiveStyles} nomargin="true" >
                                <CustomSidebar/>
                        </GridItem>
                    </Grid>
                }
                    <Grid item lg={(isNotAdmin) ? 12 : 9.8} md={(isNotAdmin) ? 12 : 8} sm={12} xs={12} id="mainContent">
                        <GridItem id="children-container" position="middle" maxmargin="true" nobg="true" nopadding="true" sx={layoutStyles.content}>
                            {children}
                        </GridItem>
                    </Grid>
                    <Grid item lg={10} md={12} sm={12} xs={12} >
                        <Notification />
                    </Grid>
                </Grid> 
                <Box style={{minHeight:'100vh'}} />
            </Grid>
    );
};

export default CustomLayout;