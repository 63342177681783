import * as React from 'react';
import {
  TextInput,
  BooleanInput,
  useTranslate,
  useRecordContext,
} from 'react-admin';
import {
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import { useState } from 'react';

const RestrictionsForm = ({isLoggedInByRoleTech}) => {
  const t = useTranslate();
  const r = useRecordContext();
  const [actif, setActif] = useState(r?.actif);
  const isActivating = !r.actif && actif;
  if (r?.actif) r.none = '****' // valorise une source fausse dans certain cas où les champs fake sont vident
 
  const handleChangeActif = () => {
    setActif(!actif);
  };


  return (
    <Grid container spacing={2} >
      <Grid item xs={12} >
        <BooleanInput source="actif" label={t('SETTINGS.ACCOUNT.TOGGLE_RESTRICTIONS')} onChange={handleChangeActif} disabled={isLoggedInByRoleTech} />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ padding: '0 8px', fontSize: '12px' }}>{t('SETTINGS.ACCOUNT.MESSAGE')}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Stack direction="column">
          <CustomInput
            label={t('SETTINGS.ACCOUNT.FIELD_PIN')}
            source="pin"
            isActivating={isActivating}
            isActif={actif}
            isLoggedInByRoleTech={isLoggedInByRoleTech}
          />
          <CustomInput
            label={t('SETTINGS.ACCOUNT.FIELD_CONFIRM_PIN')}
            source="pinConfirm"
            isActivating={isActivating}
            isActif={actif}
            isLoggedInByRoleTech={isLoggedInByRoleTech}
          />
      </Stack>
    </Grid>
</Grid>
);
};

const CustomInput = ({ label, source, isActivating, isActif, isLoggedInByRoleTech }) => {
  if (isActif && !isActivating) return <TextInput source="none" variant='outlined' label={label} className="raTextInput" defaultValue="****" disabled />;

  return (
    <TextInput
      label={label}
      source={source}
      className="raTextInput"
      disabled={!isActif || isLoggedInByRoleTech}
      defaultValue=""
    />
  );
};


export default RestrictionsForm;