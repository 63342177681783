import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customFrenchMessages from './fr';
import customSpanishMessages from './es';

const getMessages = (locale) => {
  // Locale depuis le restaurant/role
  const restaurantLocale = localStorage.getItem('country');
  if (restaurantLocale === 'ES') return customSpanishMessages;
  if (restaurantLocale === 'FR') return customFrenchMessages;
  // Locale depuis le browser
  return (locale.toLowerCase() === 'es') ? customSpanishMessages : customFrenchMessages;
};

const i18n = polyglotI18nProvider(getMessages, resolveBrowserLocale({ fullLocale: true }), { allowMissing: true });

export default i18n;