import * as Crypto from 'crypto-js';
import Roles from './Roles';
import { clearSessionLocalStorage, clearAuthLocalStorage, setAuthLocalStorage } from './utils';
import jwt_decode from 'jwt-decode';

let role = '';
let isAdmin = 'false';

const AuthProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    localStorage.removeItem('idRestaurant');
    const HMAC_KEY = process.env.REACT_APP_HMAC_KEY;
    const body = {
      backoffice_login: username,
      backoffice_password: password,
      anti_sniffer: Math.random(),
    };
    const _encrypt = Crypto.enc.Hex.stringify(Crypto.HmacSHA256(JSON.stringify(body), body.backoffice_login + HMAC_KEY));
    const url_authent = `${process.env.REACT_APP_URL_API}/authentication/login`;

    const request = new Request(url_authent, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        'x-signature': _encrypt,
        'x-from-origin': 'radmin',
      }),
    });

    try {
      const response = await fetch(request);
      if (response && response.status !== 200)
        throw new Error(response.statusText);
      const auth = await response.json();
      localStorage.setItem('username', username);
      setAuthLocalStorage(auth);

      return auth;
    } catch (err) {
      throw new Error(err);
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    clearSessionLocalStorage();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      clearAuthLocalStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: (e) => {
    if (!role || role !== localStorage.getItem('role') || isAdmin !== localStorage.getItem('isAdmin')) { // cas modification dans le local storage des clés role et isAdmin
      const token = localStorage.getItem('token');
      const decoded = (token) ? jwt_decode(token) : {};
      role = decoded?.role;
      isAdmin = decoded?.isAdmin;
      const loggedInByRole = (decoded?.logged_in_by_role) ? decoded.logged_in_by_role : false;
      const country = (role === 'user') ? localStorage.getItem('country') : decoded?.country;
      localStorage.setItem('role', role);
      localStorage.setItem('country', country);
      localStorage.setItem('isAdmin', isAdmin);
      localStorage.setItem('loggedInByRole', loggedInByRole);
    }
    const permissions = Roles(localStorage.getItem('role'), localStorage.getItem('country'));

    return (permissions) ? Promise.resolve(permissions) : Promise.reject();
  },
};

export default AuthProvider;