import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const DesignedTextField = styled(TextField)({
    background: 'white',
    borderRadius : 5,
    border: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle:'solid',
    '& .MuiFilledInput-root': {
      background:'white'
    },
    '& .MuiFilledInput-root:before': {
        borderBottom: 'none',
    },
    '& .MuiFilledInput-root:after' : {
      border: '1px solid rgb(3, 201, 215) !important',
    },
    '& .MuiFilledInput-root:hover': {
      background: 'white',
  },
    '& .MuiFilledInput-root:hover:before' : {
      borderBottom: 'none !important'
    },
  });

const CustomTextField = (props) => {
  const { name, defaultValue, multiline, onChange, type, fullWidth, disabled, error, errorText } = props;
    const isMultiline = (typeof multiline !== 'undefined' && multiline === true) ? true : false;
    return (
        <DesignedTextField
            name={name}
            onChange={onChange}
            error={error}
            helperText={errorText}
            size="small"
            defaultValue={defaultValue}
            multiline={isMultiline}
            fullWidth={fullWidth}
            hiddenLabel
            type={type}
            autoComplete={name === "password" ? "new-password" : ""} autoFocus={false}
            disabled={disabled}
      />
    );
};

export default CustomTextField;