import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import { useTranslate, useCreate } from 'react-admin';
import { useState, useEffect, useRef } from 'react';

const _HIDE_CHAR = '*';

const RestrictionModal = ({ open, title, onClose, handleSubmit }) => {
  const t = useTranslate();
  const forbidTimeout = useRef();
  const [fakeVal, setfakeVal] = useState('');
  const [pin, setPin] = useState('');
  const [error, setError] = useState(t('SETTINGS.ACCOUNT.SET_CODE_FAIL'));
  const [showError, setShowError] = useState(false);
  const [count, setCount] = useState(0);
  const [requestValidPin, { isLoading: isLoadingValidPin, data: dataValidPin }] = useCreate();
  
  const idRestaurant = localStorage.getItem('idRestaurant');
  const _NB_TENTATIVES = 5;
  const _DELAY = 10000;
  
  useEffect(() => {
    // check le code à 4 chiffre saisit // si moins de 5 essais
    if (pin.length === 4) { // && count <= _NB_TENTATIVES
      // setCount(count + 1);
      requestValidPin(`settings/account/checkPin`, { data: { idRestaurant, pin } });
    }
  }, [pin]);

  useEffect(() => {
    if (!isLoadingValidPin && dataValidPin) {
      if (dataValidPin.isValid) {
        setfakeVal('');
        setPin('');
        handleSubmit();
      }
      else setShowError(true);
      // if (count <= _NB_TENTATIVES) {
      //   if (dataValidPin.isValid) {
      //     setfakeVal('');
      //     setPin('');
      //     handleSubmit();
      //   }
      //   else setShowError(true);
      // }
      // else {
      //   clearSubmitTimeout();
      //   setError('TROP D\'ESSAI !!');
      //   setShowError(true);
      //   startSubmitTimout();
      // }
    }
  }, [isLoadingValidPin]);

  // const clearSubmitTimeout = () => {
  //   clearTimeout(forbidTimeout.current);
  // };
  
  // const startSubmitTimout = () => {
  //   forbidTimeout.current = setTimeout(() => {
  //     setCount(0);
  //     setError(t('SETTINGS.ACCOUNT.SET_CODE_FAIL'));
  //   }, _DELAY);
  // };

  const handleChange = (e) => {
    const fieldValue = e.target.value;
    // valorisation du champ avec les valeurs saisit remplacé par des *
    const fake = getFakeValue(fieldValue.length)
    setfakeVal(fake);
    // récupère le nombne tapé
    const val = fieldValue ? fieldValue.split(_HIDE_CHAR).pop() : fieldValue;
    // on détermine la valeur du pin
    const newVal = (val) // un caractère est saisi
      ? (val === fieldValue) // 1er chiffre
        ? val
        : `${pin}${val}` // on ajoute le chiffre au pin
      : pin.slice(0, -1); // touche delete, on retire le dernier char
    setPin(newVal);
    // on cache le message d'erreur
    if (fieldValue.length <= 4) setShowError(false);
  };

  const handleClose = () => {
    setfakeVal('');
    setPin('');
    setShowError(false);
    onClose();
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <TextField
                className="raTextInput"
                variant="standard"
                autoFocus={true}
                sx={{ textAlign: 'center' }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', textAlign: 'center' }}
                onChange={handleChange}
                onFocus={(e) => {e.target.select()}}
                error={showError}
                value={fakeVal}
              />
              {showError && <ErrorMessage message={error} />}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          {t('ACTIONS.CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ErrorMessage = ({ message }) => {
  const styles = {
    position: 'absolute',
    fontSize: '10pt',
    color: '#d32f2f',
  };
  
  return <Typography sx={styles}>{message}</Typography>;
};

/**
 * Retourne les caractères saisis remplacé par des *
 *
 * @param {Number} len Nombre de caractères saisis
 * @returns {String} Example: ****
 */
const getFakeValue = (len) => {
  let i = 0;
  let val = '';
  while (i < len) {
    val = `${val}${_HIDE_CHAR}`;
    i++;
  }

  return val;
};

export default RestrictionModal;