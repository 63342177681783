import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ConfirmModal = ({ show, t, title, message, handleCancel, handleSubmit }) => {
  if (!show) return null;
  
  return (
    <Dialog
      open={true}
      onClose={() => handleCancel(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => handleCancel(false)}>
            {t('COMMON.NO')}
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            {t('COMMON.YES')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;