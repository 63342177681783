import { makeStyles } from '@mui/styles';


export  const useUrlFieldStyles = makeStyles(
    {
        link: {
            textDecoration: 'none',
        },
        icon: {
            width: '0.5em',
            height: '0.5em',
            paddingLeft: 2,
        },
    }
)

export  const useMenuItemStyles = makeStyles(
    {
        root:{
            margin: 0,
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: 3,
            marginLeft: 5,
            marginRight: 5,
            marginTop: 10,
            marginBottom: 10,
            padding:'0 10px',
            borderRadius: '9px',
            color:'white'
        },
        active:{
            background:'none',
            fontWeight: 400,
            color:'white',
            '&:hover':{
                background:'rgba(3, 201, 215, 0.8)'
            }
        },
        icon:{
            color:'inherit',
        },
    }
)

export  const useQuickAccessStyles = makeStyles(
    {
        startIcon:{
            '& svg': {
                fontSize:"50px !important",
                opacity:0.5,
                position:'absolute',
                top:'25%', 
                left:'5%'
                },
        },
    }
)

export const useQuickAccessOutlinedStyles = makeStyles({
    root:{
        '& :hover': {
            opacity:0.5,
            },
    },
});


export const useAccordionStyles = makeStyles({
    root:{
        backgroundColor:' #a4c3df54 !important',
        marginBottom:"10px",
        fontFamily:'DM Sans !important', borderRadius:"10px !important",
        overflow:'hidden',
        '&:before':{
            backgroundColor:"rgb(0 0 0 / 6%) !important",
            left:"5px !important", right:"5px !important"
        }
    },
    region:{ background: '#fff'}
  });

// export const useLoginPageStyles = makeStyles({
//     root:{
//         fontFamily:'DM Sans !important', borderRadius:"10px !important",
//         '&:before':{
//             backgroundColor:"rgb(0 0 0 / 6%) !important",
//             left:"5px !important", right:"5px !important"
//         }
//     },
//     region:{ background: '#eee'}
//   });


export const useMenuItemAppBarStyles = makeStyles({
    root: { padding: 10, '& svg': { color: "rgb(251, 150, 120)" }, background:'white' },
    selected: { backgroundColor: 'rgba(0, 0, 0, 0.08) !important' }
  });

export const useMenuItemListStyles = makeStyles({
    root: { margin: 0, borderRadius: 0, '& svg': { color: "rgb(251, 150, 120)" }, background:'white', '& .MuiList-root': {padding:0} },
});

export const useMenuListStyles = makeStyles({
    paper: { borderRadius: 0 },
    list: { padding: 0, width: '295px', borderRadius:0 },
});

export const useSelectAppBarStyles = makeStyles({
    root: { color: 'white', },
    outlined: { display: 'flex', alignItems: 'center', padding: 0, paddingRight:'20px !important'},
    icon: { color: 'white' },
    iconOpen: { color: 'white' },
    nativeInput: { border: 'none'}
  });



