import * as React from 'react';
import {
  Box,
} from '@mui/material';
import {
  List,
  SimpleList,
  Datagrid,
  Pagination,
  PaginationActions,
  useResourceContext,
  useResourceDefinitionContext,
  useTranslate,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import Breadcrumb from './BreadCrumb';
import ListTitle from './ListTitle';
import { transactionsListStyles, dashboardStyles } from '../../styles/Styles';
import Grid from '@mui/material/Grid';
const GlobalPaginationActions = props => {  
  return(
    <PaginationActions
        {...props}
        // these props are passed down to the MUI <Pagination> component
        color="primary"
        showFirstButton
        showLastButton
    />
  )
};

const GlobalPagination = props => {
  return(
    <Pagination ActionsComponent={GlobalPaginationActions} {...props} />
  )
};

const ListComponent = props => {
  const isSmall = useMediaQuery('(max-width:899px)');
  const resource = useResourceContext();
  const resourcesDefinitions = useResourceDefinitionContext();
  const t = useTranslate();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  const currentResource = resources[0][resource];
  if (!currentResource) return null;
  
  const options = resources[0][resource]?.options;
  const label = t(options.label);
  

  const desktopList = (bulkActionButtons) => (
    <Datagrid
      rowStyle={props.rowStyle ? props.rowStyle : () => {}}
      optimized
      rowClick={props.rowclick}    
      expand={props.expand}
      expandSingle
      isRowExpandable={props.isRowExpandable}
      bulkActionButtons={bulkActionButtons ? bulkActionButtons : false}
    >
      {props.children}
    </Datagrid>
  );

  const mobileList = () => (
    <SimpleList {...props.mobilelist} />
  );

  return (
    <Box>
      {!props.hideTitle && <>
        <Breadcrumb label={label} path={`#${props.basePath}`} />
        <ListTitle content={label} />
      </>}
      {props.additionnalfilter && props.additionnalfilter()}
      {(props.statsComponent)
      ? <ListWithStatsComponent {...props}  transactionsListStyles={transactionsListStyles} isSmall={isSmall} mobileList={mobileList} desktopList={desktopList} dashboardStyles={dashboardStyles} /> 
      : <StandardList {...props}  transactionsListStyles={transactionsListStyles} isSmall={isSmall} mobileList={mobileList} desktopList={desktopList}  />}
    </Box>
  );
};

const ListWithStatsComponent = (props) => {
  return <Grid container spacing={2} sx={props.dashboardStyles.container}>
  <Grid item lg={8} md={8} sm={12} xs={12}>
      <List
        {...props}
        sort={props.sort}
        empty={false}
        actions={(props.actions) ? props.actions : false}
        pagination={<GlobalPagination translate={props.translate}/>}
      >
          {(props.isSmall) ? props.mobileList() : props.desktopList(props.bulkActionButtons)}
      </List>
  </Grid>
  <Grid item lg={4} md={4} sm={12} xs={12}> 
      {props.statsComponent}
  </Grid>
</Grid>
}


const StandardList = (props) => {
  return <List
        {...props}
        classes={props.listStyle}
        empty={false}
        bulkActionButtons={props.bulkActionButtons ? props.bulkActionButtons : false}
        actions={(props.actions) ? props.actions : false}
        className="listWithFilter"
        style={props.transactionsListStyles.list}
        pagination={<GlobalPagination translate={props.translate}/>}
      >
          {(props.isSmall) ? props.mobileList() : props.desktopList()}
      </List>
}

export default ListComponent;