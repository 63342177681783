import * as React from 'react';
import { Toolbar, SaveButton, Button, useRecordContext } from 'react-admin';
import { SaveOutlined, Send } from '@mui/icons-material';
import { btnStyles } from '../../../../styles/Styles';

const CustomToolbar = ({ t, showReinitButton, handleReinitCode, handleSubmit, isLoggedInByRoleTech }) => {
  const r = useRecordContext();
  const hasRestriction = r?.actif && r?.pin;

  return (
    <Toolbar>
      <SaveButton
        label={t('ACTIONS.SAVE')}
        sx={btnStyles.success}
        icon={<SaveOutlined />}
        onClick={handleSubmit}
        disabled={isLoggedInByRoleTech}
      />
      <ReinitCodeButton label={t('SETTINGS.ACCOUNT.BTN_RETREIVE_CODE')} show={showReinitButton || hasRestriction} handleReinitCode={handleReinitCode} isLoggedInByRoleTech={isLoggedInByRoleTech} />
    </Toolbar>
  );
};

const ReinitCodeButton = ({ show, label, handleReinitCode, isLoggedInByRoleTech }) => {
  if (!show) return null;

  return (
    <Button
      label={label}
      startIcon={<Send />}
      variant='contained'
      size="large"
      color="warning"
      onClick={handleReinitCode}
      disabled={isLoggedInByRoleTech}
    />
  )
};

export default CustomToolbar;