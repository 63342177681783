import * as React from 'react';
import Box from '@mui/material/Box';
import Breadcrumb from './core/BreadCrumb';
import ListTitle from './core/ListTitle';

const Assistance = (props) => {
  return (
    <Box>
        <Breadcrumb label={"Assistance"} path={`#${props.basePath}`} />
        <ListTitle content="Assistance" /> 
    </Box>
  );
}

export default Assistance;
