import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { listDetailsStyles } from '../styles/Styles';
import { stylesTableCell } from '../styles/Styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';


function descendingComparator(a, b, orderBy, translate) {
    const _paymentModeMapping = [
      translate('COMMON.BANK_CARD_SHORT'),
      "AMEX",
      translate('COMMON.PAYMENT_TITLE_SHORT'),
      "SWILE",
      "RESTOFLASH",
      "EDENRED",
      "GOOGLE PAY",
      "APPLE PAY",
      translate('OTHER'),
    ];
    if(orderBy === "type_carte")
    {
      if (_paymentModeMapping[b[orderBy]] < _paymentModeMapping[a[orderBy]]) {
          return -1;
      }
      if (_paymentModeMapping[b[orderBy]] > _paymentModeMapping[a[orderBy]]) {
          return 1;
      }
      return 0;
    }
    else
    {
      if (b[orderBy] < a[orderBy]) {
          return -1;
      }
      if (b[orderBy] > a[orderBy]) {
          return 1;
      }
      return 0;
    }
}

export function getComparator(order, orderBy, translate) {
    return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, translate)
    : (a, b) => -descendingComparator(a, b, orderBy, translate);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



export function CustomTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={listDetailsStyles.tableCellHeader} align={headCell.align}
            >
             {headCell.id ?  <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={headCell.id ? createSortHandler(headCell.id) : null}
                style={{ flexDirection:'row' }}
              >
                <Box sx={stylesTableCell.listHeaderCell}>{headCell.icon} {headCell.label}</Box> 
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              : <Box sx={stylesTableCell.listHeaderCell}>{headCell.icon} {headCell.label}</Box> 
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }