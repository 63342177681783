import { makeStyles } from '@mui/styles';
const BreadcrumbStyle = makeStyles({
    root: {
      fontFamily: "DM Sans !important",
      fontSize:'1.1rem !important',
      display:'inline-block'
    },
    separator: {
        fontSize:'1.5rem !important',
        color:"rgb(119, 126, 137)",
        margin: "0 15px !important"
    },
    li : {
        '& a': { lineHeight:1}
    }
});

export default BreadcrumbStyle;