import React, { useState, useEffect } from "react";
import { TextField, DateField, FunctionField, useTranslate, useGetOne, useGetList, Loading } from 'react-admin';
import ListComponent from "../core/ListComponent";
import { muiIconStyles } from "../../styles/Styles";
import { EventOutlined, InfoOutlined, EuroOutlined, PaymentsOutlined, ReceiptOutlined, TableRestaurantOutlined, RestartAltOutlined } from '@mui/icons-material';
import { defaultWeekDateRangeFilter, formatNumber } from "../../utils/utils";
import ListCellHeader from "../core/ListCellHeader";
import DateRangeFilters from '../common/DateRangeFilters';
import TransactionPanel from "./TransactionPanel";
import { Box } from "@mui/material";

const _RA_FILTER_LIST_TRANSACTIONS = 'RaStore.transactions/liste/:idRestaurant.listParams';


const TransactionsList = props => {
  const listcompFilters = localStorage.getItem(_RA_FILTER_LIST_TRANSACTIONS);
  const filtersObj = listcompFilters ? JSON.parse(listcompFilters) : {};
  const translate = useTranslate();
  const { startFilter, endFilter } = defaultWeekDateRangeFilter();
  const [startDate, setStartDate] = useState(localStorage.getItem('filterDateStart') || startFilter);
  const [endDate, setEndDate] = useState(localStorage.getItem('filterDateEnd') || endFilter);
  const [keyword, setKeyword] = useState(filtersObj?.filter?.keyword || '');
  const [openPanel, setOpenPanel] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const idRestaurant = localStorage.getItem('idRestaurant');
  const { data: restrictions, isLoading: isLoadingRestrictions } = useGetOne(`settings/account`, { id : idRestaurant  });
  const { data: listTransactions, isLoading: isLoadingListTransactions } = useGetList(`transactions/liste/${idRestaurant}`, { filter: { start: startDate, end: endDate }, pagination: false });
  const [hasRestrictions, setHasRestrictions] = useState(false);
  

  useEffect(() => {
    if (!isLoadingRestrictions && restrictions) setHasRestrictions(restrictions?.actif);
  }, [isLoadingRestrictions]);

  const handleChangeStartDate = (date) => {
    setStartDate(new Date(date).toISOString().split('T')[0]);
  };
  const handleChangeEndDate = (date) => {
    setEndDate(new Date(date).toISOString().split('T')[0]);
  };
  const handleChangeKeyword = (val) => {
    const filters = JSON.stringify({ ...filtersObj, filter: { ...filtersObj?.filter, keyword: val } });
    localStorage.setItem(_RA_FILTER_LIST_TRANSACTIONS, filters);
    setKeyword(val);
  };

  const dateFilters = (
    <DateRangeFilters
      keyword={keyword}
      handleChangeStartDate={handleChangeStartDate}
      handleChangeEndDate={handleChangeEndDate}
      handleChangeKeyword={handleChangeKeyword}
    />
  );

  const mobileList = {
    primaryText: record => record.orderId,
    secondaryText: record => formatNumber(record.amount),
    tertiaryText: record => record.status,
  };

  const switchTransaction = (id) => {
    if(!id) return setTransaction(false);
    const tr = listTransactions.find(t => t.transaction_id === id)
    setTransaction(tr);
  }


  const rowClick = (id, resource, record) => {
    setOpenPanel(true);
    setTransaction(record);
    
    return false;
  }

  const closePanel = () => {
    setTransaction(false);
    setOpenPanel(false);
  }

  const togglePanel = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
      return;

    setOpenPanel(open);
  };

  if (isLoadingListTransactions || isLoadingRestrictions) return <Loading />;

  return (<>
      <TransactionPanel open={openPanel} togglePanel={togglePanel} closePanel={closePanel} transaction={transaction} translator={translate} switchTransaction={switchTransaction} startDate={startDate} endDate={endDate} hasRestrictions={hasRestrictions} />
      <ListComponent
        {...props}
        mobilelist={mobileList}
        filter={{ start: startDate, end: endDate, keyword }}
        filters={dateFilters}
        perPage={25}
        sort={{ field: 'date_transaction', order: 'DESC' }}
        filterDefaultValues={{ start: startDate, end: endDate, keyword }}
        rowclick={rowClick}
      >
        <DateField
          locales="fr-FR"
          source="date_transaction"
          showTime
          label={<ListCellHeader icon={<EventOutlined sx={muiIconStyles.primary} />} text={translate('COMMON.DATE')} />}
        />
        <TextField
          source="numero_table"
          label={<ListCellHeader icon={<TableRestaurantOutlined sx={muiIconStyles.secondary} />} text={translate('COMMON.TABLE_NUM')} />}
          textAlign="right"
        />
        <TextField
          source="numero_ticket"
          label={<ListCellHeader icon={<ReceiptOutlined sx={muiIconStyles.warning} />} text={translate('COMMON.TICKET_NUM')} />}
          textAlign="right"
        />
        <TextField
          source="numero_transaction"
          label={<ListCellHeader icon={<ReceiptOutlined sx={muiIconStyles.success} />} text={translate('COMMON.TRANSACTION_NUM')} />}
          textAlign="right"
        />
        <TextField
          source="service"
          label={<ListCellHeader icon={<InfoOutlined sx={muiIconStyles.warning} />} text={translate('COMMON.SERVICE')} />}
          textAlign="right"
        />
        <FunctionField
          source="pourboire"
          render={(record) => formatNumber(record.pourboire)}
          label={<ListCellHeader icon={<PaymentsOutlined sx={muiIconStyles.danger} />} text={translate('COMMON.TIPS')} />}
          textAlign="right"
        />
        <FunctionField
          source="remboursements"
          render={(record) => formatNumber(record.remboursements)}
          label={<ListCellHeader icon={<RestartAltOutlined sx={muiIconStyles.danger} />} text={translate('COMMON.REFUNDS_ABR')} />}
          textAlign="right"
        />
        <FunctionField
          source="montant"
          render={(record) => <b>{formatNumber(record.montant)}</b>}
          label={<ListCellHeader icon={<EuroOutlined sx={muiIconStyles.danger} />} text={translate('COMMON.AMOUNT')} />}
          textAlign="right"
        />
        <FunctionField
          source="statut"
          render={(record) =>  <Box color={'white'} bgcolor={record.statusColor} width={'73px'} height={'32px'} lineHeight={'32px'} borderRadius={'50px'} marginLeft={'auto'} marginRight={'auto'} fontSize={"0.8125rem"}>{record.statut}</Box>}
          label={<ListCellHeader icon={<InfoOutlined sx={muiIconStyles.secondary} />} text={translate('COMMON.STATUS')} />}
          textAlign="center"
        />
      </ListComponent>
    </>
  );
};

export default TransactionsList;