import {useState, useCallback, useEffect} from 'react'
import { useLocation } from "react-router-dom";
import { clearSessionLocalStorage } from '../utils/utils';

const _limitTimer = 12000; // duration in seconds
const _step = 60; // duration in seconds

function SessionTimer() {
    const initialTimer = localStorage.getItem("timer") ?? _limitTimer;
    const [timer, setTimer] = useState(initialTimer);
    const location = useLocation()
    const [route, setRoute] = useState({
        to: location.pathname,
        from: location.pathname
    });

    const countTimer = useCallback(() => {
        if (timer <= 0) {
            clearSessionLocalStorage();
            window.location.reload();
        } else {
            setTimer(timer - _step);
            if(timer % 5 === 0) {
                localStorage.setItem("timer", timer);
            }
        }
    }, [timer]);

    useEffect(()=> {
        setRoute((prev)=> ({to: location.pathname, from: prev.to}))
        if(route.to !== route.from) setTimer(_limitTimer)
    }, [location]);
    
    useEffect(() => {
        const timeout =  setTimeout(countTimer, _step * 1000);
        return () => clearTimeout(timeout);
    }, [timer, countTimer]);
}

export default SessionTimer
