import * as React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { SaveOutlined } from '@mui/icons-material';
import { btnStyles } from '../../../../styles/Styles';

const CustomToolbar = ({ t, handleSubmit, isLoggedInByRoleTech }) => {

  return (
    <Toolbar>
      <SaveButton
        label={t('ACTIONS.SAVE')}
        sx={btnStyles.success}
        icon={<SaveOutlined />}
        onClick={handleSubmit}
        disabled={isLoggedInByRoleTech}
      />
    </Toolbar>
  );
};

export default CustomToolbar;