const superAdmin = {
  clients: {
    create: true,
    read: true,
    edit: true,
  },
  utilisateurs: {
    create: true,
    read: true,
    edit: true,
  },
  restaurants: {
    create: true,
    read: true,
    edit: {
      save: true,
      resetPassword: true,
      sendIdentifiers: true,
      disableAccount: true,
    },
  },
  'transactions/all': {
    read: true
  },
  'statistiques/scores': {
    read: true
  }
};

const admin = {
  clients: {
    create: true,
    read: true,
    edit: true,
  },
  restaurants: {
    create: true,
    read: true,
    edit: {
      save: true,
      resetPassword: true,
      sendIdentifiers: true,
      disableAccount: true,
    },
  }
};

const resp = {
  restaurants: {
    create: true,
    read: true,
    edit: {
      save: false,
      resetPassword: false,
      sendIdentifiers: false,
      disableAccount: false,
    },
  },
};

const tech = {
  restaurants: {
    read: true,
    edit: {
      resetPassword: false,
      sendIdentifiers: false,
    },
  }
};

const user = () => {
  const hasPayxpert = localStorage.getItem('hasPayxpert') === 'true';
  const hasPayplug = localStorage.getItem('hasPayplug') === 'true';
  const hasPaygreen = localStorage.getItem('hasPaygreen') === 'true';
  const hasEdenred = localStorage.getItem('hasEdenred') === 'true';

  return {
    'settings/account': {
      edit: true,
    },
    'transactions/liste/': {
      read: true,
    },
    'qr-codes': {
      create: true,
      read: true,
      edit: true,
      delete: true
    },
    cartes: {
      create: true,
      read: true,
      edit: true,
      delete: true
    },
    'statistiques/scores': {
      read: true
    }
  }
};

// Changement contante roles en fonction car n'est appellé qu'une seule dès le début sinon.
// les permissions de user dépendent du localstorage qui doit etre rappellé
const getPermissionsByRole = () => ({
  superAdmin,
  admin,
  resp,
  tech,
  user: user(),
});

const Roles = (role, country) => {
  const isSuperAdmin = (localStorage.getItem('isAdmin') === "true");
  if (!role) return {
    name: 'user',
    country,
    resources: getPermissionsByRole()['user'],
  };

  if(isSuperAdmin) return {
      name: 'admin',
      country,
      resources: getPermissionsByRole()['superAdmin'],
    }

  return {
    name: role,
    country,
    resources: getPermissionsByRole()[role],
  };
};

export default Roles;
