import * as React from "react";
import { SimpleShowLayout, TextField } from 'react-admin';
import ShowComponent from "../core/ShowComponent";


const AdminUsersShow = ({ permissions, ...props }) => {
  
  return (
    <ShowComponent
      title="Afficher utilisateur"
      breadcrumb="Afficher utilisateur"
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="adresse_mail" />
        <TextField source="nom" />
        <TextField source="prenom" />
        <TextField source="role" />
      </SimpleShowLayout>
    </ShowComponent>
  );
};

export default AdminUsersShow;