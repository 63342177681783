import UtilisateursIcon from '@mui/icons-material/Person';
import UtilisateursList from './List';
import UtilisateursCreate from './Create';
import UtilisateursEdit from './Edit';
import UtilisateursShow from './Show';

const Utilisateurs = {
  icon: UtilisateursIcon,
  name: 'utilisateurs',
  list: UtilisateursList,
  create: UtilisateursCreate,
  edit: UtilisateursEdit,
  show: UtilisateursShow
  // options: { label: 'Utilisateurs', bg: '#f2c002' },
};

export default Utilisateurs;