import { Typography, Modal, Button, Box } from '@mui/material';
import { useNotify, useTranslate, useCreate, useResourceContext } from 'react-admin';
import { modalBodyStyle, smallModalStyle } from '../../styles/Styles';
import loaderImage from '../../assets/images/loading.gif';
import { useState, useEffect } from "react";

const ModalConfirmationSendIdentifiers = ({isOpen, onClose, id, adresse_mail}) => {
    const resource = useResourceContext();
    const notify = useNotify();
    const translate = useTranslate();
    const [showLoader, setShowLoader] = useState(false);
    const [sendIdentifiers, { isLoading: isLoadingSendIdentifiers, error: errorSendIdentifiers, data: dataSendIdentifiers }] = useCreate();

    useEffect(() => {
        if(!isLoadingSendIdentifiers && !errorSendIdentifiers && dataSendIdentifiers)
        {   
            notify(`${translate('RESTAURANTS.EMAIL_SENT_TO')} ${dataSendIdentifiers.id}.`, { type: 'success' });
            onClose();
            setShowLoader(false);
        } 
    }, [isLoadingSendIdentifiers]);

    const handleSendIdentifiers = () => {
        setShowLoader(true);
        sendIdentifiers(`${resource}/send-identifiers`, { data: { id, adresse_mail } });
    }

    return <Modal
            open={isOpen}
            onClose={onClose}
        >
            <Box sx={smallModalStyle}>
            <Box sx={modalBodyStyle}>
                <Typography variant="h6">{translate('RESTAURANTS.DO_SEND_ID')}</Typography>
                {showLoader && <Box component="img" alt="Loader remboursement" src={loaderImage} />}
                {!showLoader && <Box>
                    <Button size="small" color="success" variant="contained" sx={{m:1}} onClick={handleSendIdentifiers}>{translate('COMMON.YES')}</Button>
                    <Button size="small" color="error" variant="contained" sx={{ m: 1 }} onClick={onClose}>{translate('COMMON.NO')}</Button>
                </Box>}
            </Box>
            
            </Box>
        </Modal>
}

export default ModalConfirmationSendIdentifiers;