import * as React from "react";
import { useState, useEffect } from 'react';
import {
  Toolbar,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  DateInput,
  useNotify,
  useCreate,
  useRecordContext,
} from 'react-admin';
import { SaveOutlined, Check, Lock, ToggleOff, RestartAlt } from '@mui/icons-material';
import { Box, Typography, Stack, Divider, Chip, Grid } from '@mui/material';
import { adminForm, colors, btnStyles, disabledBtnStyles } from "../../styles/Styles";
import { editSchema } from "../../utils/Validations/schemas/restaurantSchema";
import errorSchemas from "../../utils/Validations/handleErrorSchemas/errorSchemas";
import { _ID_AGENCE_ES, _ID_CAISSE_ES, } from '../../constantes';
import ModalConfirmationEnableDisableAccount from "../core/ModalConfirmationEnableDisableAccount";
import ModalConfirmationSendIdentifiers from "../core/ModalConfirmationSendIdentifiers";
import { formatDate } from "../../utils/utils";


const NonInput = React.memo(function NonInput({ children }) {
  return children;
});


const RestaurantEditContent = ({ translate, agences, caisses, restaurateurs, permissions, country, role }) => {
  const record = useRecordContext();
  const [disablePayxpert, setDisablePayxpert] = useState(false); // disabled pxp if payplug
  const [disablePayplug, setDisablePayplug] = useState(false); // disabled payplug if pxp
  const [openModalConfirmAccount, setOpenModalConfirmAccount] = useState(false);
  const [openModalConfirmIdentifiers, setOpenModalConfirmIdentifiers] = useState(false);
  const [operationAccount, setOperationAccount] = useState('enable');
  const [hideMdp, setHideMdp] = useState(false);
  const [restaurants, setRestaurants] = useState(false);
  const [showPayxpert, setShowPayxpert] = useState(true);
  const [showPaygreen, setShowPaygreen] = useState(false);
  const [requestPaygreen, setRequestPaygreen] = useState(false);
  const [showPayplug, setShowPayplug] = useState(true);
  const [showEdenred, setShowEdenred] = useState(false);
  const isNotAdmin = (role === 'resp' || role === 'tech');
  const _IS_ADMIN_ES = country === 'ES';

  useEffect(() => {
    if (record?.compte_actif) setOperationAccount('disable');
    setShowPayxpert(record?.identifiant_payxpert?.actif);
    setShowPaygreen(record?.identifiant_paygreen?.actif && record?.metas?.use_paygreen);
    setRequestPaygreen(record?.metas?.use_paygreen);
    setShowPayplug(record?.identifiant_payplug?.actif);
    setShowEdenred(record?.identifiant_edenred?.actif || false);
    setDisablePayxpert(record?.identifiant_payplug?.actif);
    setDisablePayplug(record?.identifiant_payxpert?.actif);
  }, [record]);


  const handleToggleRequestPaygreen = (e) => {
    const isChecked = e.target.checked;
    setRequestPaygreen(isChecked);
    setShowPaygreen(isChecked);
  };

  const handleToggleShowPaygreen = (e) => {
    const isChecked = e.target.checked;
    setShowPaygreen(isChecked);
    if (!record?.metas?.use_paygreen) setRequestPaygreen(isChecked);
    if (record?.metas?.use_paygreen && !requestPaygreen) setRequestPaygreen(isChecked);
  };

  // Payxpert validator
  const togglePayxpert = (e) => {
    setShowPayxpert(e.target.checked === true);
    (e.target.checked === true) ? setDisablePayplug(true) : setDisablePayplug(false);
  }

  // Payplug validator
  const togglePayplug = (e) => {
    setShowPayplug(e.target.checked === true);
    (e.target.checked === true) ? setDisablePayxpert(true) : setDisablePayxpert(false);
  }

  // Edenred validator
  const toggleEdenred = (e) => {
    setShowEdenred(e.target.checked === true);
  }

  /**
   * Global validator
   * 
   * @param {Object} values : champs du formulaire
   * @returns tableau d'erreurs
   */
  const handleValidate = (values) => {
    const errors = errorSchemas(editSchema, values);
    const errorCredsPayxpert = validateCredsPayxpert(values);
    const errorCredsPaygreen = validateCredsPaygreen(values);

    return { ...errors, ...errorCredsPayxpert, ...errorCredsPaygreen };
  };

  const validateCredsPayxpert = (values) => {
    const errors = {};
    if (values.identifiant_payxpert?.id && !values.identifiant_payxpert?.cle_api) errors['identifiant_payxpert.cle_api'] = translate('VALIDATION.MANDATORY.API_KEY');
    if (!values.identifiant_payxpert?.id && values.identifiant_payxpert?.cle_api) errors['identifiant_payxpert.id'] = translate('VALIDATION.MANDATORY.PAYXPERT_ID');

    return errors;
  };

  const validateCredsPaygreen = (values) => {
    const errors = {};
    if ((values.identifiant_paygreen?.secret_key || values.identifiant_paygreen?.public_key) && !values.identifiant_paygreen?.shop_id) errors['identifiant_paygreen.shop_id'] = translate('VALIDATION.MANDATORY.SHOP_ID');
    if ((values.identifiant_paygreen?.shop_id || values.identifiant_paygreen?.public_key) && !values.identifiant_paygreen?.secret_key) errors['identifiant_paygreen.secret_key'] = translate('VALIDATION.MANDATORY.SECRET_KEY');
    if ((values.identifiant_paygreen?.shop_id || values.identifiant_paygreen?.secret_key) && !values.identifiant_paygreen?.public_key) errors['identifiant_paygreen.public_key'] = translate('VALIDATION.MANDATORY.PUBLIC_KEY');

    return errors;
  };


  const handleOpenModalConfirmAccount = () => {
    setOpenModalConfirmAccount(true);
  };

  const handleCloseModalConfirmAccount = () => {
    setOpenModalConfirmAccount(false);
  };

  const handleOpenModalConfirmIdentifiers = () => {
    setOpenModalConfirmIdentifiers(true);
  };

  const handleCloseModalConfirmIdentifiers = () => {
    setOpenModalConfirmIdentifiers(false);
  };

  const isDisabledTogglePaygreen = !requestPaygreen && !showPaygreen && record?.metas?.use_paygreen;


  return (
    <>
      <ModalConfirmationEnableDisableAccount isOpen={openModalConfirmAccount} onClose={handleCloseModalConfirmAccount} nom={record?.nom} operation={operationAccount} id={record?.id} />
      <ModalConfirmationSendIdentifiers isOpen={openModalConfirmIdentifiers} onClose={handleCloseModalConfirmIdentifiers} id={record?.id} adresse_mail={record?.adresse_mail_utilisateur} />
      <SimpleForm
        mode="onChange"
        toolbar={<CustomToolbar
          translate={translate}
          record={record}
          handleOpenModalConfirmAccount={handleOpenModalConfirmAccount}
          handleOpenModalConfirmIdentifiers={handleOpenModalConfirmIdentifiers}
          permissions={permissions.edit}
          isNotAdmin={isNotAdmin}
        />}
        validate={handleValidate}
      >
        <NonInput>
          <Typography fontSize={24} fontWeight="bold" mb={4}>{record.nom}</Typography>
          <Divider sx={{ ...adminForm.divider, ...adminForm.divider.first, width: '100%' }} textAlign="left" variant="fullWidth">
            <Chip size="small" sx={adminForm.chip} color="primary" label={translate('RESTAURANTS.INFOS')} />
          </Divider>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6} lg={5}>
              <EmailField
                t={translate}
                restaurants={restaurants}
                hideMdp={hideMdp}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <IdJwtCaisseField label={translate('COMMON.TILL_ID')} />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <AgenceForm
                label={translate('COMMON.AGENCY')}
                choices={agences}
                disabled={_IS_ADMIN_ES || !permissions.edit.save || isNotAdmin}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <CodeClient
                label={translate('COMMON.CLIENT_CODE')}
                disabled={!permissions.edit.save || isNotAdmin}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <CaisseForm
                show={!_IS_ADMIN_ES}
                label={translate('COMMON.TILL_NAME')}
                choices={caisses}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <CreatedAtField label={translate('COMMON.DATE_CREATED')} />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <CreatorField label={translate('COMMON.RESTAURANT_CREATOR')} />
            </Grid>
          </Grid>

          <Divider sx={{ ...adminForm.divider, ...adminForm.divider.first, width: '100%' }} textAlign="left" variant="fullWidth">
            <Chip size="small" sx={adminForm.chip} color="primary" label="Options" />
          </Divider>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={6} lg={5}>
              <TipsForm
                show={!_IS_ADMIN_ES}
                label={translate('COMMON.TIPS')}
                disabled={!permissions.edit.save || isNotAdmin}
              />
            </Grid>
            <Grid item xs={6} lg={5}>
              <NpScoreField
                label={translate('COMMON.NP_SCORE')}
                disabled={!permissions.edit.save || isNotAdmin}
              />
            </Grid>
            <Grid item xs={12}>
              <TicketRestaurantForm
                t={translate}
                show={!_IS_ADMIN_ES}
                title={translate('COMMON.CLIENT_WISH_USE_PAYGREEN')}
                checked={requestPaygreen}
                onChange={handleToggleRequestPaygreen}
                disabled={isNotAdmin}
              />
            </Grid>
          </Grid>

          <Divider sx={{ ...adminForm.divider, width: '100%' }} textAlign="left" variant="fullWidth">
            <Chip size="small" sx={adminForm.chip} color="primary" label={translate('COMMON.PAYMENTS_ID')} />
          </Divider>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6} lg={5}>
              <PayxpertForm
                t={translate}
                checked={showPayxpert}
                nbReminds={record.identifiant_payxpert?.nbReminds}
                requestDate={record.identifiant_payxpert?.requestDate}
                onChange={togglePayxpert}
                disabledToggle={disablePayxpert || (!permissions.edit.save) || isNotAdmin}
                isNotAdmin={isNotAdmin}
                record={record}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <PaygreenForm
                t={translate}
                show={!_IS_ADMIN_ES}
                title={translate('COMMON.PAYGREEN_ID')}
                identifiant_paygreen={record.identifiant_paygreen}
                checked={showPaygreen}
                onChange={handleToggleShowPaygreen}
                disabled={!permissions.edit.save || isDisabledTogglePaygreen || isNotAdmin}
                isNotAdmin={isNotAdmin}
                record={record}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6} lg={5}>
              <PayplugForm
                t={translate}
                checked={showPayplug}
                nbReminds={record.identifiant_payplug?.nbReminds}
                requestDate={record.identifiant_payplug?.requestDate}
                onChange={togglePayplug}
                disabledToggle={disablePayplug || (!permissions.edit.save) || isNotAdmin}
                isNotAdmin={isNotAdmin}
                record={record}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <EdenredForm
                t={translate}
                checked={showEdenred}
                nbReminds={record.identifiant_edenred?.nbReminds}
                requestDate={record.identifiant_edenred?.requestDate}
                onChange={toggleEdenred}
                disabledToggle={(!permissions.edit.save) || isNotAdmin}
                isNotAdmin={isNotAdmin}
                record={record}
              />
            </Grid>
          </Grid>

          <Divider sx={{ ...adminForm.divider, width: '100%' }} textAlign="left" variant="fullWidth">
            <Chip size="small" sx={adminForm.chip} color="primary" label={translate('COMMON.FURTHER_INFORMATIONS')} />
          </Divider>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} lg={10}>
              <InfosField label={translate('COMMON.COMMENT')} disabled={isNotAdmin} />
            </Grid>
          </Grid>


        </NonInput>
      </SimpleForm>
    </>
  );
};


//#region Toolbar

const CustomToolbar = (props) => {
  const resetPassword = ResetPasswordButton(props.record?.adresse_mail_utilisateur, props.record?.compte_actif, props.translate);
  const enableDisableAccount = EnableDisableAccountButton(props.record?.compte_actif, props.handleOpenModalConfirmAccount, props.translate);
  const sendIdentifiers = SendIdentifiers(props.record?.compte_actif, props.handleOpenModalConfirmIdentifiers, props.translate)
  const saveButton = <SaveButton
    label={props.translate('ACTIONS.SAVE')}
    sx={(props.record?.compte_actif) ? btnStyles.success : disabledBtnStyles.success}
    icon={<SaveOutlined />}
    disabled={!props.record?.compte_actif}
  />;

  return (
    <Toolbar {...props}>
      {props.permissions.save && saveButton}
      {props.permissions.resetPassword && resetPassword}
      {props.permissions.sendIdentifiers && sendIdentifiers}
      {props.permissions.disableAccount && enableDisableAccount}
    </Toolbar>
  )
};

const ResetPasswordButton = (email, flagCompteActif, translate) => {
  const notify = useNotify();
  const [resetPassword, { isLoading, error, data }] = useCreate();

  useEffect(() => {
    if (!isLoading && !error && data)
      notify(`${translate('RESTAURANTS.EMAIL_SENT_TO')} ${email}.`, { type: 'success' });
  }, [isLoading])

  const handleResetPassword = () => {
    resetPassword(`authentication/forgot-password`, { data: { email, role: 'user' } });
  }

  return (
    <Button label={translate('COMMON.PASSWORD_RENEW')} sx={(flagCompteActif) ? btnStyles.primary : disabledBtnStyles.primary} onClick={handleResetPassword} disabled={!flagCompteActif}>
      <Lock />
    </Button>
  );
};

const EnableDisableAccountButton = (compte_actif, onClick, translate) => {
  return (
    <Button label={translate((compte_actif) ? 'COMMON.ACCOUNT_DESACTIVATE' : 'COMMON.ACCOUNT_ACTIVATE')} sx={btnStyles.danger} onClick={onClick}>
      <ToggleOff />
    </Button>
  );
};

const SendIdentifiers = (flagCompteActif, onClick, translate) => {
  return (
    <Button label={translate('RESTAURANTS.SEND_IDS')} sx={(flagCompteActif) ? btnStyles.warning : disabledBtnStyles.warning} onClick={onClick} disabled={!flagCompteActif}>
      <RestartAlt />
    </Button>
  );
};

//#endregion Toolbar

//#region Form

//#region Infos Restaurants

const IdJwtCaisseField = ({ label }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
      <TextInput fullWidth source="jpay_api_jwt_id" label={label} disabled={true} />
    </Stack>
  );
};

const EmailField = ({ t, restaurants, hideMdp }) => {
  return (
    <Stack direction="column" sx={{ width: '100%' }} alignItems="baseline">
      <Stack direction="row" sx={{ width: '100%' }} alignItems="center">
        <TextInput fullWidth label={t('COMMON.EMAIL_ADDRESS')} source="adresse_mail_utilisateur" disabled={true} />
        {(hideMdp) && <Check sx={{ color: colors.success }} />}
      </Stack>
      <AssociatedRestaurants restaurants={restaurants} />
    </Stack>
  )
};

const AssociatedRestaurants = ({ restaurants }) => {
  if (!restaurants) return null;

  return <Box>
    <Typography>Restaurants déjà associés à cet email : </Typography>
    <Box marginLeft={10}>
      {restaurants.map(r => {
        return <p key={r.nom} fontSize={15}>{r.nom}</p>;
      })}
    </Box>
  </Box>
};

const AgenceForm = ({ label, choices, disabled }) => {
  return (
    <SelectInput
      fullWidth
      label={label}
      source="id_agence"
      optionText="nom"
      disabled={disabled}
      choices={choices}
    />
  );
};

const CodeClient = ({ label, disabled }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
      <TextInput fullWidth source="code_client" label={label} disabled={disabled} />
    </Stack>
  );
};

const CaisseForm = ({ show, label, choices }) => {
  if (!show) return null;

  return (
    <SelectInput fullWidth label={label} source="id_caisse" optionText="nom" choices={choices} disabled={true} />
  );
};

//#endregion Infos Restaurants

//#region Options

const TipsForm = ({ show, label, disabled }) => {
  if (!show) return null;

  return (
    <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
      <BooleanInput label="" source="pourboire_active" disabled={disabled} />
      <Typography>{label}</Typography>
    </Stack>
  );
};

const NpScoreField = ({ label, disabled }) => {
  return (
    <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
      <BooleanInput label="" source="nps_score_actif" disabled={disabled} />
      <Typography>{label}</Typography>
    </Stack>
  );
};

const TicketRestaurantForm = ({ show, t, title, checked, onChange, disabled }) => {
  if (!show) return null;

  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source="metas.use_paygreen" onChange={onChange} checked={checked} options={{ checked }} disabled={disabled} />
        <Typography>{title}</Typography>
      </Stack>
      <Grid container display={(checked) ? 'flex' : 'none'} spacing={{ xs: 2, md: 4 }}>
        <Grid item xs={12} md={6} lg={5}>
          <TextInput fullWidth label={t('COMMON.SIGNATORY_FULLNAME')} source="metas.client_name" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <TextInput fullWidth label={t('COMMON.ETABLISHMENT_BUSINESS_NAME')} source="metas.raison_social" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <TextInput fullWidth label={t('COMMON.SIGNATORY_EMAIL')} source="metas.signataire_email" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextInput fullWidth label={t('COMMON.SIGNATORY_MOBILE_PHONE')} source="metas.signataire_phone" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextInput fullWidth label={t('COMMON.SIRET_NUM')} source="metas.numero_siret" disabled={disabled} />
        </Grid>
      </Grid>
    </>
  );
};

//#endregion Options

//#region Ids paiements

const PayxpertForm = ({ t, checked, onChange, disabledToggle, nbReminds, requestDate, isNotAdmin, record }) => {
  const id_payxpert = (isNotAdmin) ? (record.identifiant_payxpert?.id) ? record.identifiant_payxpert.id.replace(/./g, '*') : "" : record.identifiant_payxpert?.id;
  const cle_api_payxpert = (isNotAdmin) ? (record.identifiant_payxpert?.cle_api) ? record.identifiant_payxpert.cle_api.replace(/./g, '*') : "" : record.identifiant_payxpert?.cle_api;
  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source="identifiant_payxpert.actif" fullWidth onChange={onChange} disabled={disabledToggle} defaultValue={true} />
        <Typography>{t('COMMON.PAYXPERT_ID')}</Typography>
      </Stack>
      <Grid container display={(checked) ? 'block' : 'none'}>
        <Grid item xs={12}>
          <RemindDate requestDate={requestDate} nbReminds={nbReminds} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label="Id" source={isNotAdmin ? "" : "identifiant_payxpert.id"} defaultValue={id_payxpert} disabled={disabledToggle} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.KEY_API')} source={isNotAdmin ? "" : "identifiant_payxpert.cle_api"} defaultValue={cle_api_payxpert} disabled={disabledToggle} />
        </Grid>
      </Grid>
    </>
  );
};

const PaygreenForm = ({ t, show, title, checked, onChange, disabled, identifiant_paygreen, isNotAdmin, record}) => {
  if (!show) return null;

  const shop_id_paygreen = (isNotAdmin) ? (record.identifiant_paygreen?.shop_id) ? record.identifiant_paygreen.shop_id.replace(/./g, '*') : "" : record.identifiant_paygreen?.shop_id;
  const secret_key_paygreen = (isNotAdmin) ? (record.identifiant_paygreen?.secret_key) ?  record.identifiant_paygreen.secret_key.replace(/./g, '*') : "" : record.identifiant_paygreen?.secret_key;
  const public_key_paygreen = (isNotAdmin) ? (record.identifiant_paygreen?.public_key) ? record.identifiant_paygreen.public_key.replace(/./g, '*') : "" : record.identifiant_paygreen?.public_key;

  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source="identifiant_paygreen.actif" fullWidth onChange={onChange} disabled={disabled} checked={checked} options={{ checked }} />
        <Typography>{title}</Typography>
      </Stack>
      <Grid container display={(checked) ? 'block' : 'none'}>
        <Grid item xs={12}>
          <OnBoardingDate onboardingDate={identifiant_paygreen?.onboardingDate}  />
          <RemindDate requestDate={identifiant_paygreen?.requestDate} nbReminds={identifiant_paygreen?.nbReminds} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.SHOP_ID')} source={isNotAdmin ? "" : "identifiant_paygreen.shop_id"} defaultValue={shop_id_paygreen}  disabled={disabled} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.KEY_SECRET')} source={isNotAdmin ? "" : "identifiant_paygreen.secret_key"} defaultValue={secret_key_paygreen} disabled={disabled} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.KEY_PUBLIC')} source={isNotAdmin ? "" : "identifiant_paygreen.public_key"} defaultValue={public_key_paygreen} disabled={disabled} />
        </Grid>
      </Grid>
    </>
  );
};

const PayplugForm = ({ t, checked, onChange, disabledToggle, isNotAdmin, record, nbReminds, requestDate}) => {
  const secret_key_payplug = (isNotAdmin) 
                              ? (record.identifiant_payplug?.id) 
                                ?  record.identifiant_payplug.id.replace(/./g, '*') 
                                : "" 
                              : record.identifiant_payplug?.id;

  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source="identifiant_payplug.actif" fullWidth onChange={onChange} disabled={disabledToggle} checked={checked} options={{ checked }} />
        <Typography>{t('COMMON.PAYPLUG_ID')}</Typography>
      </Stack>
      <Grid container display={(checked) ? 'block' : 'none'}>
        <Grid item xs={12}>
          <RemindDate requestDate={requestDate} nbReminds={nbReminds} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.KEY_SECRET')} source={isNotAdmin ? "" : "identifiant_payplug.id"} defaultValue={secret_key_payplug} disabled={disabledToggle} />
        </Grid>
      </Grid>
    </>
  );
}

const EdenredForm = ({ t, checked, onChange, disabledToggle, isNotAdmin, record, nbReminds, requestDate}) => {
  const mid_edenred = (isNotAdmin) 
                      ? (record.identifiant_edenred?.mid) 
                          ?  record.identifiant_edenred.mid.replace(/./g, '*') 
                          : "" 
                      : record.identifiant_edenred?.mid;

  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source="identifiant_edenred.actif" fullWidth onChange={onChange} disabled={disabledToggle} checked={checked} options={{ checked }} />
        <Typography>{t('COMMON.EDENRED_ID')}</Typography>
      </Stack>
      <Grid container display={(checked) ? 'block' : 'none'}>
        <Grid item xs={12}>
          <RemindDate requestDate={requestDate} nbReminds={nbReminds} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={'mid'} source={isNotAdmin ? "" : "identifiant_edenred.mid"} defaultValue={mid_edenred} disabled={disabledToggle} />
        </Grid>
      </Grid>
    </>
  );
}

//#endregion Ids paiements

//#region Comments

const InfosField = ({ label, disabled }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
      <TextInput
        label={label}
        source="metas.comment"
        size="large"
        multiline={true}
        fullWidth={true}
        disabled={disabled}
      />
    </Stack>
  );
};

//#endregion Comments

//#region CreatedAt

const CreatedAtField = ({ label }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
      <DateInput
        label={label}
        source="created"
        fullWidth={true}
        disabled={true}
      />
    </Stack>
  )
};

//#endregion CreatedAt

//#region Creator

const CreatorField = ({ label }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
      <TextInput fullWidth source="namecreator" label={label} disabled={true} />
    </Stack>
  );
};

//#endregion Creator

//#endregion Form

const RemindDate = ({ requestDate, nbReminds }) => {
  if (!nbReminds) return null;
  const date = formatDate(requestDate);

  return <Typography lineHeight="2.4em">Date relance ({nbReminds}): {date}</Typography>;
};

const OnBoardingDate = ({ onboardingDate }) => {
  if (!onboardingDate) return null;

  const date = formatDate(onboardingDate);

  return <Typography lineHeight="2.4em">Date Demande Onboarding: {date}</Typography>;
};


export default RestaurantEditContent;