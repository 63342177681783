import * as React from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import { btnStyles } from '../../../styles/Styles';

const ExportButton = ({ show, isDisabled, handleSubmit }) => {
  const translate = useTranslate();

  if (!show) return null;

  return (
    <Button variant="outlined" sx={{ ...btnStyles.warning, marginTop: ' .5em' }} onClick={handleSubmit} disabled={isDisabled}>
      {translate('DASHBOARD.TIPS.CSV_EXPORT_BTN')}
    </Button>
  );
};

export default ExportButton;
