import RestaurantIcon from '@mui/icons-material/RestaurantMenu';
import RestaurantList from './List';
import RestaurantCreate from './Create';
import RestaurantEdit from './Edit';
import RestaurantShow from './Show';

const Restaurants = {
  icon: RestaurantIcon,
  name: 'restaurants',
  list: RestaurantList,
  create: RestaurantCreate,
  edit: RestaurantEdit,
  show: RestaurantShow
  // options: { label: 'Restaurants', bg: '#f2c002'}
};

export default Restaurants;