import * as React from "react";
import { useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import { btnStyles } from '../../../styles/Styles';

const TodayDateButton = ({ hide = false, handleSubmit }) => {
  const translate = useTranslate();

  if (hide) return null;

  const handleToDayFilter = () => handleSubmit(new Date());

  return (
    <Button variant="outlined" sx={{ ...btnStyles.secondary, marginTop: ' .5em' }} onClick={handleToDayFilter}>
      {translate('COMMON.TODAY')}
    </Button>
  );
};

export default TodayDateButton;