import { Typography, Modal, Box } from '@mui/material';
import { useLocaleState } from 'react-admin';
import { modalBodyStyle, smallModalStyle } from "../../styles/Styles";

const TemplateModal = ({ open, onClose, template, t }) => {
  const [locale] = useLocaleState();
  if (!template) return null;

  const margin = template.id === 'CARTE'
    ? { xs: "20% 25px 0 25px", sm: "12% 30% 0 30%" }
    : '5% 36% 0 36%';

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...smallModalStyle, margin }}>
        <Box sx={modalBodyStyle}>
          <Typography variant="h6" fontWeight={'bold'} textAlign='center'>{template.name}</Typography>
          <Box marginTop={'20px'}><img src={`/qrcode/${template.preview[locale.toLowerCase()]}`} /></Box>
          <Typography marginBottom={'30px'} variant="body2" color={'#4E4E4E'}>{t('COMMON.PREVIEW_OF')} {template.name.toLowerCase()}</Typography>
          <Typography variant="body">{template.description}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};


export default TemplateModal;