import Joi from 'joi';

export const joiCartesSchema = Joi.object().keys({
    nom: Joi.string().required().messages({
        'any.required': `Le nom est obligatoire.`,
        'string.base': `Le nom est obligatoire.`
    }),
    // inputFile: Joi.required().messages({
    //     'any.required': `Le pdf est obligatoire.`
    // }),
    inputFile: Joi.when('url', {
        is: Joi.any().valid(null, ""), 
        then: Joi.required().messages({
            'any.required': `Le pdf est obligatoire.`
        })
    })
  }).unknown();
