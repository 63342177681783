import * as React from 'react';
import {
  SimpleForm,
  useTranslate,
  useCreate,
  useNotify,
  useUpdate,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import EditComponent from '../../../core/EditComponent';
import RestrictionsForm from './Form';
import CustomToolbar from './Toolbar';
import { accountSchema } from '../../../../utils/Validations/schemas/accountSchema';
import errorSchemas from '../../../../utils/Validations/handleErrorSchemas/errorSchemas';
import RestrictionModal from '../../restrictionModal';


const Restrictions = (props) => {
  const isLoggedInByRoleTech = (localStorage.getItem('loggedInByRole') === 'tech');
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [record, setRecord] = useState();
  const [openModalRestrictions, setOpenModalRestrictions] = useState(false);
  const [requestRecoveryPin, { data: recoveryPin }] = useCreate();
  const [update, { isLoading: isSubmitting }] = useUpdate();
  const idRestaurant = localStorage.getItem('idRestaurant');

  
  useEffect(() => {
    if (recoveryPin) notify(`${t('RESTAURANTS.EMAIL_SENT_TO')} ${recoveryPin.email}.`, { type: 'success' });
  }, [recoveryPin]);


  const handleValidate = (values) => {
    if (!values?.actif) {
      setRecord({ id: values?.id, actif: false });
      return false;
    }

    setRecord(values);
    const errors = errorSchemas(accountSchema, values);
    if (values?.pin !== values?.pinConfirm) errors['pinConfirm'] = t('VALIDATION.TYPE.RESTRICTION_CODE_CONFIRM');

    return errors;
  };

  const handleReinitCode = () => {
    requestRecoveryPin('settings/account', { data: { id: idRestaurant } });
  };

  const handleSubmit = (e) => {
    if (!record?.actif) {
      setOpenModalRestrictions(true);
      e.preventDefault();
    }
  };

  const handleCloseModalrestrictions = () => {
    setOpenModalRestrictions(false);
  };

  const handleSubmitModalrestrictions = () => {
    setOpenModalRestrictions(false);
    update(
      'settings/account',
      { id: record?.id, data: record },
      {
        onSuccess: () => {
          refresh();
          redirect('edit', '/settings/account', record?.id);
        }
      });
  };


  return (
    <>
      <EditComponent
        mutationMode="optimistic"
        redirect="" // redirect vers nul part sinon, après un edit on est redirect vers la liste, or il n'y en a pas
        transform={() => record} // si actif === false, on enlève la valeur du champs pin
        heightAuto={true}
        noMarginBottom="true"
      >
        <SimpleForm
          mode="onChange"
          validate={handleValidate}
          toolbar={<CustomToolbar t={t} showReinitButton={record?.actif && record?.pin} handleReinitCode={handleReinitCode} handleSubmit={handleSubmit} isLoggedInByRoleTech={isLoggedInByRoleTech} />}
        >
          <Typography variant="h5" gutterBottom>{props.title}</Typography>
          <RestrictionsForm isLoggedInByRoleTech={isLoggedInByRoleTech} />
        </SimpleForm>
      </EditComponent>
      <RestrictionModal title={t('MODAL_RESTRICTION.RESTRICT_TITLE')} open={openModalRestrictions} onClose={handleCloseModalrestrictions} handleSubmit={handleSubmitModalrestrictions} />
    </>
  );
};

export default Restrictions;