import React from 'react';
import Chart from 'react-apexcharts';
import { dashboardStyles } from '../../styles/Styles';
import {useTranslate} from 'react-admin';
import { formatNumber } from '../../utils/utils';

const formatDataForMoyensPaiementGraph = (data, translate) => {
  const labels = [];
  const montantsCB = [];
  const montantsOthers = [];
  const montants = [];
  const CB = translate('COMMON.BANK_CARD');
  const TP = translate('COMMON.RESTAURANT_TICKET')
  if(data) {
      data.forEach( d => {
          if(d.type_carte === 0)
          {
            if(labels.indexOf(CB) < 0 ) labels.push(CB)
            montantsCB.push(d.montant)
          }
          else 
          {
            if(labels.indexOf(TP) < 0 ) labels.push(TP)
            montantsOthers.push(d.montant);
          }
      });
      const sumCB = montantsCB.reduce((sum, x) => sum + x, 0);
      const sumOthers = montantsOthers.reduce((sum, x) => sum + x, 0);
      if(sumCB > 0) montants.push(sumCB)
      if(sumOthers > 0) montants.push(sumOthers)
      return {
          labels: labels,
          series: {
              montants
          }
      }
  }
  else return null;
}

const formatDataForClientStatusGraph = (data) => {
  const labels = [];
  const montants = [];

  if (data) {
    Object.keys(data).forEach(k => {
      labels.push(k);
      montants.push(data[k]);
    });
    return {
      labels,
      series: { montants }
    };
  }
  else return null;
};

const Donut = (props) => {
  const translate = useTranslate()
  const formatedData = (props.isAdmin) ? formatDataForClientStatusGraph(props.data) : formatDataForMoyensPaiementGraph(props.data, translate);
  const options = {
    noData: {
      text: translate('COMMON.NO_DATA_ON_RANGE'),
      align: 'center',
      verticalAlign: 'top',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    labels: formatedData.labels,
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: function (value) {
          return formatNumber(value);
        }
      }
    },
    dataLabels: {
      enabled: true
    },
    legend: {
      position: "bottom"
    },
  };
  return (
    <div className="donut">
      <Chart className="graphContent" options={options} series={formatedData.series.montants} type="donut" sx={dashboardStyles.statsGraph.donutPie} />
    </div>
  );
};

export default Donut;