import * as React from "react";
import { styled } from '@mui/material/styles';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box, 
  Stack,
} from '@mui/material';
import TableCellHeader from '../core/TableCellHeader';
import { iconStyles, listDetailsStyles } from '../../styles/Styles';
import { useTranslate } from 'react-admin';
import { formatNumber, formatDateWithSeconds } from "../../utils/utils";
import { InfoOutlined } from '@mui/icons-material';
import { muiIconStyles } from "../../styles/Styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const PayxpertDetails = props => {
  const translate = useTranslate();
  const transactions = (props.transactions) ? props.transactions.map((transaction) => renderRow(transaction)) : props.record.transactions.map((transaction) => renderRow(transaction));

  return (
    <Box sx={listDetailsStyles.box}>
      <Typography variant="body1" component="div" sx={listDetailsStyles.tableTitle}>
        {translate('COMMON.TRANSACTION_DETAILS')}
      </Typography>
      <TableContainer component={Paper} sx={listDetailsStyles.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead sx={listDetailsStyles.tableHead}>
            <TableRow>
              <TableCellHeader align="left" padding="16" sx={iconStyles.primary} text={translate('COMMON.DATE')} />
              <TableCellHeader align="left" padding="16" sx={iconStyles.secondary} text={translate('COMMON.TRANSACTION_ID')} />
              <TableCellHeader align="left" padding="16" sx={iconStyles.warning} text={translate('COMMON.COMMAND_ID')} />
              <TableCellHeader align="right" padding="16" sx={iconStyles.danger} text={translate('COMMON.AMOUNT')} />
              <TableCellHeader align="left" padding="16" sx={iconStyles.success} text={translate('COMMON.OPERATION')} />
              <TableCellHeader align="right" padding="16" sx={iconStyles.primary} text={translate('COMMON.CODE')} />
              <TableCellHeader align="left" padding="16" sx={iconStyles.primary} text={translate('COMMON.MESSAGE')} />
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const renderRow = (transaction) => {

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.secondary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      // boxShadow: theme.shadows[1],
      fontSize: '0.875rem',
      borderRadius: 5,
      borderColor: 'black'
    },
  }));

  const date = formatDateWithSeconds(transaction.date);
  const id_transaction = transaction.transactionID;
  const id_commande = transaction.orderId;
  const montant = formatNumber(transaction.amount);
  const operation = transaction.operation;
  const code = transaction.resultCode;
  const message = transaction.resultMessage;
  const messageDescription = (code) ?  `${code} : ${transaction.operationCodeDescription}` : transaction.operationCodeDescription;
  const showToolTip = (transaction.operationCodeDescription) ? true : false;
  return (
    <TableRow key={transaction.id} sx={listDetailsStyles.tableRow}>
      <TableCell align="left" sx={listDetailsStyles.tableCellContent}>{date}</TableCell>
      <TableCell align="left" sx={listDetailsStyles.tableCellContent}>{id_transaction}</TableCell>
      <TableCell align="left" sx={listDetailsStyles.tableCellContent}>{id_commande}</TableCell>
      <TableCell align="right" sx={listDetailsStyles.tableCellContent}>{montant}</TableCell>
      <TableCell align="left" sx={listDetailsStyles.tableCellContent}>{operation}</TableCell>
      <TableCell align="right" sx={listDetailsStyles.tableCellContent}>{code}</TableCell>
      <TableCell align="left" sx={listDetailsStyles.tableCellContent}>
        <Stack spacing={2} direction={'row'}>
          <Box>{message}</Box>
          <Box>{showToolTip && <LightTooltip title={messageDescription}><InfoOutlined sx={muiIconStyles.secondary} /></LightTooltip>}</Box>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default PayxpertDetails;