import React, { useState, useEffect } from 'react';
import ListTitle from '../core/ListTitle';
import GridItem from '../../styles/GridItem';
import { muiIconStyles } from '../../styles/Styles';
import { EuroOutlined, RoomService } from '@mui/icons-material';
import {
  Typography,
  Stack,
  Divider,
  Modal,
  Button,
  Box,
  TextField,
  Table, TableBody, TableCell, TableContainer, TableRow, 
  Paper,
  Grid,
} from '@mui/material';
import Breadcrumb from '../core/BreadCrumb';
import { CustomTableHead, getComparator, stableSort } from '../../utils/ListFilter';
import { dashboardStyles, btnStyles, linkStyles, smallModalStyle, modalBodyStyle, modalHeaderStyle } from '../../styles/Styles';
import { useTranslate, useGetList, useCreate, useNotify } from 'react-admin';
import { defaultWeekDateRangeFilter, formatNumber, formatDate } from '../../utils/utils';
import { CSVLink } from 'react-csv';
import DateRangeFilters from '../common/DateRangeFilters';


const TableauPourboire = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const idRestaurant = localStorage.getItem('idRestaurant');
  const { startFilter, endFilter } = defaultWeekDateRangeFilter();
  const emailRegex = new RegExp("[a-z0-9]+@[a-z]+\.[a-z]{2,3}");

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date_ticket');
  const [tips, setTips] = useState([]);
  const [startDate, setStartDate] = useState(localStorage.getItem('filterDateStart') || startFilter);
  const [endDate, setEndDate] = useState(localStorage.getItem('filterDateEnd')|| endFilter);
  const [openCsvModal, setOpenCsvModal] = useState(false);
  const [emailExport, setEmailExport] = useState('');
  const [emailExportError, setEmailExportError] = useState('');

  const [exportCSVTips, { isLoading: isLoadingExportCSVTips, error: errorExportCSVTips, data: dataExportCSVTips }] = useCreate();

  useEffect(() => {
    if(!isLoadingExportCSVTips && !errorExportCSVTips && dataExportCSVTips)
    {
      setOpenCsvModal(false);
      notify(`${translate('RESTAURANTS.EMAIL_SENT_TO')} ${dataExportCSVTips.email}`, { type: 'success' });
    }
  }, [isLoadingExportCSVTips])

  const filter = {
    start: startDate,
    end: endDate,
    noPagination: true
  }

  // const { data: dataTips, loaded: loadedTips } = _getTipsForPageDashboard(payload);
  const { data: dataTips, isLoading: isLoadingTips } = useGetList(
    `dashboard/${idRestaurant}/tips`,
    {
      filter
    }
  );

  useEffect(() => {
    if(!isLoadingTips)
      setTips(dataTips);
  }, [dataTips, isLoadingTips])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeStartDate = (newValue) => {
    setStartDate(new Date(newValue).toISOString().split('T')[0]);
  };
  const handleChangeEndDate = (newValue) => {
    setEndDate(new Date(newValue).toISOString().split('T')[0]);
  };

  const headCells = [
    {
      id: 'vendeur',
      numeric: false,
      disablePadding: false,
      label: translate('COMMON.SELLER'),
      icon: <RoomService sx={muiIconStyles.warning} />,
      align:'left'
    },
    {
      id: 'pourboire',
      numeric: true,
      disablePadding: false,
      label: translate('COMMON.AMOUNT'),
      icon: <EuroOutlined sx={muiIconStyles.secondary} />,
      align:'right'
    },
  ];

  const getDataTips = () => {;
    if(!dataTips) return [];
    let data = dataTips.map(t => {
      return {
        vendeur: t.vendeur,
        pourboire: formatNumber(t.pourboire, 2, '')
      }
    });

    const total = data.reduce((p, k) => p += (k.pourboire) ? Number(k.pourboire) : 0, 0);
    data.push({vendeur: translate('DASHBOARD.TIPS.TOTAL'), pourboire: formatNumber(total, 2, '')});
    
    const headers = [
      { label: translate('COMMON.SELLER'), key: "vendeur" },
      { label: translate('COMMON.AMOUNT'), key: "pourboire" }
    ];

    return { data, headers }
  }

  const isDataTips = () => {
    return dataTips && dataTips.length > 0
  }

  const toggleCSVExportModal = () => {
    setEmailExport('');
    setOpenCsvModal(!openCsvModal);
  }

  const handleChangeEmailExport = (event) => {
    setEmailExportError('');
    setEmailExport(event.target.value);
  }

  const handleSendCSVExportByMail = () => {
    if(emailExport === '') return  setEmailExportError(translate('VALIDATION.MANDATORY.EMAIL'));
    if(!emailRegex.test(emailExport)) return setEmailExportError(translate('VALIDATION.TYPE.EMAIL'));

    exportCSVTips(`dashboard/${idRestaurant}/tips/export`, { data: { email: emailExport, start: startDate, end: endDate, fileName: `${translate('DASHBOARD.TIPS.CSV_EXPORT_FILENAME', {start: startDate, end: endDate})}.csv` } });
  }

  const totalPourboires = tips.reduce((p, k) => p += (k.pourboire) ? Number(k.pourboire) : 0, 0);
  return <Box>
    <CSVExportModal isOpen={openCsvModal} onClose={toggleCSVExportModal} startDate={startDate} endDate={endDate} emailExport={emailExport} handleChangeEmailExport={handleChangeEmailExport} emailRegex={emailRegex} getDataTips={getDataTips} handleSendCSVExportByMail={handleSendCSVExportByMail} emailExportError={emailExportError} translate={translate} />
    <Breadcrumb label={translate('COMMON.DASHBOARD')} path={`#/`}  />
    <ListTitle content={translate('TIPS.TRACKING')} />
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <GridItem nomargin="true" minMarginBottom={true}>
        <DateRangeFilters
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          handleExportGrid={toggleCSVExportModal}
          disabledExportButton={!isDataTips()}
        />
      </GridItem>
    </Grid>
    <GridItem nomargin="true">
    <TableContainer component={Paper}>
      <Table>
      <CustomTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
        <TableBody className="bodyTableStats">
          {stableSort(tips, getComparator(order, orderBy, translate)).map((row) => {
            return (<TableRow
              key={row.id}
              sx={dashboardStyles.statsTables.row}
            >
              <TableCell align="left">{row.vendeur}</TableCell>
              <TableCell align="right">{formatNumber(row.pourboire)}</TableCell>
            </TableRow>
          );
        })}
        <TableRow
              sx={dashboardStyles.statsTables.row}
            >
              <TableCell align="left" component="th" scope="row"><b>{translate('DASHBOARD.TIPS.TOTAL')}</b></TableCell>
              <TableCell align="right"><b>{formatNumber(totalPourboires)}</b></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </ GridItem>
  </Box>;
};

const CSVExportLink = ({dataGenerator, periodStart, periodEnd, closeModal, t}) => {
  const { data, headers } = dataGenerator();
  const filename = `${t('DASHBOARD.TIPS.CSV_EXPORT_FILENAME', {start: periodStart, end: periodEnd})}.csv`;
  return <Box display={'flex'} flex={1}>
      <Stack
        direction="row"
        alignContent='center'
        alignItems='center'
        justifyContent='center'
        spacing={2}
        width={'100%'}
      >
        <CSVLink onClick={closeModal} style={{...linkStyles.warning}} data={data} headers={headers} filename={filename}>{t('ACTIONS.DOWNLOAD')}</CSVLink>
      </Stack>
    </Box>
}

const CSVExportByMail = ({ emailExport, emailExportError, handleChangeEmailExport, handleSendCSVExportByMail, t}) => {
  return <Box flex={1}>
    <Stack
      direction="row"
      alignContent='center'
      alignItems='center'
      justifyContent='center'
      spacing={2}
    >
      <TextField label={t('COMMON.EMAIL_ADDRESS')} size="small" type="email" onChange={handleChangeEmailExport} value={emailExport}  />
      <Button sx={btnStyles.primary} onClick={handleSendCSVExportByMail} >{t('ACTIONS.SEND')}</Button>
      
    </Stack>
    <Typography variant={"body2"} color="red">{emailExportError}</Typography>
  </Box>
}

const CSVExportModal = ({isOpen, onClose, startDate, endDate, emailExport, handleChangeEmailExport, getDataTips, emailRegex, handleSendCSVExportByMail, emailExportError, translate}) => {
  return <Modal
    open={isOpen}
    onClose={onClose}
  >
   <Box sx={smallModalStyle}>
      <Box sx={modalHeaderStyle}>
        <Typography variant="h6">{translate('DASHBOARD.TIPS.CSV_EXPORT_TITLE')}</Typography>
        <Typography variant="h6">{translate('DASHBOARD.TIPS.CSV_EXPORT_PERIOD', { start: formatDate(startDate), end: formatDate(endDate) })}</Typography>
      </Box>
      <Box sx={{...modalBodyStyle, width:'100%'}}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <CSVExportLink dataGenerator={getDataTips} periodStart={startDate} periodEnd={endDate} closeModal={onClose} t={translate} />
          <CSVExportByMail emailRegex={emailRegex} emailExport={emailExport} emailExportError={emailExportError} handleChangeEmailExport={handleChangeEmailExport} handleSendCSVExportByMail={handleSendCSVExportByMail}  t={translate} />
        </Stack>
      </Box>
    </Box> 
  </Modal>
}

export default TableauPourboire;