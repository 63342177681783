import React, {useEffect} from 'react';
import { Loading, Error, useGetOne } from 'react-admin';
import ListTitle from '../core/ListTitle';
import Breadcrumb from '../core/BreadCrumb';
import GridItem from '../../styles/GridItem';
import { Typography, Box, Grid } from '@mui/material';
import DetailsTransaction from './DetailsTransaction';
import { showPageStyles } from '../../styles/Styles';
import { formatNumber, formatDateTime } from '../../utils/utils';

const idRestaurant = localStorage.getItem('idRestaurant');

function ShowTransaction(props) {
    const numeroTicket = props.match.params.numero_ticket;

    const { data, isLoading, error } = useGetOne(
        `transactions/${idRestaurant}`,
        { id: numeroTicket }
    );

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    if (isLoading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    return (
        <Box sx={showPageStyles.conatiner}>
            <Breadcrumb label={`Transactions`} path={`#/transactions/liste/${idRestaurant}`}   />
            <ListTitle content="Détails" />
            <Grid container spacing={2} >
                <Grid item lg={12} md={12} sm={12} xs={12} >
                    <GridItem position="middle">
                        <Grid container spacing={2} >
                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                <Typography sx={showPageStyles.label} variant="body1"> Date ticket</Typography>
                                <Typography  variant="h6">{formatDateTime(data.date_ticket)}</Typography>

                                <Typography sx={showPageStyles.label} variant="body1"> N° ticket</Typography>
                                <Typography  variant="h6">{data.numero_ticket}</Typography>

                                <Typography sx={showPageStyles.label} variant="body1">N° TABLE</Typography>
                                <Typography  variant="h6">{data.numero_table}</Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                <Typography sx={showPageStyles.label} variant="body1">Pourboire</Typography>
                                <Typography  variant="h6">{formatNumber(data.pourboire)}</Typography>

                                <Typography sx={showPageStyles.label} variant="body1">Montant</Typography>
                                <Typography  variant="h6">{formatNumber(data.montant)}</Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <DetailsTransaction transactions={data.transactions} />
                            </Grid>
                        </Grid>
                    </GridItem>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ShowTransaction;