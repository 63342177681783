import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Box, Link } from '@mui/material';
import { breadcrumbStyles } from '../../styles/Styles';
import { MenuItemLink, useTranslate } from 'react-admin';
import BreadcrumbStyle from '../../styles/BreadcrumbStyle';



const Breadcrumb = (props) => {
  const translate = useTranslate();
  const breadcrumbStyle = BreadcrumbStyle();
  const haveChild = props.child ? true : false;

  const linkStyle = {
    padding:'6px 0',
    color: "rgb(119, 126, 137)",
    fontSize: "1.1rem",
  }
  
  return (
    <Box role="presentation" sx={breadcrumbStyles.container}>
      <Breadcrumbs aria-label="breadcrumb" separator="›" classes={breadcrumbStyle}>
        <MenuItemLink
          to="/"
          primaryText={translate('COMMON.HOME')}
          style={linkStyle}
        />
        <MenuItemLink
          to={props.path.replace('#', '')}
          primaryText={props.label}
          style={{...linkStyle, color: haveChild ? "rgb(119, 126, 137)" : "rgba(0, 0, 0, 0.87)"}}
          
        />
        {
          haveChild ? (
            <Link
              underline="hover"
              aria-current="page"
              color={"text.primary"}
            >
              {props.child}
            </Link>
          ) : null
        }
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;