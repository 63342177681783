import TablesRestaurantIcon from '@mui/icons-material/TableChart';
import TablesRestaurantList from './List';
import TablesRestaurantCreate from './Create';
import TablesRestaurantEdit from './Edit';

const TablesRestaurant = {
  icon: TablesRestaurantIcon,
  name: `qr-codes/:idRestaurant`,
  list: TablesRestaurantList,
  create: TablesRestaurantCreate,
  edit: TablesRestaurantEdit,  
};

export default TablesRestaurant;