import CartesIcon from '@mui/icons-material/ListAlt';
import CartesList from './List';
import CartesCreate from './Create';
import CartesEdit from './Edit';

const Cartes = {
  icon: CartesIcon,
  name: `cartes/:idRestaurant`,
  list: CartesList,
  create: CartesCreate,
  edit: CartesEdit,  
};

export default Cartes;