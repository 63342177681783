export const colors = {
    primary: 'rgb(3, 201, 215)',
    secondary: 'rgb(251, 150, 120)',
    warning: 'rgb(242, 192, 2)',
    danger: 'rgb(228, 106, 118)',
    success: 'rgb(0, 194, 146)'
}

export const disabledColors = {
    primary: 'rgb(3 201 215 / 50%)',
    secondary: 'rgb(251 150 120 / 50%)',
    warning: 'rgb(254 201 15 / 50%)',
    danger: 'rgb(228 106 118 / 50%)',
    success: 'rgb(0 194 146 / 50%)'
}

const baseBtn = {
    border: '0 !important',
    borderRadius: '3px',
    color: 'white',
    height: '30px',
    padding: '20px',
    boxShadow: 'none', 
    cursor: 'pointer', 
    margin:'20px 0',
    marginRight: '10px', 
}

export const baseLink = {
    borderRadius: '3px',
    color: 'white',
    height: '40px',
    padding: '0 20px',
    cursor: 'pointer',
    lineHeight: '38px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block'
}


export const btnStyles = {
    primary: {
        background: `${colors.primary}  !important`,
        ...baseBtn
    },
    secondary: {
        background: `${colors.secondary}  !important`,
        ...baseBtn
    },
    warning: {
        background: `${colors.warning}  !important`,
        ...baseBtn
    },
    danger: {
        background: `${colors.danger} !important`,
        ...baseBtn
    },
    success: {
        background: `${colors.success}  !important`,
        ...baseBtn
    }
}

export const disabledBtnStyles = {
    primary: {
        background: disabledColors.primary,
        ...baseBtn
    },
    secondary: {
        background: disabledColors.secondary,
        ...baseBtn
    },
    warning: {
        background: disabledColors.warning,
        ...baseBtn
    },
    danger: {
        background: disabledColors.danger,
        ...baseBtn
    },
    success: {
        background: disabledColors.success,
        ...baseBtn
    }
}


export const linkStyles = {
    primary: {
        background: colors.primary,
        ...baseLink
    },
    secondary: {
        background: colors.secondary,
        ...baseLink
    },
    warning: {
        ...baseLink,
        background: colors.warning,
    },
    danger: {
        background: colors.danger,
        ...baseLink
    },
    success: {
        background: colors.success,
        ...baseLink
    }
}

export const iconStyles = {
    primary: {
        color: colors.primary
    },
    secondary: {
        color: colors.secondary
    },
    danger: {
        color: colors.danger
    },
    warning: {
        color: colors.warning
    },
    success: {
        color: colors.success
    }
}

export const muiIconStyles = {
    default: {
        margin: '0px 10px 0px 0px',
    },
    primary: {
        margin: '0px 10px 0px 0px',
        color: colors.primary
    },
    secondary: {
        margin: '0px 10px 0px 0px',
        color: colors.secondary
    },
    danger: {
        margin: '0px 10px 0px 0px',
        color: colors.danger
    },
    warning: {
        margin: '0px 10px 0px 0px',
        color: colors.warning
    },
    success: {
        margin: '0px 10px 0px 0px',
        color: colors.success
    }
}

export const tagStyles = {
    primary: {
        color: 'white',
        background: colors.primary
    },
    secondary: {
        color: 'white',
        background: colors.secondary
    },
    danger: {
        color: 'white',
        background: colors.danger
    },
    warning: {
        color: 'rgba(0, 0, 0, 0.6)',
        background: colors.warning
    },
    success: {
        color: 'white',
        background: colors.success
    }
}

export const listDetailsStyles = {
    box: {
        marginTop: '20px', marginBottom: '20px'
    },
    tableContainer: {
        boxShadow: 'none',
    },
    tableRow: {
        // background:'#F5F5F5'
    },
    tableTitle: {
        background: '#a4c3df54', fontFamily: 'DM Sans', padding: "5px 15px", flexGrow: 1, fontWeight: 'bold', fontSize: '15px',
    },
    tableCellHeader: {
        padding: ["15px 5px", "15px 5px", "15px", "15px"],
        fontFamily: 'DM Sans',
        fontWeight: 'bold', fontSiz: '1rem',
        whiteSpace: 'nowrap',
    },
    tableCellContent: {
        padding: ["15px 5px", "15px 5px", "15px", "15px"],
        fontFamily: 'DM Sans',
        color: 'rgb(119, 126, 137)', fontSiz: '1rem',
        whiteSpace: 'nowrap',
    }
}

export const sideBarResponsiveStyles = {
    position:
    {
        lg: 'fixed', md: 'fixed', sm: 'fixed', xs: 'fixed'
    },
    width:
    {
        lg: '17%', md: '25%'
    },
    top: {
        lg: 'unset', md: 'unset', sm: 0, xs: 0
    },
    height: {
        lg: 'auto', md: 'auto', sm: '100vh', xs: '100vh'
    },
    zIndex: {
        lg: 'unset', md: 'unset', sm: '10px', xs: '10px'
    },
    padding: { md: '20px' }
}

export const paragraphAccordionStyles = {
    p: {
        color: 'rgba(0, 0, 0, 0.6)',
        fontFamily: "DM Sans !important",
    },

}

export const dateInputStyles = {
    dateInput: { marginRight: '10px', marginLeft: '10px' },
};

export const stylesTableCell = {
    span: {
        fontWeight: 'bold', fontFamily: 'DM Sans'
    },
    listHeaderCell: { display: 'flex', alignItems: 'center', textAlign: 'right' }
}

export const dashboardStyles = {
    container: {
        // justifyContent: 'center'
    },
    title: {
        fontWeight: 'bold',
        fontSize: 20,
        paddingLeft: 1,
        marginBottom: 1,
        color: '#000',
        textTransform: 'uppercase'
    },
    qrCodes: {
        container: {
            position: 'relative',
            display: 'flex',
            margin: 0,
            justifyContent: 'center',
            alignItems: 'center',
            background: 'transparent',
            boxShadow: 'none',
        },
        image: {
            transform: 'rotate(-17deg)',
            zoom: .5,
        },
        button: {
            padding: 2,
            borderRadius: 8,
        },
        arrow: {
            position: 'absolute',
            top: '80px',
            right: '40%',
            transform: 'scaleX(-1) rotate(-115deg)',
            width: '180px',
            '& img': {
                width: '100%'
            }
        },
        // arrowBody: {
        //     width: '100%',
        //     height: '100%',
        //     borderWidth: '0 5px 5px 0',
        //     borderStyle: 'dashed',
        //     borderColor: '#000',
        //     borderBottomRightRadius: '100%',
        //     '&::after': {
        //         content: '""',
        //         position: 'absolute',
        //         top: '-12px',
        //         right: '-12px',
        //         transform: 'rotate(20deg)',
        //         borderWidth: '0 12px 16px',
        //         borderStyle: 'solid',
        //         borderColor: 'transparent transparent #000',
        //     }
        // }
    },
    filter: {
        textAlign: ['center', 'center', 'left', 'left'],
        margin: { xs: "0px 5px" },
        padding: ['20px 15px', '30px', '30px', '30px'],
        stack: {
            alignItems: 'center',
            flexDirection: ["row", "row", "row", "row"],
        },
        todayBtn: {
            display: ['none', 'inline-flex', 'inline-flex', 'inline-flex']
        },
        todayBtnSm: { display: ['inline-flex', 'none', 'none', 'none'], marginTop: '10px', width: "100%" }
    },
    statsText: {
        position: 'relative', padding: ['25px 15px 20px 15px', '20px 30px 15px 30px', '20px 30px 15px 30px', '20px 30px 15px 30px'],
        listTitle: {
            fontSize: '1.125rem', textAlign: 'left', marginBottom: '20px'
        },
        iconBtn: {
            color: 'white', position: 'absolute', top: '10%', right: '0', marginRight: "16px", lineHeight: '1.75em', fontSize: '28px',
            width: '1.75em', height: '1.75em', borderRadius: '50%'
        },
        icon: {
            fontSize: '100%'
        },
        text: {
            fontSize: '3.5vh', marginLeft: '5px'
        }
    },
    statsGraph: {
        height: "100%", boxSizing: "border-box", padding: ['20px 0px', '30px', '30px', '30px 15px'],
        noPadding: { padding: '0px !important'},
        link: {
            textDecoration: 'none'
        },
        listTitle: {
            fontSize: '1.125rem'
        },
        donutPie: {
            width: "100%", marginLeft: 'auto', marginRight: 'auto'
        }
    },
    statsTables: {
        row: {
            '&:last-child td, &:last-child th': { border: 0 }
        }
    },
    statsCard: {
        position: 'relative', margin: { xs: "0px 5px" }, padding: ['25px 15px 20px 15px', '20px 30px 15px 30px', '20px 30px 15px 30px', '20px 30px 15px 30px'],
        listTitle: {
            fontSize: '1.125rem', textAlign: 'left', marginBottom: '20px'
        },
        cardContent: {
            height: '5em',
        },
        iconBtn: {
            color: 'white', position: 'absolute', top: '10%', right: '0', marginRight: "16px",

        },
        icon: {
            fontSize: '100%',
            border: '1en solid red'
        }
    },
    groupCardContainer: {
        background: '#fff',
        margin: '0 5px',
        marginTop: '16px',
        borderRadius: '20px',
        boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
        overflow: 'hidden',
    },
    groupCardItem: {
        padding: '20px 30px 15px 30px',
        borderRight: '1px solid #0000001a',
        '& .MuiIconButton-root': {
            marginBottom: '2em',
        },
        '& .MuiIconButton-colorDefault': {
            backgroundColor: '#0000000a'
        },
        '& .MuiIconButton-colorError': {
            backgroundColor: '#F4BEBD'
        },
        '& .MuiIconButton-colorInfo': {
            backgroundColor: '#0288d10a'
        },
        '& .MuiIconButton-colorPrimary': {
            backgroundColor: '#B8D3F1'
        },
        '& .MuiIconButton-colorSecondary': {
            backgroundColor: '#9c27b00a'
        },
        '& .MuiIconButton-colorSuccess': {
            backgroundColor: '#BFD5BD'
        },
        '& .MuiIconButton-colorWarning': {
            backgroundColor: '#FAD1B7'
        },
    }
}

export const listStyles = {
    topToolbar: {
        leftSideButtons: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
        }
    }
}

export const inputStyles = {
    main: {
        background: 'white',
        width: 'auto',
        '& .MuiInputBase-root': {
            borderRadius: '5px',
            border: '1px solid #dee3e9',
            background: 'white'
        },
        '& .MuiFilledInput-underline:before': {
            borderBottom: 'none'
        },
        '& input': {
            minWidth: '200px'
        }
    },
    hidden : {
        display: 'none',
    },
    success: {
        // background: 'white',
        // width: 'auto',
        '& .MuiInputBase-root': {
            // borderRadius: '5px',
            border: '1px solid #dee3e9 !important',
            // background: 'white'
        },
        '& .MuiFilledInput-underline:before': {
            borderBottom: `2px solid ${colors.success} !important`,
        },
        '& input': {
            // minWidth: '200px'
        },
        '& .MuiFilledInput-underline:after': {
            borderBottom: `2px solid ${colors.success} !important`,
        },
    },
    hiddenPasswords: {
        display: 'none !important'
    },
    visiblePasswords: {
        display: 'flex'
    },
    withHelper: {
        '& .MuiFormHelperText-root': {
            whiteSpace: 'pre-line',
            lineHeight: '.6em',
            marginBottom: '1em',
        }
    }
}

export const transactionsListStyles = {
    searchInput: {
        marginLeft: ["0px", "10px", "10px", "10px"],
        position: 'absolute',
        top: '16px',
        right: '0',
    },
    todayBtn: {
        display: ['none', 'inline-flex', 'inline-flex', 'inline-flex']
    },
    todayBtnSm: {
        display: ['inline-flex', 'none', 'none', 'none']
    },
    list: {
         padding: "30px"
    },
    responsiveList: {
        padding: ["15px", "15px", "15px", "30px"]
    }
}

export const clientsListStyles = {
    responsiveList: {
        padding: ["15px", "15px", "15px", "30px"]
    }
}

export const showPageStyles = {
    conatiner: {
        flexGrow: 1
    },
    label: {
        fontWeight: 500, fontFamily: "DM Sans", fontSize: '1rem', display: 'block', lineHeight: '1.5px', color: '#a3a1a1'
    },
}

export const appBarStyles = {
    appBarContainer: {
        flexGrow: 1, background: 'white', marginBottom: [0, 0, 0, 0], paddingBottom: [0, 0, 0, 0]
    },
    appBarContent: {
        backgroundColor: "white",
        height:'100%',
        color:'rgb(33 33 33) !important',
        paddingLeft: '1%',
        paddingRight: '1%'
    },
    logoContainer: {
        display: 'flex', flexDirection: 'row', alignItems: 'center', height:'100%'
    },
    logo: {
        marginRight: "0", fontFamily: "Birthstone", display: 'inline', lineHeight: '0.800', padding: '12px 12px 12px 0',
        link: {
            color: 'black', textDecoration: 'none', position: 'relative', background:'blue'
        },
        logoImage: {
            height: '40px', width: '40px', position: 'absolute', top: '36%'
        },
    },
    selectLogout: {
        display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', height:'100%'
    },
    selectedRestaurant: {
        position: "absolute", top: "70px", left: 0, right: 0, textAlign: "center", background: "white", padding: "10px", color: "black", fontWeight: 600,
        display: ['block', 'none', 'none', 'none']
    }
}

export const layoutStyles = {
    topContainer: {
        width:'100%',
        position:'relative',
        minHeight:'100vh',
        margin:0,
    },
    mainContainer: {
        marginTop: ['110px', '100px', '100px', '100px'], padding: ["0px 5px 40px 5px", "0px 5px 40px 5px", "0px 20px 40px 20px", "0px 20px 40px 20px"]
    },
    sidebar: {
        display: ['none', 'none', 'block', 'block']
    },
    sidebarTitle: {
        padding : "10px 0px 10px 0px",
        color:'rgba(0, 0, 0, 0.87)', 
        opacity: '0.4',
        fontWeight: 500, 
        fontSize: '0.875rem',
    },
    content: {
        margin: ["0px 5px", "0px 10px", "0", "0"]
    },
    mobileSideBar: {


    },
    mobileSideBarContainer: {
        left: ["-250px", "-250px", "-250px", "-250px"], width: '100%', height: '100%', display: 'none', padding: "0 !important", position: "absolute", top: "16px", zIndex: 1111, background: 'rgba(0, 0, 0, 0.5)',
    },
    mobileSideBarContent: {
        padding: "20px", height: '100vh', width: '265px', background: "white"
    }
}

export const loginPageStyles = {
    loginContainer: {
        paddingTop: '80px',
    },
    logo: {
        fontSize: '10px', textAlign: 'center', fontFamily: "Birthstone"
    },
    logoImage: {
        height: '20vh', width: '100%'
    },
    label: {
        fontFamily: "DM SANS"
    },
    btn: {
        width: '100%'
    },
    forgotPass: {
        display: 'block', marginTop: '20px',
        width: '100%',
        fontSize: '0.95rem',
        fontFamily: 'DM Sans',
        textAlign: 'right',
        textDecoration: 'none',
        fontWeight: 600
    }
}

export const breadcrumbStyles = {
    container: {
        margin: "0px 5px", textAlign: ['center', 'left', 'left', 'left']
    }
}

export const listTitleStyles = {
    textAlign: ['center', 'left', 'left', 'left'],
    color:'rgba(0, 0, 0, 0.87)',
    fontWeight: 700,
    fontFamily: "DM Sans",
    position:'relative',
    fontSize:"3.5vh",
    lineHeight: 1.235,
    margin: "0px 5px",
    marginBottom:'25px',
}

export const adminForm = {
    divider: {
        marginBottom: "20px",
        marginTop: "30px",
        first: {
            marginTop: 0
        },
        middle: {
            marginBottom: "20px",
            marginTop: "10px",
        }
    },
    chip: {
        color: 'white',
        background: colors.secondary
    }
}

export const largeModalStyle = {
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: '24px',
    p: '4px',
    fontFamily:"DM Sans",
    margin: { xs: "20% 25px 0 25px", sm: "10% 20% 0 20%" },
    padding:'0 20px',
    textAlign:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  };

  export const smallModalStyle = {
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: '24px',
    p: '4px',
    fontFamily:"DM Sans",
    margin: { xs: "20% 25px 0 25px", sm: "12% 30% 0 30%" },
    padding:'0 20px',
    textAlign:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  };

  
  export const largeVerticalModalStyle = {
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: '24px',
    p: '4px',
    fontFamily:"DM Sans",
    margin: { xs: "20% 25px 0 25px", sm: "5% 30% 0 30%" },
    height:'80%',
    padding:'0 20px',
    textAlign:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  };

export const modalHeaderStyle = {
    borderBottom: '1px solid #ccc',
    width:'100%',
    padding:'10px 20px'
  };

export const modalBodyStyle = {
    padding:'30px'
  };

  export const modalVerticalBodyStyle = {
    width:'100%',
    padding:'30px',
    height: '80%'
  };

export const modalFooterStyle = {
    borderTop: '1px solid #ccc',
    width:'100%',
    padding:'10px 20px'
  };

export const listMenuItemStyle = (color) => {
    return {
        box: {
            background: color
        },
        boxLink: { 
            display: 'flex',
            alignItems:'center'
        },
        infoIcon: {
            fontSize:'18px'
        }
    }
};