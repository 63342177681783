import * as React from "react";
import {
  TextField,
  FunctionField,
  EditButton,
  useTranslate,
  useRecordContext,
  usePermissions,
  useResourceContext,
  useCreate,
  useNotify,
  useGetList,
  Loading,
  useRefresh,
  SearchInput,
} from 'react-admin';
import {
  HomeOutlined,
  Settings,
  FiberManualRecord,
  FlipToFront,
  PermIdentity,
  Business,
  Close
} from '@mui/icons-material';
import { muiIconStyles, transactionsListStyles } from "../../styles/Styles";
import ListComponent from "../core/ListComponent";
import { Box, Button } from "@mui/material";
import { getRoleAndPermissions } from "../../utils/utils";
import { useState, useEffect } from "react";
import ListCellHeader from "../core/ListCellHeader";
import ConfirmModal from './ConfirmModal';
import { clearAuthLocalStorage, clearSessionLocalStorage, setAuthLocalStorage } from '../../utils/utils';
import MultipleSelectInput from "../core/MultipleSelectInput";

const RestaurantList = props => {
  const globalPermissions = usePermissions();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMetas, setConfirmMetas] = useState({});
  const [restaurant, setRestaurant] = useState();
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [selectedPayxpert, setSelectedPayxpert] = useState([]);
  const [selectedPaygreen, setSelectedPaygreen] = useState([]);
  const agence = JSON.parse(localStorage.getItem('agence'));
  const userAgency = (agence && agence.slug === 'all') ?  false : agence?.id;
  const [filterDefaultValues, setFilterDefaultValues] = useState((userAgency) ? {id_agence : [userAgency], payxpertStatus: [], paygreenStatus: []} : {id_agence : [], payxpertStatus: [], paygreenStatus: []})
  const { data: agences, isLoading: isLoadingAgences } = useGetList('agences');
  const [loginAs, { error: errorLogAs, data: dataLogAs }] = useCreate();
  const [onBoardingRequest, { data: onBoardingRestaurant }] = useCreate();
  const [reminderRequest, { data: reminderRestaurant }] = useCreate();
  const translate = useTranslate();
  const notify = useNotify();
  const { role, permissions, country } = getRoleAndPermissions(globalPermissions.permissions, resource);
  const _IS_ADMIN_ES = country === 'ES';
  const isNotAdmin = (role === 'resp' || role === 'tech');
  useEffect(() => {
    if (dataLogAs) {
      // clear localstorage
      clearAuthLocalStorage();
      clearSessionLocalStorage();
      // set selected restaurant user to localstorage
      const { id, ...payload } = dataLogAs;
      localStorage.setItem('username', id);
      setAuthLocalStorage(payload);
      // go to home page
      window.location.href = "/";
    }

    if (errorLogAs) notify(errorLogAs, { type: 'error' });
  }, [dataLogAs, errorLogAs]);

  useEffect(() => {
    if (onBoardingRestaurant) refresh();
    if (reminderRestaurant) refresh();
  }, [onBoardingRestaurant, reminderRestaurant, selectedAgencies, filterDefaultValues])

  if (!permissions) return null;
  if (isLoadingAgences || !agences) return <Loading />;

  const _PAYXPERT_STATUS = [
    { id: 'pending', name: `${translate('COMMON.STATUS_PENDING').charAt(0).toUpperCase()}${translate('COMMON.STATUS_PENDING').slice(1)}` },
    { id: 'active', name: `${translate('COMMON.ACTIVE').charAt(0).toUpperCase()}${translate('COMMON.ACTIVE').slice(1)}` },
    { id: 'inactive', name: `${translate('COMMON.INACTIVE').charAt(0).toUpperCase()}${translate('COMMON.INACTIVE').slice(1)}` },
    { id: 'remind_request', name: `${translate('TRANSACTIONS.REMINDER_REQUEST')}` },
  ];

  const _PAYGREEN_STATUS = [
    
    { id: 'onboarding_request', name: `${translate('TRANSACTIONS.ONBOARDING_REQUEST')}` },
    { id: 'pending', name: `${translate('COMMON.STATUS_PENDING').charAt(0).toUpperCase()}${translate('COMMON.STATUS_PENDING').slice(1)}` },
    { id: 'active', name: `${translate('COMMON.ACTIVE').charAt(0).toUpperCase()}${translate('COMMON.ACTIVE').slice(1)}` },
    { id: 'disabled', name: `${translate('COMMON.DISABLED').charAt(0).toUpperCase()}${translate('COMMON.DISABLED').slice(1)}` },
    { id: 'not_used', name: `${translate('COMMON.STATUS_NOT_USED')}` },
    { id: 'remind_request', name: `${translate('TRANSACTIONS.REMINDER_REQUEST')}` },
  ];

  const _AGENCIES = agences.map(agence => {
    return {id: agence.id, name: agence.nom}
  });

  const mobileList = {
    primaryText: r => r.nom,
    secondaryText: r => r.status,
    tertiaryText: r => r.adresse_mail_utilisateur,
  };


  const handleAccesBo = (val) => {
    setRestaurant(val);
    const metas = {
      title: translate('MODAL_BO_ACCESS.TITLE'),
      message: translate('MODAL_BO_ACCESS.MESSAGE', { nom: val.nom }),
      submit: (data) => loginAs('authentication/login-as', { data })
    };
    setConfirmMetas(metas);
    setShowConfirmModal(true);
  };
  
  const handleOnBoardPaygreen = (val) => {
    setRestaurant(val);
    const metas = {
      title: translate('MODAL_ONBOARDING.TITLE'),
      message: translate('MODAL_ONBOARDING.MESSAGE', { nom: val.nom }),
      submit: (data) => onBoardingRequest('restaurants/onboarding-request', { data })
    };
    setConfirmMetas(metas);
    setShowConfirmModal(true);
  };

  const handleRemindPaygreen = (val) => {
    setRestaurant(val);
    const metas = {
      title: translate('MODAL_REMINDER.TITLE', {service: 'Paygreen'}),
      message: translate('MODAL_REMINDER.MESSAGE', { nom: val.nom }),
      submit: (data) => reminderRequest('restaurants/reminder-request', { data: {...data, service: 'PAYGREEN'} })
    };
    setConfirmMetas(metas);
    setShowConfirmModal(true);
  };

  const onChangeSelectedAgencies = (event, values, reason) => {
    setSelectedAgencies(values);
    
    if(reason === 'selectOption'){
      const newSelectedAgencies = values.map(a => a.id);
      const agencies = (userAgency) ? [userAgency, ...newSelectedAgencies] : newSelectedAgencies
      setFilterDefaultValues({...filterDefaultValues, id_agence: agencies});
    }
    else if(['removeOption', 'clear'].includes(reason))
    {
      const newSelectedAgencies = filterDefaultValues.id_agence.filter(a => values.findIndex(agency => agency.id === a) >= 0);
      const agencies = (userAgency) ? [userAgency, ...newSelectedAgencies] : newSelectedAgencies
      setFilterDefaultValues({...filterDefaultValues, id_agence: agencies})
    }
  }

  const onChangeSelectedPayxpert = (event, values, reason) => {
    setSelectedPayxpert(values);
    
    if(reason === 'selectOption'){
      const newSelectedPayxpert = values.map(a => a.id);
      setFilterDefaultValues({...filterDefaultValues, payxpertStatus: newSelectedPayxpert});
    }
    else if(['removeOption', 'clear'].includes(reason))
    {
      const newSelectedPayxpert = filterDefaultValues.payxpertStatus.filter(a => values.findIndex(item => item.id === a) >= 0);
      setFilterDefaultValues({...filterDefaultValues, payxpertStatus: newSelectedPayxpert})
    }
  }

  const onChangeSelectedPaygreen = (event, values, reason) => {
    setSelectedPaygreen(values);
    
    if(reason === 'selectOption'){
      const newSelectedPaygreen = values.map(a => a.id);
      setFilterDefaultValues({...filterDefaultValues, paygreenStatus: newSelectedPaygreen});
    }
    else if(['removeOption', 'clear'].includes(reason))
    {
      const newSelectedPaygreen = filterDefaultValues.paygreenStatus.filter(a => values.findIndex(item => item.id === a) >= 0);
      setFilterDefaultValues({...filterDefaultValues, paygreenStatus: newSelectedPaygreen})
    }
  }

  const onChangeAgencyCheckbox = (agencyId) => {
    const isAlreadyChecked = selectedAgencies.findIndex(agency => agency.id === Number(agencyId)) >= 0;
    if(isAlreadyChecked)
    {
      const agencies = selectedAgencies.filter(a => a.id !== Number(agencyId));
      setSelectedAgencies(agencies);
      const newSelectedAgencies = filterDefaultValues.id_agence.filter(id => id !== Number(agencyId));
      setFilterDefaultValues({...filterDefaultValues, id_agence: newSelectedAgencies})
    }
  }

  const onChangePayxpertCheckbox = (statusId) => {
    const isAlreadyChecked = selectedPayxpert.findIndex(item => item.id === statusId) >= 0;
    if(isAlreadyChecked)
    {
      const status = selectedPayxpert.filter(item => item.id !== statusId);
      setSelectedPayxpert(status);
      const newSelectedPayxpert = filterDefaultValues.payxpertStatus.filter(id => id !== statusId);
      setFilterDefaultValues({...filterDefaultValues, payxpertStatus: newSelectedPayxpert})
    }
  }

  const onChangePaygreenCheckbox = (statusId) => {
    const isAlreadyChecked = selectedPaygreen.findIndex(item => item.id === statusId) >= 0;
    if(isAlreadyChecked)
    {
      const status = selectedPaygreen.filter(item => item.id !== statusId);
      setSelectedPaygreen(status);
      const newSelectedPaygreen = filterDefaultValues.paygreenStatus.filter(id => id !== statusId);
      setFilterDefaultValues({...filterDefaultValues, paygreenStatus: newSelectedPaygreen})
    }
  }

  const agencyFilter = () => {
    const options = (userAgency) ?  _AGENCIES.filter(agency => agency.id === userAgency) : _AGENCIES;
    return <MultipleSelectInput 
              onSelectChange={onChangeSelectedAgencies}
              selectValues={selectedAgencies}
              alwaysOn={true}
              multiple={true}
              id={"agencySearch"}
              key={"agency"}
              options={options}
              optionLabel={"name"}
              limit={1}
              optionValue={"id"}
              onCheckBoxChange={onChangeAgencyCheckbox}
              optionText={"name"}
              inputSource={"id_agence"}
              inputLabel={translate('COMMON.AGENCY')}
            />
  }

  const payxpertStatusFilter = () => {
    return <MultipleSelectInput 
              onSelectChange={onChangeSelectedPayxpert}
              selectValues={selectedPayxpert}
              alwaysOn={true}
              multiple={true}
              id={"payxpertStatusSearch"}
              key={"payxpertStatus"}
              options={_PAYXPERT_STATUS}
              optionLabel={"name"}
              limit={1}
              optionValue={"id"}
              onCheckBoxChange={onChangePayxpertCheckbox}
              optionText={"name"}
              inputSource={"payxpert"}
              inputLabel={'Payxpert'}
            />
  }

  const paygreenStatusFilter = () => {
    return <MultipleSelectInput 
              onSelectChange={onChangeSelectedPaygreen}
              selectValues={selectedPaygreen}
              alwaysOn={true}
              multiple={true}
              id={"paygreenStatusSearch"}
              key={"paygreenStatus"}
              options={_PAYGREEN_STATUS}
              optionLabel={"name"}
              limit={1}
              optionValue={"id"}
              onCheckBoxChange={onChangePaygreenCheckbox}
              optionText={"name"}
              inputSource={"paygreen"}
              inputLabel={'Paygreen'}
            />
  }

  
 
  const filters = [
    agencyFilter(),
    payxpertStatusFilter(),
    <SearchInput
      source="keyword"
      alwaysOn
      style={transactionsListStyles.searchInput}
    />
  ];

  if(!_IS_ADMIN_ES) filters.splice(2, 0, paygreenStatusFilter()) // Si pas ADMIN ES, ajouter le filtre paygreen juste après le filtre payxpert
  
  const handleRemindPayxpert = (val) => {
    setRestaurant(val);
    const metas = {
      title: translate('MODAL_REMINDER.TITLE', { service: 'Payxpert' }),
      message: translate('MODAL_REMINDER.MESSAGE', { nom: val.nom }),
      submit: (data) => reminderRequest('restaurants/reminder-request', { data: {...data, service: 'PAYXPERT'} })
    };
    setConfirmMetas(metas);
    setShowConfirmModal(true);
  };

  const handleRemindPayplug = (val) => {
    setRestaurant(val);
    const metas = {
      title: translate('MODAL_REMINDER.TITLE', { service: 'Payplug' }),
      message: translate('MODAL_REMINDER.MESSAGE', { nom: val.nom }),
      submit: (data) => reminderRequest('restaurants/reminder-request', { data: {...data, service: 'PAYPLUG'} })
    };
    setConfirmMetas(metas);
    setShowConfirmModal(true);
  };

  const handleRemindEdenred = (val) => {
    setRestaurant(val);
    const metas = {
      title: translate('MODAL_REMINDER.TITLE', { service: 'Edenred' }),
      message: translate('MODAL_REMINDER.MESSAGE', { nom: val.nom }),
      submit: (data) => reminderRequest('restaurants/reminder-request', { data: {...data, service: 'EDENRED'} })
    };
    setConfirmMetas(metas);
    setShowConfirmModal(true);
  };

  const handleCancelConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleSubmitConfirmModal = () => {
    confirmMetas.submit(restaurant);
    setShowConfirmModal(false);
    setRestaurant(false);
    setConfirmMetas(null);
  };

  const rowStyle = (r) => {
    if (!r.compte_actif) return { backgroundColor: "#ddd" };
  };

  return (
    <>
      <ListComponent
        id="restaurantList"
        mobilelist={mobileList}
        translate={translate}
        sort={{ field: 'nom', order: 'ASC' }}
        filters={filters}
        filter={{ id_agence: filterDefaultValues.id_agence, payxpertStatus: filterDefaultValues.payxpertStatus, paygreenStatus: filterDefaultValues.paygreenStatus }}
        rowStyle={rowStyle}
      >
        <NameField label={<ListCellHeader icon={<HomeOutlined sx={muiIconStyles.primary} />} text={translate('RESTAURANTS.NAME')} />} labelDisabled={translate('COMMON.DISABLED')} />
        <TextField label={<ListCellHeader icon={<Business sx={muiIconStyles.success} />} text={translate('COMMON.AGENCY')} />} source="nom_agence" />
        <TextField label={<ListCellHeader icon={<PermIdentity sx={muiIconStyles.secondary} />} text={translate('COMMON.CLIENT_CODE')} />} source="code_client" textAlign="right" />
        <PayxpertField label={<ListCellHeader text="Payxpert" />} translate={translate} handleOpenReminderModal={handleRemindPayxpert} isNotAdmin={isNotAdmin} />
        {(!_IS_ADMIN_ES) && <PaygreenField
          label={<ListCellHeader text="Paygreen" />}
          translate={translate}
          handleOpenOnBoardModal={handleOnBoardPaygreen}
          handleOpenReminderModal={handleRemindPaygreen}
          isNotAdmin={isNotAdmin}
        />}
        <PayplugField label={<ListCellHeader text="Payplug" />} translate={translate} handleOpenReminderModal={handleRemindPayplug} isNotAdmin={isNotAdmin} />
        <EdenredField label={<ListCellHeader text="Edenred" />} translate={translate} handleOpenReminderModal={handleRemindEdenred} isNotAdmin={isNotAdmin} />
        {permissions.edit && <EditField label={<ListCellHeader icon={<Settings sx={muiIconStyles.primary} />} text={translate('ACTIONS.EDIT')} />} />}
        <FunctionField
          label={<ListCellHeader icon={<Settings sx={muiIconStyles.success} />} text={translate('RESTAURANTS.BO_ACCESS')} />}
          render={r => <ImpersonnificationField nom={r.nom} email={r.adresse_mail_utilisateur} role={role} handleClick={handleAccesBo} />}
        />
      </ListComponent>
      <ConfirmModal
        t={translate}
        show={showConfirmModal}
        title={confirmMetas?.title}
        message={confirmMetas?.message}
        handleCancel={handleCancelConfirmModal}
        handleSubmit={handleSubmitConfirmModal}
      />
    </>
  );
};


const NameField = ({ labelDisabled }) => {
  const record = useRecordContext();
  const isActive = record.compte_actif;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", textTransform: 'lowercase', '& span:first-child:first-letter': { textTransform: 'uppercase' } }}>
      <span style={{ whiteSpace: "nowrap" }}>{record.nom}</span>
      {!isActive && <span style={{ whiteSpace: "nowrap", color: '#999' }}>({labelDisabled})</span>}
      <span style={{ whiteSpace: "nowrap", color: '#999', fontSize: '.8em' }}>{record.adresse_mail_utilisateur}</span>
    </Box>
  );
};

const PayxpertField = ({ translate, handleOpenReminderModal, isNotAdmin }) => {
  const record = useRecordContext();
  const { payxpert, id, nom, compte_actif } = record;
  const statusKey = payxpert?.id;
  const status = payxpert?.text;
  const color = (compte_actif) ? payxpert?.color : '#666';

  if (statusKey === 'remind_request' && compte_actif) return <RelanceButton id={id} nom={nom} t={translate} submit={handleOpenReminderModal} disabled={isNotAdmin} />;

  return (
    <Box sx={{ display: "flex", alignItems: "center", color, '& span:first-letter': { textTransform: 'uppercase' } }}>
      <FiberManualRecord />
      <span style={{ whiteSpace: "nowrap" }}>{status}</span>
    </Box>
  );
};

const PaygreenField = ({ translate, handleOpenOnBoardModal, handleOpenReminderModal, isNotAdmin }) => {
  const record = useRecordContext();
  const { paygreen, id, nom, compte_actif } = record;
  const status = paygreen?.text;
  const statusKey = paygreen?.id;
  const color = (compte_actif) ? paygreen?.color : '#666';
  if (statusKey === 'remind_request' && compte_actif) return <RelanceButton id={id} nom={nom} t={translate} submit={handleOpenReminderModal} disabled={isNotAdmin} />;
  if (statusKey === 'onboarding_request' && compte_actif) return <OnBoardingButton id={id} nom={nom} t={translate} submit={handleOpenOnBoardModal} disabled={isNotAdmin} />;
  
  
  return (
    <Box sx={{ display: "flex", alignItems: "center", color, '& span:first-letter': { textTransform: 'uppercase' } }}>
      {(statusKey === 'not_used') ? <Close /> : <FiberManualRecord />}
      {(statusKey !== 'not_used') && <span style={{ whiteSpace: "nowrap" }}>{status}</span>}
    </Box>
  );
};

const PayplugField = ({ translate, handleOpenReminderModal, isNotAdmin }) => {
  const record = useRecordContext();
  const { payplug, id, nom, compte_actif } = record;
  const statusKey = payplug?.id;
  const status = payplug?.text;
  const color = (compte_actif) ? payplug?.color : '#666';

  if (statusKey === 'remind_request' && compte_actif) return <RelanceButton id={id} nom={nom} t={translate} submit={handleOpenReminderModal} disabled={isNotAdmin} />;

  return (
    <Box sx={{ display: "flex", alignItems: "center", color, '& span:first-letter': { textTransform: 'uppercase' } }}>
      <FiberManualRecord />
      <span style={{ whiteSpace: "nowrap" }}>{status}</span>
    </Box>
  );
};

const EdenredField = ({ translate, handleOpenReminderModal, isNotAdmin }) => {
  const record = useRecordContext();
  const { edenred, id, nom, compte_actif } = record;
  const statusKey = edenred?.id;
  const status = edenred?.text;
  const color = (compte_actif) ? edenred?.color : '#666';

  if (statusKey === 'remind_request' && compte_actif) return <RelanceButton id={id} nom={nom} t={translate} submit={handleOpenReminderModal} disabled={isNotAdmin} />;

  return (
    <Box sx={{ display: "flex", alignItems: "center", color, '& span:first-letter': { textTransform: 'uppercase' } }}>
      <FiberManualRecord />
      <span style={{ whiteSpace: "nowrap" }}>{status}</span>
    </Box>
  );
};

const OnBoardingButton = ({ t, id, nom, submit, disabled }) => {
  const handleClick = () => submit({ id, nom });

  return (
    <Button variant="contained" size="small" color="secondary" onClick={handleClick} disabled={disabled}>
      {t('TRANSACTIONS.ONBOARDING_REQUEST')}
    </Button>
  );
};

const RelanceButton = ({ t, id, nom, submit, disabled }) => {
  const handleClick = () => submit({ id, nom });
  return (
    <Button variant="contained" size="small" color="secondary" onClick={handleClick} disabled={disabled}>
      {t('TRANSACTIONS.REMINDER_REQUEST')}
    </Button>
  );
}

const EditField = ({ label, ...props }) => <EditButton label="" {...props} />;

const ImpersonnificationField = ({ nom, email, role, handleClick }) => {
  const handleSubmit = () => handleClick({ nom, email, role });

  return (
    <Button onClick={handleSubmit} >
      <FlipToFront />
    </Button>
  );
};

export default RestaurantList;