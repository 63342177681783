import * as React from 'react';
import { useState, useEffect } from 'react';
import { useGetPermissions, useTranslate, useResourceDefinitions, useSidebarState, useGetOne, useRedirect } from 'react-admin';
import { useLocation } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import MenuItem from '../components/core/MenuItem';
import SideBarTitle from '../components/core/SideBarTitle';
import { BarChartOutlined, Build, TableChart, ListAlt, QueryStats } from '@mui/icons-material';
import { PersonAdd } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { colors, layoutStyles } from '../styles/Styles';
import RestrictionModal from '../components/Settings/restrictionModal';


const isBOAdmin = (role) => role !== 'user';
const _EXCLUDED_ITEMS_BY_ROLE = {
  superAdmin: ['clients','statistiques', 'statistiques/scores'],
  admin: ['clients','statistiques', 'statistiques/scores', 'transactions/all'],
  user: ['qr-codes','cartes', 'statistiques/scores']
}

const _RESTRICTION_MODAL_TITLE_BY_LOCATION = {
  '/cartes/:idRestaurant' : 'MODAL_RESTRICTION.MENU_CARDS_TITLE',
  '/parametres' : 'MODAL_RESTRICTION.TIPS_TITLE'
}

const CustomSidebar = ({ onMenuClick, logout }) => {
  const [role, setRole] = useState();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const getPermissions = useGetPermissions();

  useEffect(() => {
    getPermissions().then(p => {
      setRole(p.name);
      // permission accordé si la ressource existe dans Role avec param read à true
      if(p?.resources)
      {
        const permissions = Object.keys(p.resources).map(k => (p.resources[k].read) ? k : null);
        setPermissions(permissions.filter(p => p));
      }
    });
    setIsSuperAdmin((localStorage.getItem('isAdmin') === 'true'));
    // Cleanup function de useEffect: utilisé pour empecher memory leak qui peut etre causé par le warning 'Can't perform a React state update on an unmounted component' dans la console
    return () => {
      setRole();
      setPermissions([]);
      setIsSuperAdmin(false);
    };
  }, [getPermissions]);

  const isSM = useMediaQuery('(max-width:899px)');
  const [open] = useSidebarState();
  const isSmall = (isSM) ? true : false;
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  const items = (resources)
    ? Object.values(resources)
      .filter(r => {
        const item = r.name.split(':')[0];
        const showItem = permissions.indexOf(item) > -1;
        if (isSuperAdmin) return showItem && !_EXCLUDED_ITEMS_BY_ROLE['superAdmin'].includes(r.name);
        if (role !== 'user') return showItem && !_EXCLUDED_ITEMS_BY_ROLE['admin'].includes(r.name);
        return showItem && !_EXCLUDED_ITEMS_BY_ROLE['user'].includes(r.name)
      })
      .map(p => renderMenuItem({ onMenuClick, isOpen: open, isSmall, ...p }))
    : null;

  if (!role) return null;

  return (
    <> 
      {
        (isBOAdmin(role))
          ? <AdminContent
            isOpen={open}
            isSmall={isSmall}
            items={items}
            onMenuClick={onMenuClick}
            permissions={permissions}
            role={role}
            isSuperAdmin={isSuperAdmin}
          />
          : <UserContent
            isOpen={open}
            isSmall={isSmall}
            items={items}
            onMenuClick={onMenuClick}
            permissions={permissions}
          />
      }
    </>
  );
};

const renderMenuItem = (props) => {
  if (!props) return null;
  return (
    <MenuItem
      key={props.name}
      to={{ pathname: `/${props.name}`, state: { _scrollToTop: true } }}
      primaryText={props.options && props.options.label ? props.options.label : props.name}
      leftIcon={props.icon ? <props.icon /> : null}
      sidebarIsOpen={props.isOpen}
      bg={props.options.bg}
      onClick={props.isSmall ? window['closeMenuMobile'] : null}
    />
  );
}

const AdminContent = (props) => {
  const translate = useTranslate();
  return (
    <Box>
      <MenuDashboard
        show={props.role === 'admin' || props.isSuperAdmin}
        t={translate}
        isOpen={props.isOpen}
        isSmall={props.isSmall}
        menuColor={colors.primary}
        titleStyles={layoutStyles.sidebarTitle}
      />

      {props.isSuperAdmin && <SideBarTitle text={translate('SIDEBAR.CREATE').toUpperCase()} sx={layoutStyles.sidebarTitle} />}
      {/* {MenuCreerRestaurant(props, translate)} */}
      {MenuCreerAdminUser(props, translate)}

      <SideBarTitle text={translate('SIDEBAR.MANAGEMENT').toUpperCase()} sx={layoutStyles.sidebarTitle} />
      {props.items}

      {/* {isXS && logout} */}
    </Box>
  );
}

const UserContent = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { pathname } = useLocation();
  const [hasRestrictions, setHasRestrictions] = useState(false);
  const [openModalRestrictions, setOpenModalRestrictions] = useState(false);
  const [newLocation, setNewLocation] = useState('');
  const idRestaurant = localStorage.getItem('idRestaurant');
  const { data: restrictions, isLoading: isLoadingRestrictions } = useGetOne(`settings/account`, { id : idRestaurant  });

  useEffect(() => {
    if (!isLoadingRestrictions && restrictions) setHasRestrictions(restrictions?.actif);
  }, [isLoadingRestrictions]);

  const blockAccessByRestrictions = (event, url, force = false) => {
    if(hasRestrictions && !force && pathname !== url)
    {
      event.preventDefault();
      setNewLocation(url)
      return setOpenModalRestrictions(true);
    }
    redirect('list', url, false, {}, { forceAccess : true});
    return props.isSmall ? window['closeMenuMobile'] : null

  }

  const handleCloseModalrestrictions = () => {
    setOpenModalRestrictions(false);
  };

  const handleSubmitModalrestrictions = () => {
    setOpenModalRestrictions(false);
    blockAccessByRestrictions(null, newLocation, true); 
  };

  if (isLoadingRestrictions) return null;

  return (
    <>
      <RestrictionModal title={newLocation ? translate(_RESTRICTION_MODAL_TITLE_BY_LOCATION[newLocation]) : ''} open={openModalRestrictions} onClose={handleCloseModalrestrictions} handleSubmit={handleSubmitModalrestrictions} />
      <Box>
        <MenuDashboard
          show={true}
          t={translate}
          isOpen={props.isOpen}
          isSmall={props.isSmall}
          menuColor={colors.primary}
          titleStyles={layoutStyles.sidebarTitle}
        />
        <SideBarTitle text={translate('SIDEBAR.CONSULTING').toUpperCase()} sx={layoutStyles.sidebarTitle} />

        {props.items}
        <SideBarTitle text={translate('SIDEBAR.SETTINGS').toUpperCase()} sx={layoutStyles.sidebarTitle} />
        <MenuItem
          to="/parametres"
          primaryText={translate('COMMON.TIPS')}
          leftIcon={<Build />}
          sidebarIsOpen={props.isOpen}
          bg={colors.secondary}
          onClick={(event) => blockAccessByRestrictions(event, '/parametres')}
        />
        <MenuItem
          to="/qr-codes/:idRestaurant"
          primaryText={translate('SIDEBAR.QRCODES')}
          leftIcon={<TableChart />}
          sidebarIsOpen={props.isOpen}
          bg={colors.secondary}
          onClick={() => props.isSmall ? window['closeMenuMobile'] : null}
        />
        <MenuItem
          to="/cartes/:idRestaurant"
          primaryText={translate('SIDEBAR.MENU_SETTINGS')}
          leftIcon={<ListAlt />}
          sidebarIsOpen={props.isOpen}
          bg={colors.secondary}
          onClick={(event) => blockAccessByRestrictions(event, '/cartes/:idRestaurant')}
        />

        <MenuStatistiques
          t={translate}
          permissions={props.permissions}
          sidebarIsOpen={props.isOpen}
          titleStyles={layoutStyles.sidebarTitle}
          menuColor={colors.success}
          onClick={() => props.isSmall ? window['closeMenuMobile'] : null}
        />
        {/* <MenuItem
          to="/assistance"
          primaryText="Assistance"
          leftIcon={<HelpOutlineOutlinedIcon />}
          sidebarIsOpen={props.isOpen}
          bg={`#d0d0d0`}
          onClick={props.isSmall ? window['closeMenuMobile'] : null}
        /> */}

        {/* {isXS && logout} */}
      </Box>
    </>
  );
};

const MenuDashboard = ({ show, t, isOpen, isSmall, menuColor, titleStyles }) => {
  if (!show) return null;
  return (
    <>
      <SideBarTitle text={t('COMMON.DASHBOARD').toUpperCase()} sx={titleStyles} />
      <MenuItem
        to="/"
        primaryText={t('COMMON.DASHBOARD')}
        leftIcon={<BarChartOutlined />}
        sidebarIsOpen={isOpen}
        bg={menuColor}
        onClick={() => isSmall ? window['closeMenuMobile'] : null}
      />
    </>
  );
};

const MenuCreerAdminUser = (props, translate) => {
  if (props.permissions.indexOf('utilisateurs') < 0) return null;

  return (
    <MenuItem
      to="/utilisateurs/create"
      primaryText={translate('SIDEBAR.CREATE_ADMIN_USER')}
      leftIcon={<PersonAdd />}
      sidebarIsOpen={props.isOpen}
      bg={colors.secondary}
      onClick={props.isSmall ? window['menuToggle'] : null}
    />
  );
};

const MenuStatistiques = ({ t, permissions, sidebarIsOpen, titleStyles, menuColor, onClick }) => {
  if (permissions.indexOf('statistiques/scores') < 0) return null;

  return (
    <>
      <SideBarTitle text={t('SIDEBAR.STATISTIQUES').toUpperCase()} sx={titleStyles} />
      <MenuItem
        to="statistiques/scores/:idRestaurant"
        primaryText={t('SIDEBAR.STATISTIQUES')}
        leftIcon={<QueryStats />}
        sidebarIsOpen={sidebarIsOpen}
        bg={menuColor}
        exact
        onClick={onClick}
      />
    </>
  );
};

export default CustomSidebar;