import React from 'react';
import { Admin, Resource, ListGuesser, resolveBrowserLocale, CustomRoutes, Loading } from 'react-admin';
import { Route } from 'react-router';
import Dashboard from './components/Dashboard'
import dataProvider from './utils/dataProvider';
import authProvider from './utils/authProvider';
import CustomLayoutContainer from './layout/CustomLayoutContainer';
import LoginPage from './components/Login/LoginPage'
import Transactions from './components/Transactions';
import Restaurants from './components/Restaurants';
import Utilisateurs from './components/Utilisateurs';
import TablesRestaurant from './components/TablesRestaurant';
import Cartes from './components/Cartes';
import Assistance from './components/Assistance';
import TableauRepartitionMoyenPaiement from "./components/Dashboard/TableauRepartitionMoyenPaiement";
import TableauRepartitionMidiSoir from "./components/Dashboard/TableauRepartitionMidiSoir";
import TableauPourboire from "./components/Dashboard/TableauPourboire";
import ShowTransaction from "./components/Transactions/ShowTransaction";
import ResetPassword from './components/Login/ResetPassword';
import Parametres from "./components/Parametres/index.js";
import Scores from './components/Score';
import i18nProvider from './i18n';
import AllTransactions from './components/AllTransactions';
import AccountSettings from './components/Settings/Account';


function App() {
  
  return (
    <Admin layout={CustomLayoutContainer} dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider} locale={resolveBrowserLocale({ fullLocale: true })} i18nProvider={i18nProvider} loginPage={LoginPage} loading={Loading} >
      <Resource {...Transactions} options={{ label: 'TRANSACTIONS.TITLE', bg: '#f2c002' }} />
      <Resource {...TablesRestaurant} options={{ label: 'QRCODE.TITLE', bg: '#f2c002' }} />
      <Resource {...Cartes} options={{ label: 'CARDS.TITLE', bg: '#f2c002' }} />
      <Resource name="restaurantsByUser" list={ListGuesser} />
      <Resource name="utilisateurs/list-accounts" list={ListGuesser} />
      <Resource {...Restaurants} options={{ label: 'SIDEBAR.RESTAURANTS', bg: '#f2c002'}}/>
      <Resource {...Utilisateurs} options={{ label: 'SIDEBAR.USERS', bg: '#f2c002'}}/>
      <Resource {...Scores} options={{ label: 'SIDEBAR.STATISTIQUES', bg: '#f2c002'}}/>
      <Resource {...AllTransactions} options={{ label: 'COMMON.TRANSACTIONS_ALL', bg: '#f2c002' }} />
      <Resource name='settings/account' edit={AccountSettings} options={{ label: "restriciton", bg: '#f2c002' }} />

      <CustomRoutes> 
        <Route exact path="/assistance" element={<Assistance />} />  
        <Route exact path="/parametres" element={<Parametres />} /> 
        <Route exact path="/repartitionMoyenPaiement" element={<TableauRepartitionMoyenPaiement />} /> 
        <Route exact path="/repartitionMidiSoir" element={<TableauRepartitionMidiSoir />} />
        <Route exact path="/pourboires" element={<TableauPourboire />} />
        <Route exact path="/transactions/:numero_ticket" element={<ShowTransaction />} />
      </CustomRoutes>
      <CustomRoutes noLayout> 
        <Route exact path="/password/:id" element={<ResetPassword />} />
        <Route exact path="/login" element={<LoginPage />} /> 
      </CustomRoutes>
    </Admin>
  );
}

export default App;

