import React from 'react'
import { Select, MenuItem, Typography, Box, Modal } from '@mui/material';
import { PersonOutlineOutlined, LockOutlined, SettingsOutlined } from '@mui/icons-material';
import { useTranslate, useRedirect } from 'react-admin';
import { muiIconStyles } from '../styles/Styles';
import { useSelectAppBarStyles, useMenuItemAppBarStyles } from '../styles/Classes';
import { clearSessionLocalStorage } from '../utils/utils';

const handleChange = (e) => {
  const value = e.target.value;
  if (value === "logout") return;
  
  localStorage.setItem('idRestaurant', value);
  const restaurant = getRestaurantFromLs(value);
  if (restaurant) saveRestoAndGotToHome({ ...restaurant });
  else window.location.href = "/";
};

const renderMenuItem = (restaurant, classesMenuItem) => {
  return <MenuItem classes={classesMenuItem} key={restaurant.id} value={restaurant.id}><PersonOutlineOutlined sx={muiIconStyles.default} /> {restaurant.nom}</MenuItem>;
};

export const ListRestaurants = ({ restaurants, ...rest }) => {
  const classesSelect = useSelectAppBarStyles();
  const classesMenuItem = useMenuItemAppBarStyles();
  const translate = useTranslate();
  const redirect = useRedirect();
  if (!restaurants || (restaurants && restaurants.length === 0)) return null;

  // handle logout
  const handleLogout = (e) => {
    clearSessionLocalStorage();
    window.location.reload();
  };

  const handleSettings = () => {
    redirect('edit', '/settings/account', defaultRestaurant);
  };
  
  const defaultRestaurant = (localStorage.getItem('idRestaurant') === null || localStorage.getItem('idRestaurant') === 'logout')
    ? restaurants[0].id :
    localStorage.getItem('idRestaurant');
  // store restaurants in LS
  saveRestaurants(JSON.stringify(restaurants));

  const items = [<ItemAccount key={"settings"} value={defaultRestaurant} classes={classesMenuItem} onClick={handleSettings} t={translate} />];
  items.push(restaurants.map(restaurant => renderMenuItem(restaurant, classesMenuItem)));
  items.push(<ItemLogout key={"logout"} value="logout" classes={classesMenuItem} onClick={handleLogout} t={translate} />);

  return (
    <div>
      <Select classes={classesSelect} onChange={handleChange} defaultValue={defaultRestaurant} {...rest} className="selectResto">
        {items}
      </Select>
    </div>
  );
};

const ItemAccount = ({ t, value, classes, onClick, key }) => (<MenuItem key={key} value={value} classes={classes} onClick={onClick} >
  <SettingsOutlined sx={muiIconStyles.primary} />
  {t('COMMON.ACCOUNT_SETTINGS')}
</MenuItem>);

const ItemLogout = ({ t, value, classes, onClick, key }) => (<MenuItem key={key} value={value} classes={classes} onClick={onClick} >
  <LockOutlined sx={muiIconStyles.primary} />
  {t('ACTIONS.LOGOUT')}
</MenuItem>);

export const ListeResataurantsModal = (props) => {
  const translate = useTranslate();
  const { restaurants, onClose, open } = props;
  const classesMenuItem = useMenuItemAppBarStyles();

  const handleChangeRestaurant = (data) => {
    saveRestoAndGotToHome(data);
  };
  // handle logout
  const handleLogout = () => { onClose(); clearSessionLocalStorage(); window.location.reload(); }
  const styleModal = {
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    fontFamily: "DM Sans",
    margin: { xs: "25% 25px 0 25px", sm: "25% 20% 0 20%" },
    padding: '20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };

  return <Modal open={open} onClose={onClose} {...props} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Box sx={styleModal}>
      {(restaurants)
        ? restaurants.map(restaurant => {
          const restaurantActif = (restaurant.id === Number(localStorage.getItem('idRestaurant'))) ? { borderRadius: 20, border: "2px solid rgb(251, 150, 120)", color: "rgb(251, 150, 120)" } : { borderRadius: 20, border: "2px solid white" }
          return <MenuItem sx={restaurantActif} onClick={() => handleChangeRestaurant(restaurant)} classes={classesMenuItem} key={restaurant.id} value={restaurant.id}><PersonOutlineOutlined sx={muiIconStyles.default} /> {restaurant.nom}</MenuItem>
        })
        : null}
      <MenuItem value="logout" classes={classesMenuItem} onClick={handleLogout}><LockOutlined sx={muiIconStyles.primary} />{translate('ACTIONS.LOGOUT')}</MenuItem>
    </Box>
  </Modal>
};

export const ListeRestaurantsByUser = (props) => {
  const translate = useTranslate();
  const { restaurants, onClose, open } = props;
  const classesMenuItem = useMenuItemAppBarStyles();

  const handleChangeRestaurant = (data) => {
    saveRestoAndGotToHome(data);
  };
  // handle logout
  const styleModal = {
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    fontFamily: "DM Sans",
    margin: { xs: "25% auto 0 auto", sm: "25% auto 0 auto", md: "10% auto 0 auto", lg: "10% auto 0 auto" },
    padding: { xs: '20px', sm: '20px', md: '30px', lg: '30px' },
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: { lg: "20%", md: "20%", sm: "60%", xs: "80%" },
    minHeight: '25%'
  };

  return <Modal open={open} onClose={onClose} {...props} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Box sx={styleModal}>
      <Typography variant='h6'>{translate('RESTAURANTS.PLEASE_SELECT')}</Typography>
      <Box mt={3}>
        {(restaurants)
          ? restaurants.map(restaurant => {
            const restaurantActif = (restaurant.id === Number(localStorage.getItem('idRestaurant'))) ? { borderRadius: 20, border: "2px solid rgb(251, 150, 120)", color: "rgb(251, 150, 120)" } : { borderRadius: 20, border: "2px solid white" }
            return <MenuItem sx={restaurantActif} onClick={() => handleChangeRestaurant(restaurant)} classes={classesMenuItem} key={restaurant.id} value={restaurant.id}><PersonOutlineOutlined sx={muiIconStyles.default} /> {restaurant.nom}</MenuItem>
          })
          : null}
      </Box>
    </Box>
  </Modal>
};

const saveRestoAndGotToHome = ({ id, name, hasPayxpert, hasPayplug, hasPaygreen, hasEdenred, country }) => {
  localStorage.setItem('idRestaurant', id);
  localStorage.setItem('nameRestaurant', name);
  localStorage.setItem('country', country);
  localStorage.setItem('hasPayxpert', hasPayxpert);
  localStorage.setItem('hasPayplug', hasPayplug);
  localStorage.setItem('hasPaygreen', hasPaygreen);
  localStorage.setItem('hasEdenred', hasEdenred);

  window.location.href = "/";
};

const saveRestaurants = (list) => {
  localStorage.setItem('restaurants', list);
};

const getRestaurantFromLs = (id) => {
  const data = localStorage.getItem('restaurants');
  if (!data) return null;

  const restaurants = JSON.parse(data);
  if (!restaurants) return null;

  const restaurant = restaurants.find((p) => p.id === id);
  if (!restaurant) return null;

  return restaurant;
};

