import * as React from 'react';

import { useState, useEffect } from "react";
import {
  Button, 
  useTranslate,
  useRefresh,
  useNotify,
  usePermissions,
  useResourceContext,
  useCreate,
  useGetList,
  Loading,
  useRedirect,
  useDelete,
  Confirm,
  useGetOne
} from 'react-admin';
import { Settings, Create, HighlightOff, DragIndicator, Add  }  from '@mui/icons-material';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ListAlt, Cached, Search, InfoOutlined  } from '@mui/icons-material';
import {
    Typography, Modal, Box,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Switch
  } from '@mui/material';
import { muiIconStyles, btnStyles, disabledColors,  modalVerticalBodyStyle, largeVerticalModalStyle } from "../../styles/Styles";
import ChangeActifMenuModal from "./ChangeActifMenuModal";
import { getRoleAndPermissions } from "../../utils/utils";
import ListCellHeader from "../core/ListCellHeader";
import Breadcrumb from '../core/BreadCrumb';
import ListTitle from '../core/ListTitle';
import GridItem from '../../styles/GridItem';
import { CustomTableHead } from '../../utils/ListFilter';
import RestrictionModal from '../Settings/restrictionModal';
import { useLocation } from "react-router-dom";

const CartesList = props => {
    const idRestaurant = localStorage.getItem('idRestaurant');
    const isLoggedInByRoleTech = (localStorage.getItem('loggedInByRole') === 'tech');
    const globalPermissions = usePermissions();
    const resource = useResourceContext();
    const { permissions } = getRoleAndPermissions(globalPermissions.permissions, resource);
    const { state } = useLocation();
    const t = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();
    const redirect = useRedirect();
    const [toggleCarteActif, { isLoading: isLoadingToggleCarteActif, error: errorToggleCarteActif, data: dataToggleCarteActif }] = useCreate();
    const { data: cards, isLoading: isLoadingCards } = useGetList(
        `cartes/${idRestaurant}`,
        {
            sort: { field: 'ordre', order: 'ASC' }
        }
    );
    const [deleteCardHook, { isLoading: isLodingDelete }] = useDelete();
    const [updateOrderCards] = useCreate();
    const { data: restrictions, isLoading: isLoadingRestrictions } = useGetOne(`settings/account`, { id : idRestaurant  });

    const [carte, setCarte] = useState(false)
    const [openModalApercuCarte, setOpenModalApercuCarte] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [currentPDF, setcurrentPDF] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [cardToDelete, setCardToDelete] = useState({});
    const [hasRestrictions, setHasRestrictions] = useState(false);
    const [openModalRestrictions, setOpenModalRestrictions] = useState(true);
    const [forceAccess, setForceAccess] = useState(false || state?.forceAccess);

    const headCells =[
        {
            numeric: false,
            disablePadding: false,
            label: '',
            icon: '',
            align:'left'
        },
        {
          numeric: false,
          disablePadding: false,
          label: t('COMMON.NAME'),
          icon: <ListAlt sx={muiIconStyles.primary} />,
          align:'left'
        },
        {
            numeric: false,
            disablePadding: false,
            label: t('COMMON.ACTIVE'),
            icon: <Cached sx={muiIconStyles.secondary} />,
            align:'left'
        },
        {
            numeric: false,
            disablePadding: false,
            label: t('COMMON.PREVIEW'),
            icon: <InfoOutlined sx={muiIconStyles.success} />,
            align:'left'
        },
        {
            numeric: false,
            disablePadding: false,
            label: t('ACTIONS.EDIT'),
            icon: <Settings sx={muiIconStyles.primary} />,
            align:'left'
        },
        {
            numeric: false,
            disablePadding: false,
            label: t('ACTIONS.DELETE'),
            icon: <Settings sx={muiIconStyles.danger} />,
            align:'left'
        },
      ];

    const handleOpenDelete = (card) => {
        setCardToDelete(card)
        setOpenConfirmDelete(true);
    }
    const handleCloseDelete = () => setOpenConfirmDelete(false);

    useEffect(() => {
    }, [currentPDF, cardToDelete])

    useEffect(() => {
        if(!isLoadingToggleCarteActif && !errorToggleCarteActif && dataToggleCarteActif)
        {
            refresh();
            if(dataToggleCarteActif.desactivated === true)
            notify(`${t('CARDS.DESACTIVATED')}`, { type: 'success' });
            else if(dataToggleCarteActif.activated === true)
            notify(`${t('CARDS.ACTIVATED')}`, { type: 'success' });
        }
    }, [isLoadingToggleCarteActif]);

    useEffect(() => {
        if (!isLoadingRestrictions && restrictions) setHasRestrictions(restrictions?.actif);
      }, [isLoadingRestrictions]);

    const handleCloseModalrestrictions = () => {
        setOpenModalRestrictions(false);
    };

    const handleSubmitModalrestrictions = () => {
        setOpenModalRestrictions(false);
        setForceAccess(true);
    };
      
    if (!permissions) return null;
    if(isLoadingRestrictions) return null;
    if(!forceAccess && hasRestrictions) return <RestrictionModal title={t('MODAL_RESTRICTION.MENU_CARDS_TITLE')} open={openModalRestrictions} onClose={handleCloseModalrestrictions} handleSubmit={handleSubmitModalrestrictions} />;
    if(isLoadingCards) return <Loading />;

    const onDragEnd = (movedItem) => {
        if(isLoggedInByRoleTech) return null;
        const cartes = cards.map((card) => {return {id: card.id, nom: card.nom, order: card.ordre}})
        const movingItem ={id: Number(movedItem.draggableId), order: movedItem.destination.index}
        const reordredCards = reorderCards(cartes, movingItem);
        updateOrderCards(`cartes/${idRestaurant}/reorder`, 
            { data: {reordredCards} },
            {
                mutationMode: 'optimistic',
                onSuccess: () => {
                    refresh();
                }
            }
        );
    }

    const getDraggableItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        // change la couleur du background pour l'élément à déplacer
        background: isDragging ? disabledColors.warning : "white",
      });
    
    const toggleOpenPdfViewModal = (record) => {
        const currentTable = {
            nomCarte: record.nom,
            urlCarte: record.url
        }
        if(!openModalApercuCarte) setcurrentPDF(currentTable)
        else setcurrentPDF(false)
        setOpenModalApercuCarte(!openModalApercuCarte)
    }

    const handleCloseConfirmModal = () => {
        refresh();
        setOpenConfirmModal(false);
    }

    const handleOpenConfirmModal = () => {
        setOpenConfirmModal(true)
    }

    const handleChangeActif = (carte) => {
        setCarte(carte);
        handleOpenConfirmModal();
    }

    const handleConfirmChangeActif = () => {
        handleCloseConfirmModal();
        setCarte(false);
        toggleCarteActif(`cartes/${idRestaurant}/toggle`, { data: { carte } });
    }

    const editCard = (id) => {
        return redirect(`/cartes/:idRestaurant/${id}`);
    }

    const deleteCard = () => {
        const onSuccess = () => {
            handleCloseDelete();
            refresh();
        }
        deleteCardHook(
            'cartes',
            { id: cardToDelete.id , previousData: cardToDelete },
            { onSuccess }
        );
        
    }

    const createCard = () => {
        return redirect('create', `/cartes/:idRestaurant`, false, {}, { record: { nbCards: cards.length } });
    }

    const ViewButton = ({ label, onChange, ...props }) => 
    {
        return <Button
            startIcon={<Search />}
            sx={muiIconStyles.success}
            onClick={onChange}
            label="" {...props}
        />;
    }
    
    return <Box>
        <ModalApercuCarte open={openModalApercuCarte} onClose={toggleOpenPdfViewModal} currentPDF={currentPDF} t={t} />
        <ChangeActifMenuModal isOpen={openConfirmModal} carte={carte} onClose={handleCloseConfirmModal} changeActifMenu={handleConfirmChangeActif} translate={t} />
        <Confirm
            isOpen={openConfirmDelete}
            loading={isLodingDelete}
            title={`${t('CARDS.DELETE')} "${cardToDelete && cardToDelete.nom}"`}
            content={(cardToDelete.actif) ? `${t('CARDS.ACTIF_CARD', { nom : cardToDelete.nom})}  ${t('CARDS.CONFIRM_DELETE')}`  : t('CARDS.CONFIRM_DELETE')}
            onConfirm={deleteCard}
            onClose={handleCloseDelete}
            cancel={'ACTIONS.CANCEL'}
            confirm={'ACTIONS.SUBMIT'}
        />
        <Breadcrumb label={t('CARDS.TITLE')} path={`#/${resource}`}  />
        <ListTitle content={t('CARDS.TITLE')} />
        <GridItem>
            <Button label="COMMON.CARD_ADD" sx={btnStyles.secondary} onClick={createCard} startIcon={<Add />} />
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <TableContainer components={Paper} sx={{overflow:'visible'}}>
                    <Table>
                    <CustomTableHead
                    headCells={headCells}
                    />
                    <Droppable droppableId="droppable">
                    {(provided) => (
                        <TableBody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {cards.map((card,i) => ( 
                                <Draggable key={card.id} draggableId={`${card.id}`} index={i}>
                                    {(provided, snapshot) => ( <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        sx={getDraggableItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                        <TableCell {...provided.dragHandleProps}><DragIndicator color="action" /></TableCell>
                                            <TableCell>{card.nom}</TableCell>
                                            <TableCell><Switch checked={card.actif} onChange={() => handleChangeActif(card)} disabled={isLoggedInByRoleTech} /></TableCell>
                                            <TableCell><ViewButton label={<ListCellHeader icon={<InfoOutlined sx={muiIconStyles.success} />} text={t('COMMON.PREVIEW')} />} onChange={() => toggleOpenPdfViewModal(card)}  /></TableCell>
                                            <TableCell>{permissions.edit && <Button startIcon={<Create />} onClick={() => editCard(card.id)} />}</TableCell>
                                            <TableCell>{permissions.delete && <Button startIcon={<HighlightOff />} color="secondary" t={t} onClick={() => handleOpenDelete(card)} />}</TableCell>
                                        </TableRow>)}
                                </Draggable>
                            )) }
                        </TableBody>
                    )}
                    </Droppable>
                </Table>
            </TableContainer>
        </DragDropContext>
      </GridItem>
    </Box>
}


const ModalApercuCarte = (props) => {
    const content = <iframe id='pdfApercu'
      src={`${props.currentPDF.urlCarte}#toolbar=0&navpanes=0&scrollbar=0&page=1&view=fitV`}
      height="100%"
      width="80%"
       />;
  
    return <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <Box sx={largeVerticalModalStyle}>
        <Box sx={modalVerticalBodyStyle}>
          <Typography variant="h6" textAlign='center' style={{marginBottom: '20px'}}>{`${props.t('CARDS.NAME')} : ${props.currentPDF.nomCarte}`}</Typography>
          {content}
        </Box>
      </Box>
    </Modal>
  };


  const reorderCards = (originalArray, obj) => {
    // Trouver l'élément correspondant à object.id dans le tableau original
    const index = originalArray.findIndex(item => item.id === obj.id);
    // Si l'objet n'est pas trouvé retouner le tableau original
    if (index === -1) {
      return originalArray;
    }
  
    // Retirer l'objet déplcé du tableau original
    const removedObj = originalArray.splice(index, 1)[0];
  
    // Puis l'insérer dans le nouvel index
    originalArray.splice(obj.order, 0, removedObj);
  
    return originalArray.map((c,i) => {return {id:c.id, nom: c.nom, order:i}});
  }

export default CartesList;