import raMessages from 'ra-language-french';

const ra = {
  ...raMessages.ra,
  action: {
    ...raMessages.ra.action,
    confirm: 'Confirmer',
  },
  navigation: {
    ...raMessages.navigation,
    page_range_info: '%{offsetBegin}-%{offsetEnd} sur %{total}',
    next: 'Suivant',
    prev: 'Précédent',
    no_results: 'Aucun résultat',
    page_rows_per_page: 'Lignes par page :',
  }
};

const customFrenchMessages = {
  ra,
  ACTIONS: {
    ACTIVATE: 'activer',
    CANCEL: 'Annuler',
    CREATE: 'Créer',
    DELETE: 'Supprimer',
    DESACTIVATE: 'désactiver',
    DOWNLOAD: "Télécharger",
    EDIT: 'Éditer',
    LOGIN: 'Se connecter',
    LOGOUT: 'Déconnexion',
    REFUND: "Rembourser",
    RENEW: 'Réinitialiser',
    SAVE: 'Enregistrer',
    SEARCH: "Rechercher",
    SEND: 'Envoyer',
    SUBMIT: "Valider",
  },
  COMMON: {
    ACCOUNT_ACTIVATE: "Activer le compte",
    ACCOUNT_DESACTIVATE: "Désactiver le compte",
    ACCOUNT_SETTINGS: 'Paramètres du compte',
    ACTIVE: "actif",
    ADDRESS: "Adresse",
    AGENCY: 'Agence',
    AGENCY_SELECT: 'Sélectionnez une agence',
    AMOUNT: "Montant",
    AMOUNT_TTC: "Montant TTC",
    AVERAGE_TICKET: "Ticket moyen",
    BANK_CARD: "Carte Bancaire",
    BANK_CARD_SHORT: "CB",
    CARD_ADD: 'Ajouter une carte',
    CITY: "Ville",
    CLICK_TO_CHANGE_FILE: "Cliquez pour changer le fichier",
    CLICK_TO_SELECT_FILE: "Cliquez pour sélectionner un ficher (.pdf)",
    CLIENT_CODE: 'Code client',
    CLIENT_WISH_USE_PAYGREEN: "Le client souhaite t'il utiliser les tickets restaurants ?",
    CODE: "Code",
    COMMAND_ID: "ID Commande",
    COMMENT: "Commentaire",
    CONFIG_SAVED: "Configuration enregistrée",
    CONFIG_SAVED_FAILED: "Configuration non enregistrée, une erreur s'est produite",
    DASHBOARD: 'Tableau de bord',
    DATE: 'Date',
    DATE_AUTHORIZATION: "Date autorisation",
    DATE_CAPTURE: "Date capture",
    DATE_CREATED: "Date de création",
    DEVICE_USED: "Appareil utilisé",
    DISABLED: 'désactivé',
    DISTRIBUTION_BY_MOYEN: "Répartition par moyen de paiement",
    DISTRIBUTION_BY_TIME: "Répartition Midi/Soir",
    DISTRIBUTION_BY_TYPE: "Répartition par type de paiement",
    EDENRED_ID: "Identifiant Edenred",
    EDIT_DATE: 'Modification',
    EMAIL_ADDRESS: 'Adresse e-mail',
    EMAIL_WILL_BE_SENT_ADDRESS: "Un e-mail va être envoyé à l'adresse",
    END: "Fin",
    ERROR_LOGIN_FAIL: "Email ou pass invalides, ou compte désactivé",
    ERROR_NOT_CREATED: "non créée, une erreur s'est produite",
    ERROR_PASSWORD_RESET: "Un problème est survenu, merci de renouveler votre demande de nouveau mot de passe",
    ETABLISHMENT_BUSINESS_NAME: "Raison sociale de l'établissement",
    ETABLISHMENT_NAME: 'Nom Etablissement',
    FEAT_CHARGEABLE_SEND_MAIL_TO: "Cette fonctionnalité est payante. Pour en profiter, veuillez envoyer un email à ",
    FIRSTNAME: 'Prénom',
    FURTHER_INFORMATIONS: "Informations complémentaires",
    HOME: 'Accueil',
    INACTIVE: 'inactif',
    ITEMS: "Articles",
    INFORMATIONS: "Informations",
    KEY: "Clé",
    KEY_API: "Clé api",
    KEY_PUBLIC: "Clé publique",
    KEY_SECRET: "Clé secrète",
    LINKED_TRANSACTIONS: "Transactions associées",
    MENU_NAME: "Nom menu",
    MESSAGE: 'Message',
    NAME: 'Nom',
    NIGHT: 'SOIR',
    NO: "Non",
    NO_DATA_ON_RANGE: 'AUCUNE DONNEE POUR LES DATES CHOISIES',
    NOON: 'MIDI',
    NOT_SUBSCRIBE: "Pas abonné",
    NP_SCORE: "NP Score",
    NUMBER: "Nombre",
    OPERATION: "Opération",
    OTHER: 'Autre',
    PART: 'PART',
    PASSWORD: 'Mot de passe',
    PASSWORD_CONFIRM: 'Confirmer mot de passe',
    PASSWORD_FORGOT: 'Mot de passe oublié',
    PASSWORD_NEW: "Nouveau mot de passe",
    PASSWORD_RENEW: 'Réinitialiser le mot de passe',
    PASSWORD_UPDATED: "Votre mot de passe à été modifé",
    PAYGREEN_ID: "Identifiant PayGreen",
    PAYMENT_INFO: "Infos paiement",
    PAYMENT_METHOD: "MOYEN DE PAIEMENT",
    PAYMENT_TITLE_SHORT: "TRD",
    PAYMENT_TYPE: "Type paiement",
    PAYMENTS_ID: "Identifiants de paiements",
    PAYPLUG_ID: "Identifiant Payplug",
    PAYXPERT_ID: "Identifiant PayXpert",
    PERCENTAGE: "Pourcentage",
    PERIOD: "Période",
    PREVIEW: 'Aperçu',
    PREVIEW_OF: 'Aperçu du',
    QRCODE_ADD: 'Ajouter un QRCode',
    REFUNDED_AMOUNT: "Montant remboursé",
    NOT_DEFINED_DATE: "- Date non défini -",
    REFUNDS: "Remboursement",
    REFUNDS_ABR: "Remb.",
    RESET_PASSWORD_REQUEST: 'Demande de réinitialisation de mot de passe',
    RESTAURANT_CREATOR: "Créateur du restaurant",
    RESTAURANT_TICKET: "Ticket Restaurant",
    ROLE: 'Rôle',
    SELLER: "Vendeur",
    SERVICE: 'Service',
    SHOP_ID: "Id boutique",
    SIGNATORY_EMAIL: "Adresse e-mail du signataire",
    SIGNATORY_FULLNAME: "Nom et prénom du signataire",
    SIGNATORY_MOBILE_PHONE: "Numéro de téléphone portable du signataire",
    SIRET_NUM: 'N° de siret',
    START: "Début",
    STATUS: 'Statut',
    STATUS_ACTIVE: 'activé(e)',
    STATUS_CREATED: 'créé(e)',
    STATUS_INACTIVE: 'désactivé(e)',
    STATUS_NOT_USED: 'Non utilisé',
    STATUS_PENDING: 'en cours',
    STATUS_REFUNDED: 'Remboursé(e)',
    STATUS_REFUNDED_PARTIAL: "Remboursée partiellement",
    STATUS_REFUSED: "Refusée",
    STATUS_SUCCESS: 'Succès',
    SUBMIT_RESET_PASSWORD: 'Envoyer la demande',
    TABLE: 'Table',
    TABLE_NUM: "N°Table",
    TABLE_NUM_FULL: 'Numéro de table',
    TABLE_NUM_QR_CODE: 'Numéro de table associé au QRCode',
    TICKET_DATE: 'Date ticket',
    TICKET_NUM: "N°Ticket",
    TILL_ID: "Identifiant Caisse",
    TILL_NAME: 'Caisse',
    TIPS: "Pourboires",
    TIPS_ACTIVE: "Pourboire actif",
    TO_CONTINUE: "Voulez-vous continuer ?",
    TODAY: "Aujourd'hui",
    TRANSACTION: "Transaction",
    TRANSACTION_DETAILS: 'Détails de la transaction',
    TRANSACTION_ID: "ID Transaction",
    TRANSACTION_NUM: "N°Transaction",
    TRANSACTIONS_ALL: "Toutes les transactions",
    TRANSACTIONS_EDENRED: 'Transactions Edenred',
    TRANSACTIONS_PAYGREEN: 'Transactions PayGreen',
    TRANSACTIONS_PAYPLUG: 'Transactions PayPlug',
    TRANSACTIONS_PAYXPERT: 'Transactions PayXpert',
    TURNOVER: "Chiffre d'affaires",
    TYPE_EMAIL: 'Saisissez votre adresse e-mail',
    VALUE: "Valeur",
    YES: "Oui",
    YOU_ARE: 'Vous êtes ?',
    ZIP_CODE: "Code postal",
    GENERAL_ERROR: "Une erreur s'est produite"
  },
  CARDS: {
    ACTIVATED: 'Carte activée',
    CONFIRM_ACTIVATE: "Etes vous sûr de vouloir %{action} la carte ?",
    ACTIF_CARD: 'La carte %{nom} est active.',
    CONFIRM_DELETE: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
    DELETE: 'Supprimer la carte',
    DESACTIVATED: 'Carte désactivée',
    NAME: 'Carte',
    TITLE: 'Gestion des menus',
    TITLE_CREATE: 'Créer une carte',
    TITLE_EDIT: 'Modifier une carte',
    WARNING_OTHER_CARDS_DESACTIVATE: 'Attention: Tous les autres cartes seront désactivées automatiquement',
  },
  DASHBOARD: {
    HEADING: 'pensez à créer et imprimer vos QR Codes !',
    TITLE: 'MES ACTIVITÉS',
    TIPS:{
      CSV_EXPORT_BTN: "Export CSV",
      CSV_EXPORT_FILENAME: "Pourboires_%{start}_%{end}",
      CSV_EXPORT_PERIOD: "pour la période : %{start} au %{end}",
      CSV_EXPORT_TITLE: "Export CSV des pourboires",
      TOTAL: "Total"
    },
    QRCODES_BUTTON: 'Je crée mes QrCodes !',
  },
  MODAL_BO_ACCESS: {
    MESSAGE: 'Confirmer la connexion au Back-Office du restaurant',
    TITLE: 'Accès BO impersonnel'
  },
  MODAL_ONBOARDING: {
    MESSAGE: 'Avez-vous bien envoyé une demande d’onboarding à PayGreen pour le compte %{nom} ?',
    TITLE: 'Demande Onboarding PayGreen'
  },
  MODAL_REMINDER: {
    MESSAGE: 'Avez-vous relancé le client %{nom} ?',
    TITLE: '%{service} - À relancer'
  },
  MODAL_RESTRICTION: {
    QRCODE_CREATE_TITLE: 'Saisir votre code pour ajouter un QrCode',
    QRCODE_EDIT_TITLE: 'Saisir votre code pour modifier un QrCode',
    TIPS_TITLE: 'Saisir votre code pour accéder à la gestion des pourboires',
    RESTRICT_TITLE: 'Saisir votre code pour désactiver les restrictions',
    REFUND_TITLE: 'Saisir votre code pour faire un remboursement',
    MENU_CARDS_TITLE: 'Saisir votre code pour accéder à la gestion des menus',
  },
  QRCODE: {
    CLOSED: 'Fermée',
    DELETE: 'Supprimer le QRCode',
    DELETE_CONFIRMATION: 'Êtes-vous sûr de vouloir supprimer ce QRCode ?',
    DOWNLOAD: 'Téléchargement des QR Codes',
    DOWNLOAD_ALL: 'Télécharger tous les QrCodes',
    DOWNLOAD_SELECTED: 'Télécharger les QrCodes sélectionnés',
    ERRORS: {
      DOWNLOAD_FAIL: 'Erreur de téléchargement des QR Codes',
      GENERATE_FAIL: 'Erreur génération du QR Code'
    },
    MODAL_TITLE: 'QRCode de la table',
    NAME: 'QRCode',
    OPENNED: 'Ouverte',
    TEMPLATES: {
      A4: {
        DESCRIPTION: "Cet export en PDF génère vos fichiers au format A4 vous permettant une impression sur votre imprimante de bureau. Vous n'avez plus qu'à découper vos QR codes et les plastifier si vous le souhaitez !",
        TITLE: 'FORMAT A4'
      },
      CARD: {
        DESCRIPTION: "Cet export en PDF génère vos fichiers au format « carte de visite » soit 85X55 mm (taille standard) permettant une impression chez votre imprimeur local.",
        TITLE: 'FORMAT CARTE DE VISITE'
      },
      PLEXI: {
        DESCRIPTION: "Cet export en PDF génère vos fichiers au format 6x6 cm pour l'impression du QR code sur du Vinyle blanc qui est collé sur un Plexiglass de 3mm d'épaisseur.",
        TITLE: 'FORMAT SUPPORT PLEXI'
      },
      QRCODE_FOR_STICKER: {
        TITLE: "FORMAT QRCODE POUR ETIQUETTES"
      }
    },
    TITLE: 'Gestion des QRCodes',
    TITLE_CREATE: 'Créer un QRCode',
    TITLE_EDIT: 'Modifier QRCode',
    UNIQ_LINK: 'QRCode du lien unique de la table',
  },
  RESTAURANTS: {
    ADD_NEW: 'Ajouter un nouveau restaurant',
    AUTHENT_DATA: "Données d'authentification",
    BO_ACCESS: 'Accès BO',
    DO_SEND_ID: 'Etes-vous sûr de vouloir renvoyer les identifiants ?',
    EMAIL_ASSOCIATED: "Adresse mail associée",
    EMAIL_SENT_TO: "Un email a été envoyé à l'adresse",
    INFOS: 'Informations du restaurant',
    NAME: 'Restaurant',
    NUM: 'Numéro du restaurant',
    ONBOARDING_DATE: 'Date demande onboarding',
    PLEASE_SELECT: "Veuillez sélectionner un établissement",
    SEND_IDS: 'Renvoyer les identifiants',
    STATUS_ACTIVE: 'Restaurants actifs',
    STATUS_ENABLED: 'Restaurants activés',
    STATUS_DISABLED: 'Restaurants Désactivés',
    STATUS_INACTIVE: 'Restaurants Inactifs',
    TILL_TYPE_SELECT: 'Sélectionnez le type de caisse',
    TITLE_CREATE: 'Nouveau restaurant',
    TITLE_EDIT: 'Modifier restaurant',
  },
  ROLES: {
    ADMIN: 'Administrateur',
    MANAGER: 'Responsable',
    TECH: 'Technicien'
  },
  SCORES: {
    AVERAGE: 'Note moyenne',
    NOTE: 'Note',
    OS: 'OS',
    VERSION: 'Version de l\'app',
  },
  SETTINGS: {
    ACCOUNT: {
      BTN_RETREIVE_CODE: 'Récupérer le mot de passe',
      MESSAGE: '** Un code sera demandé pour réaliser les remboursements et accéder à la configuration des pourboires, des QrCodes et des menus',
      FIELD_PIN: 'Saisir le code de restriction',
      FIELD_CONFIRM_PIN: 'Confirmer le code de restriction',
      SET_CODE_FAIL: 'Le code de restriction est incorrect',
      SET_CODE_SUCCESS: 'Code correct, vous pouvez maintenant désactiver les restrictions',
      TOGGLE_RESTRICTIONS: 'Activer les restrictions',
      RESTRICTIONS: 'Restrictions'
    },
    STATEMENT: {
      TRANSACTIONS_STATEMENT: 'Relevé des transactions',
      TOGGLE_TR_STATEMENT: 'Activer le relevé des transactions',
      DAILY: 'Quotidienne',
      WEEKLY: 'Hebdomadaire',
      MONTHLY: 'Mensuelle',
      ANNUAL: 'Annuelle',
      MESSAGE: '**Le relevé des transactions sera envoyé à l\'adresse e-mail associée au compte Banana Split'
    }
  },
  SIDEBAR: {
    CONSULTING: 'Consultations',
    CREATE: 'Création',
    CREATE_ADMIN_USER: 'Créer Utilisateur Admin',
    CREATE_RESTAURANT: 'Créer Restaurant',
    MANAGEMENT: 'Gestion',
    MENU_CARDS: 'Cartes menus',
    MENU_SETTINGS: 'Gestion des menus',
    QRCODES: 'QRCodes',
    RESTAURANTS: 'Restaurants',
    SETTINGS: 'Configurations',
    STATISTIQUES: 'Statistiques',
    USERS: 'Utilisateurs',
  },
  TIPS: {
    ACTIVATE: "Activer les pourboires",
    DEFAULT_SETTINGS: "Personnaliser les pourboires par défaut",
    TITLE: "Gestion Pourboires",
    TRACKING: "Suivi des pourboires",
    TYPE_USED: "Type de pourboires utilisés",
    VALIDATION_MESSAGE: "Le pourboire doit être supérieur à zéro et ne dépasse pas 2 chiffres après la virgule",
  },
  TRANSACTIONS: {
    AMOUNT_REFUND: "Montant à rembourser",
    BLOCKED_REFUNDS_REASONS: {
      NOT_REFUNDED_AFTER_CAPTURE: 'Les transactions capturées avec CONECS ne sont pas remboursables',
      REFUNDED_ONCE: 'Une part de %{refundedAmount} à déjà été remboursée. Les transactions passées avec CONECS sont remboursables une seule fois',
      REFUND_IMPOSSIBLE: 'Remboursement impossible'
    },
    CONFIRM_REFUND: "Etes vous sûre de vouloir rembourser %{amountToRefund} ?",
    DETAILS: "Détails des transactions",
    ONBOARDING_REQUEST: 'Demande OnBoarding',
    PARTIAL_REFUND_DETAILS: 'Une part de %{sumRefunds} à déjà été remboursée %{complementTextRefund}, il reste %{restToRefund} à rembourser',
    REFUND: "Remboursement Transaction",
    REFUND_CONFIRM: {
      MESSAGE1: "Confirmer le remboursement de",
      MESSAGE2: "de la transaction",
      WARNING_UNIQ: "Attention: Vous ne pouvez faire qu'un seul remboursement sur cette transaction",
      WARNING_TOTAL: "Attention: Vous ne pouvez rembourser que la totalité de la transaction avec Edenred",
    },
    REFUND_FAIL: "Le remboursement n'est pas effectué",
    REFUND_NOT_POSSIBLE: "La transaction ne peut pas être remboursée",
    REFUND_NOT_POSSIBLE_ANYMORE: "La transaction ne peut plus être remboursée",
    REFUND_PARTIAL: "Rembourser une partie de la transaction",
    REFUND_SUCCESS: "Le remboursement a été effectué",
    REFUND_TOTAL: "Rembourser la totalité de la transaction",
    REFUNDED: "Remboursée",
    REFUNDED_TOTAL: "La totalité de la transaction a été remboursée.",
    REMINDER_REQUEST: "Relance",
    REST_TO_REFUND: "Reste à rembourser %{restToRefund}",
    TITLE: "Mes transactions",
  },
  USERS: {
    ACCOUNT_ACTIVATED: 'le compte %{account} a été activé',
    ACCOUNT_DESACTIVATED: 'le compte %{account} a été désactivé',
    ACCOUNT_NOMORE_SHOW: 'Ne plus afficher le compte dans la liste',
    CONFIRM_ACTION_ACCOUNT: 'Etes vous sûr de vouloir %{action} le compte ?',
    NAME: 'Utilisateur',
    TITLE: 'Créer utilisateur',
    TITLE_CREATE: 'Nouvel utilisateur',
    TITLE_EDIT: 'Modifier utilisateur',
  },
  VALIDATION: {
    EXISTS: {
      CLIENT_CODE: "Ce code client existe déjà",
      EMAIL: "Cette adresse e-mail est déjà utilisée",
      NAME: "Ce nom existe déjà",
      TABLE_NUM: "Ce numéro de table existe déjà.",
    },
    MANDATORY: {
      ADDRESS: "L'adresse est obligatoire.",
      AGENCY: "L'agence est obligatoire",
      API_KEY: "La clé d'api de PayXpert est obligatoire",
      API_KEY_PAYPLUG: "La clé d'api de Payplug est obligatoire",
      BUSINESS_NAME: "La raison sociale est obligatoire.",
      CITY: "La ville est obligatoire.",
      CLIENT_NAME: "L'identité du client est obligatoire.",
      EMAIL: "L'adresse e-mail est obligatoire.",
      FIRSTNAME: "Le prenom est obligatoire.",
      MID: "Le MID de Edenred est obligatoire",
      NAME: "Le nom est obligatoire.",
      PASSWORD: "Le mot de passe est obligatoire",
      PASSWORD_CONFIRM: "La confirmation du mot de passe est obligatoire",
      PAYXPERT_ID: "L'identifiant PayXpert est obligatoire",
      PHONE: "`Le numéro de téléphone est obligatoire.",
      PRIVATE_KEY: "La clé privée de PayGreen est obligatoire",
      PUBLIC_KEY: "La clé publique de PayGreen est obligatoire",
      RESTRICTION_CODE: 'Un code PIN est obligatoire',
      ROLE: 'Le rôle est obligatoire.',
      SECRET_KEY: "La clé secrète de PayGreen est obligatoire",
      SIRET: "Le numéro de SIRET est obligatoire.",
      SHOP_ID: "L'id de la boutique PayGreen est obligatoire",
      TABLE_NUM: "Le numéro de table est obligatoire.",
      TILL: "La caisse est obligatoire.",
      ZIP_CODE: "Le code postal est obligatoire.",
      STATEMENT_PERIOD: "La période est obligatoire"
    },
    SELECT: {
      ROLE: "Veuillez choisir un rôle",
    },
    TYPE: {
      BUSINESS_NAME: "La raison sociale doit contenir au maximum 100 caractères.",
      CLIENT_CODE: "Le code client doit contenir uniquement des chiffres",
      CLIENT_NAME: "L'identité du client ne doit contenir que des lettres",
      CLIENT_NAME_MAX: "L'identité du client doit contenir au maximum 100 caractères.",
      EMAIL: "L'adresse e-mail doit être de type email.",
      FIRSTNAME: "Le prénom doit contenir au maximum 50 caractères.",
      NAME: "Le nom doit contenir au maximum 50 caractères.",
      PASSWORD_DIFFERENT: "Le mot de passe est différent",
      PASSWORD_LENGHT: "compris entre 6 et 50 caractères",
      PASSWORD_MIN: "Le mot de passe doit contenir au moins 6 caractères",
      PASSWORD_MAX: "Le mot de passe ne doit pas dépasser 50 caractères",
      PHONE: "Le numéro de téléphone ne doit contenir que des chiffres.",
      RESTRICTION_CODE_CONFIRM: 'Le champ de confirmation est différent du code',
      RESTRICTION_CODE_LENGTH: 'Le code PIN doit contenir 4 chiffre',
      RESTRICTION_CODE_PATTERN: 'Le code PIN ne doit contenir que des chiffres',
      SIRET: "Le numéro de SIRET doit contenir au maximum 14 chiffres.",
      TABLE_NUM: "Le numéro de table doit contenir uniquement des chiffres.",
      TABLE_NUM_MAX: "Le numéro de table doit contenir au maximum 4 chiffres.",
      TIPS: "Le pourboire doit être supérieur à 0"
    }
  },
};

export default customFrenchMessages;