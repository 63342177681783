import TransactionsIcon from '@mui/icons-material/MultilineChartSharp';
import TransactionsList from './List';


const Transactions = {
  icon: TransactionsIcon,
  name: `transactions/liste/:idRestaurant`,
  list: TransactionsList,
};

export default Transactions;