import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const GridItem = styled(Paper)((props) => {
    const { theme, position, nomargin, noborderradius, nopadding, minMarginBottom, maxmargin, nobg, nomarginbottom } = props;
    const marginLeft = (position === 'left' || nomargin === 'true') ? 0 : (maxmargin === "true") ? 150 : 10;
    const marginRight = (position === 'right' || nomargin === 'true') ? 0 : (maxmargin === "true") ? 150 : 10;
    return ({
    ...theme.typography.body2,
    padding: (nopadding === "true") ? 0 : 30 ,
    textAlign: 'left',
    color: theme.palette.text.secondary,
    borderRadius: (noborderradius === "true") ? 0 : 20,
    boxShadow: (nobg === "true") ? 'none' : 'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
    marginTop:0,
    marginBottom:(nomarginbottom === "true") ? 0 : (minMarginBottom) ? 30 : 50,
    marginLeft:marginLeft,
    marginRight:marginRight,
    background: (nobg === "true") ? '#fafafa' : 'white'
  })});

export default GridItem;