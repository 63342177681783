import * as React from "react";
import { Show } from 'react-admin';
import ReadContent from "./ReadContent";


const RestaurantShow = ({ permissions, ...props }) => {
  
  return (
    <Show {...props}>
      <ReadContent />
    </Show>
  );
};

export default RestaurantShow;