import { Typography, Modal, Box, Link } from '@mui/material';
import { smallModalStyle } from "../../styles/Styles";
const QRCode = require('qrcode');

const QrCodesModal = ({ open, onClose, url, id_table, t }) => {
  const content = getQrcode(url, t);
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{...smallModalStyle, padding: 4}}>
        <Typography variant="h5" textAlign="center">{`${t('QRCODE.MODAL_TITLE')} ${id_table}`}</Typography>
        {content}
        <Link href={url} target="_blank">{url}</Link>
      </Box>
    </Modal>
  );
};

const getQrcode = (url, t) => {
  let qrcode = '';
  QRCode.toDataURL(url, (err, data) => {
    if (err) qrcode = <Typography variant="h5" textAlign='center'>{t('QRCODE.ERRORS.GENERATE_FAIL')}</Typography>;
    qrcode = <img id='qrcode'
      src={data}
      alt={t('QRCODE.UNIQ_LINK')}
      width="35%"
      height="35%" />;
  });

  return qrcode;
};

export default QrCodesModal;