import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ConfirmResetPassword = (props) => (
  <Dialog
    open={true}
    onClose={() => props.handleShowModal(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{props.translate('COMMON.RESET_PASSWORD_REQUEST')}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {props.translate('COMMON.EMAIL_WILL_BE_SENT_ADDRESS')} <b>{props.email}</b>.
        <p>{props.translate('COMMON.TO_CONTINUE')}</p>
      </DialogContentText>
      <DialogActions>
        <Button onClick={() => props.handleShowModal(false)}>
          {props.translate('ACTIONS.CANCEL')}
        </Button>
        <Button color="primary" onClick={props.handleSubmit}>
          {props.translate('COMMON.SUBMIT_RESET_PASSWORD')}
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export default ConfirmResetPassword;