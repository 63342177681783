import * as React from "react";
import { Show, useTranslate } from 'react-admin';
import { Box, Grid } from '@mui/material';
import Breadcrumb from './BreadCrumb';
import ListTitle from './ListTitle';
import GridItem from '../../styles/GridItem';


const ShowComponent = props => {
  const t = useTranslate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Breadcrumb label={t(props.options.label)} path={`#${props.basePath}`} child={props.breadcrumb} />
      <ListTitle content={props.title} />
      <Grid container spacing={2} >
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <GridItem position="middle">
            <Show {...props} >
              {props.children}
            </Show>
          </GridItem>
        </Grid>
      </Grid>
      <Box style={{ height: 300 }} />
    </Box>
  );
};

export default ShowComponent;