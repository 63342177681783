import * as React from "react";
import { TextInput } from 'react-admin';
import { CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';
import { Checkbox as MuiCheckbox, Autocomplete } from "@mui/material";

const MultipleSelectInput = ({onSelectChange, selectValues, alwaysOn, multiple, id, key, options, optionLabel, limit, optionValue, onCheckBoxChange, optionText, inputSource, inputLabel }) => {

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    return <Autocomplete
      onChange={onSelectChange}
      value={selectValues}
      alwaysOn={alwaysOn}
      multiple={multiple}
      id={id}
      key={key}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option[optionLabel]}
      limitTags={limit}
      renderOption={(props, option, state) => { 
        return <li {...props} 
        onClick={(event) => {props.onClick(event); onCheckBoxChange(option.id);} }
        >
          <MuiCheckbox
            value={option[optionValue]}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selectValues.findIndex(agency => agency.id === option.id) >= 0 ? true : false}
            onChange={(event) => onCheckBoxChange(event.target.value)}
          />
          {option[optionText]}
        </li>
      }}
      style={{ width: 280 }}
      renderInput={(params) => (
        <TextInput {...params} source={inputSource} value={selectValues} label={inputLabel} />
      )}
    />
  }

export default MultipleSelectInput;