import { useGetList } from 'react-admin';
import { dashboardStyles } from '../../styles/Styles';
import { Typography, Box, Grid, IconButton } from '@mui/material';
import {
  PersonOutline,
  PersonAdd,
} from '@mui/icons-material';

const ContentAdmin = props => {
  const agence = JSON.parse(localStorage.getItem('agence'))
  const filterData = (agence) ? (agence.slug === 'all') ? {} : { filter: { id_agence: agence.id } } : {};
  const { data, isLoading } = useGetList('restaurants', filterData);
  if (isLoading) return null;

  const activities = computeClientsStatus(Object.values(data));
  if (!activities) return null;

  const cardActives = (nb) => (
    <Grid item lg={4} md={4} sm={6} xs={6} sx={dashboardStyles.groupCardItem}>
      <Box>
        <IconButton size="large" edge="start" color="primary">
          <PersonOutline />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="h5">{nb}</Typography>
        <Typography variant="subtitle1">{props.t('RESTAURANTS.STATUS_ACTIVE')}</Typography>
      </Box>
    </Grid>
  );
  const cardEnCours = (nb) => (
    <Grid item lg={4} md={4} sm={6} xs={6} sx={dashboardStyles.groupCardItem}>
      <Box>
        <IconButton size="large" edge="start" color="warning">
          <PersonOutline />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="h5">{nb}</Typography>
        <Typography variant="subtitle1">{props.t('RESTAURANTS.STATUS_INACTIVE')}</Typography>
      </Box>
    </Grid>
  );
  const cardDesactives = (nb) => (
    <Grid item lg={4} md={4} sm={6} xs={6} sx={{...dashboardStyles.groupCardItem, border: 'none'}}>
      <Box>
        <IconButton size="large" edge="start" color="error">
          <PersonOutline />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="h5">{nb}</Typography>
        <Typography variant="subtitle1">{props.t('RESTAURANTS.STATUS_DISABLED')}</Typography>
      </Box>
    </Grid>
  );
  // const cardBloques = (nb) => (
  //   <Grid item lg={3} md={3} sm={6} xs={6} sx={dashboardStyles.groupCardItem}>
  //     <Box>
  //       <IconButton size="large" edge="start" color="warning">
  //         <EuroOutlined />
  //       </IconButton>
  //     </Box>
  //     <Box>
  //       <Typography variant="h5">{nb}</Typography>
  //       <Typography variant="subtitle1">Bloqués</Typography>
  //     </Box>
  //   </Grid>
  // );

  return (
    <Grid item container spacing={0} lg={12} md={12} sx={dashboardStyles.groupCardContainer}>
      {cardActives(activities['Actifs'] || 0)}
      {cardEnCours(activities['Inactifs'] || 0)}
      {cardDesactives(activities['Desactives'] || 0)}
    </Grid>
  );
};

/**
 * Retourne le nombre de statuts des restaurants (Activés | En cours | Inactifs)
 * 
 * @param {Array} data : liste des restaurants
 * @returns Object
 */
const computeClientsStatus = (data) => {
  const allStatus = data.map(p => {
    const edenred = etatServices(p.identifiant_edenred, 'edenred');
    const paygreen = etatServices(p.identifiant_paygreen, 'paygreen');
    const payxpert = etatServices(p.identifiant_payxpert, 'payxpert');
    const payplug = etatServices(p.identifiant_payplug, 'payplug');
    const listServices = [
      edenred,
      paygreen,
      payxpert,
      payplug,
    ];
    const nbServicesActifs = listServices.filter(p => p.isActive).length;

    return (p.compte_actif === true)
      ? (nbServicesActifs >= 1)
        ? 'Actifs'
        : 'Inactifs'
      : 'Desactives';
  });

  return allStatus.reduce((prev, item) => {
    if (!prev[item]) prev[item] = 0;
    prev[item] += 1;

    return prev;
  }, {});
};
/**
 * Retourne l'identifiant d'un service ainsi que son statut
 * Si le param service vaut null, le service n'est pas utilisé
 * S'il vaut {}, utilisé mais non renseigné, donc inactif
 * 
 * @param {Object} service : identifiant du service
 * @returns {Object}
 */
const etatServices = (service, nomService) => {
  if (!service) return { isActive: false, isReady: false };

  const isActive = (nomService === 'edenred')
    ? (Object.keys(service).length === 0) ? false : true
    : (Object.keys(service).length === 0 || (service && service.actif === false)) ? false : true;;
  const isReady = (Object.keys(service).length === 0)
    ? false :
    (!service.mid && !service.shop_id && !service.id)
      ? false
      : true;
  const id = (isReady) ? service.mid || service.shop_id || service.id : false;

  return { isActive, isReady, id };
};

export default ContentAdmin;