import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRefresh, useCreate } from 'react-admin';
import { Box, Stack, TableBody, TableContainer, TableRow, Typography, TableCell, Table, Chip, Button, Divider, InputAdornment, 
  FormControl, OutlinedInput, SwipeableDrawer, Tooltip, Tabs, Tab, tooltipClasses, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { withStyles, styled } from '@mui/styles';
import { formatNumber, formatDateWithSeconds } from "../../utils/utils";
import { InfoOutlined, EuroOutlined } from '@mui/icons-material';
import { muiIconStyles, btnStyles } from '../../styles/Styles';
import PropTypes from 'prop-types';
import RestrictionModal from '../Settings/restrictionModal';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.danger.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.danger.main,
    color: 'white',
    fontSize: '0.875rem',
    borderRadius: 5,
    borderColor: 'black'
  },
}));

const getPaymentId = (tr) => {
  const {  service,  payxpert_id, paygreen_id, payplug_id, edenred_capture_id } = tr;
  let paymentId = '';
  switch(service) {
    case 'PayXpert':
      paymentId = payxpert_id;
      break;
    case 'Payplug':
      paymentId = payplug_id;
      break;
    case 'Paygreen':
      paymentId = paygreen_id;
      break;
    case 'Edenred':
      paymentId = edenred_capture_id;
      break;
  }
  return paymentId;
}

const TransactionPanel = ({open, togglePanel, transaction, translator, switchTransaction, hasRestrictions}) => {
  const refresh = useRefresh();
  const [inputValue, setInputValue] = useState("");
  const [selectedTr, setSelectedTr] = useState(transaction);
  const [isLoadingRefundCheck, setIsLoadingRefundCheck] = useState(false);
  const [refundTransaction, { isLoading: isLoadingrefundTransaction, error: errorrefundTransaction, data: datarefundTransaction }] = useCreate();
  const [actifTab, setActifTab] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [timeoutAlert, setTimeoutAlert] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [openModalRestrictions, setOpenModalRestrictions] = useState(false);

  const handleChangeActifTab = (event, newValue, force = false ) => {
    if(actifTab === 0 && newValue === 1 && hasRestrictions && !force)
      return setOpenModalRestrictions(true);

    setShowConfirm(false);
    setActifTab(newValue);
  };

  useEffect(() => {
    setSelectedTr(transaction);
  }, [transaction]);

  useEffect(() => {
    setInputValue(selectedTr?.checkRefund?.restToRefund);
  }, [selectedTr]);

  useEffect(() => {
    if(!isLoadingrefundTransaction && !errorrefundTransaction && datarefundTransaction)
    {
      if (datarefundTransaction && datarefundTransaction.success)
        setShowAlert('success');
      else
        setShowAlert('error');
      setTimeoutAlert(3000);

      setIsLoadingRefundCheck(false);
      refresh();
      setShowConfirm(false);
      if (datarefundTransaction && datarefundTransaction.success)
      {
        setSelectedTr(
          {
            ...datarefundTransaction.transactionContent,
            linked_transactions: selectedTr.linkedTransactions
          })
      }
      else
      {
        setSelectedTr(
          {
            ...selectedTr,
            linked_transactions: selectedTr.linkedTransactions
          })
      }
      
    }
  }, [isLoadingrefundTransaction]);

  useEffect(() => {
    setShowConfirm(false);
    setActifTab(0);
  }, [selectedTr.transaction_id]);

  useEffect(() => {
    if(timeoutAlert)
    {
        const delayTimeout = setTimeout(() => {
        setShowAlert(false);
        setTimeoutAlert(0);
      }, 5000);
      return () => clearTimeout(delayTimeout);
    }
  }, [timeoutAlert]);

  if(!selectedTr) return null;

  const { date_transaction, montant, numero_table, numero_ticket, pourboire, remboursements, service, statut, 
    infos_appareil, articles, transaction_id, linked_transactions, statusColor, date_autorisation, date_capture,
    code_erreur, platform, checkRefund, transactions } = selectedTr;

  const { isRefunded,
          type,
          restToRefund,
          blockRefund,
          blockReason } = checkRefund;
  const filterField = (service === 'PayXpert' || service === 'Payplug') ? 'operationCode' : 'type';
  const refunds = transactions.filter(o => o[filterField] === 'refund');
  const nbRefunds = refunds.length;
  const paymentType = (service === 'PayXpert' || service === 'Payplug') ? translator('COMMON.BANK_CARD') : `${translator('COMMON.RESTAURANT_TICKET')} ${platform ? `(${platform})` : ''}`;
  const paymentId = getPaymentId(selectedTr);
  const authorizationDate = date_autorisation  ? formatDateWithSeconds(date_autorisation) : `-`;
  const errorMessage = code_erreur ? <LightTooltip title={code_erreur}><InfoOutlined sx={{...muiIconStyles.danger, fontSize:'36px'}} /></LightTooltip> : null;
  const Cell = withStyles({
    root: {
      borderBottom: "none",
      padding:5,
      paddingLeft: 0,
      verticalAlign: 'top'
    }
  })(TableCell);

  const handleChangeAmountToRefund = (e) => {
    const numericValue = Number(e.target.value);
    if (numericValue <= restToRefund) {
      setInputValue(e.target.value);
    }
  }

  const handleRefundTransaction = () => {
    setIsLoadingRefundCheck(transaction_id);
    const payload = { transaction_id, timestamp: new Date().toISOString(), montant: Number(inputValue) }
    refundTransaction(`transactions/refund`, { data: payload });
  }

  const handleSwitchTransaction = (id) => {
    setShowConfirm(false);
    switchTransaction(id);
  }

  const handleCloseModalrestrictions = () => {
    setOpenModalRestrictions(false);
  };

  const handleSubmitModalrestrictions = () => {
    setOpenModalRestrictions(false);
    handleChangeActifTab(null, 1, true); // 1: index de l'onglet remboursement
  };

  const formatArrayToStrings = (array) => (array) ? array.map(element => <li>{element}</li>) : null;

  const formatArrayToLinks = (array) => (array) ? array.map(element => <Button onClick={() => handleSwitchTransaction(element)} style={{ fontSize:17.6, fontWeight:"bold", padding:0, justifyContent: "flex-start" }}>{element}</Button>) : null;

  const panelHeader = () => {
    return <>
      <Box p={3} sx={{backgroundColor:"#fafafa"}} fontSize={'1.5em'} textAlign={'center'} fontWeight={'bold'}>{`${translator('COMMON.TRANSACTION')} N°${transaction_id}`}</Box>    
      <Box sx={{backgroundColor:"#fafafa"}} fontSize={'1.5em'} paddingBottom={"10px"} textAlign={'center'} fontWeight={'bold'}>{`${montant}€`}</Box>         
    </>;
  }

  const panelTabs = () => {
    const tabStyle = {fontSize:"1.2em", fontWeight:"bold"};
    return <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={actifTab} onChange={handleChangeActifTab} centered>
        <Tab sx={tabStyle} icon={<InfoOutlined />} iconPosition='start' label="Informations"  {...a11yProps(0)} />
        <Tab sx={tabStyle} icon={<EuroOutlined />} iconPosition='start' label="Remboursement" {...a11yProps(1)} />
      </Tabs>
    </Box>
  }

  const infosTab = () => {
    const leftCellStyle = { fontWeight:'bold', fontSize:"1.1em" };
    const rightCellStyle = { fontSize:"1.1em" };
    return <CustomTabPanel value={actifTab} index={0}>
      <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Cell width={200} sx={leftCellStyle} >{translator('COMMON.DATE')}</Cell>
            <Cell sx={rightCellStyle}>{formatDateWithSeconds(date_transaction)}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.STATUS')}</Cell>
            <Cell>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Box color={'white'} bgcolor={statusColor} width={'73px'} height={'32px'} lineHeight={'32px'} borderRadius={'50px'} textAlign={'center'}>
                  {statut}
                </Box>
                {errorMessage}
              </Stack>
            </Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.TICKET_NUM')}</Cell>
            <Cell sx={rightCellStyle}>{numero_ticket}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.TABLE_NUM')}</Cell>
            <Cell sx={rightCellStyle}>{numero_table}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.AMOUNT')}</Cell>
            <Cell sx={rightCellStyle}>{formatNumber(montant)}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.REFUNDS')} {(remboursements === 0) ? `` : ` (${nbRefunds})`}</Cell>
            <Cell sx={rightCellStyle}>{(remboursements === 0) ? `-` : `${formatNumber(remboursements)}`}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.TIPS')}</Cell>
            <Cell sx={rightCellStyle}>{(Number(pourboire) === 0) ? `-` : formatNumber(pourboire)}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.ITEMS')}</Cell>
            <Cell sx={rightCellStyle}>{(articles?.length) ? <ul style={{margin: 0, padding: '0 18px'}}>{formatArrayToStrings(articles)}</ul> : `-`}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.DEVICE_USED')}</Cell>
            <Cell sx={rightCellStyle}>{infos_appareil.systeme}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.PAYMENT_TYPE')}</Cell>
            <Cell sx={rightCellStyle}>{paymentType}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{`ID ${service}`}</Cell>
            <Cell sx={rightCellStyle}>{paymentId}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.DATE_AUTHORIZATION')}</Cell>
            <Cell sx={rightCellStyle}>{authorizationDate}</Cell>
          </TableRow>
          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.DATE_CAPTURE')}</Cell>
            <Cell sx={rightCellStyle}>{date_capture ? formatDateWithSeconds(date_capture) : `-`}</Cell>
          </TableRow>

          <TableRow>
            <Cell sx={leftCellStyle}>{translator('COMMON.LINKED_TRANSACTIONS')}</Cell>
            <Cell sx={rightCellStyle}><Stack justifyContent={'left'}>{(linked_transactions?.length) ? formatArrayToLinks(linked_transactions) : `-`}</Stack></Cell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
    </CustomTabPanel>;
  }

  const refundsTab = () => {
    const blocageMessageStyle = { fontSize: "1.1em", textAlign: 'center', color: 'red' };
    const messageRefund = (blockRefund)
      ? (blockReason === 'conecs_is_refunded_once' || blockReason === 'conecs_not_refunded_after_capture')
        ? 'TRANSACTIONS.REFUND_NOT_POSSIBLE_ANYMORE'
        : 'TRANSACTIONS.REFUND_NOT_POSSIBLE'
      : (isRefunded && type === 'total')
        ? 'TRANSACTIONS.REFUNDED_TOTAL'
        : null;
    
    const content = (messageRefund)
      ? <Typography sx={blocageMessageStyle}>{translator(messageRefund)}</Typography>
      : <Box>
        <RefundBtn />
        <ConfirmRefund />
      </Box>;
    
    return (
      <CustomTabPanel value={actifTab} index={1}>
        {content}
        <Box mt={2}>{displayAlert()}</Box>
        <TableContainer sx={{ paddingTop: "40px" }} >
          <Table>
            <TableBody>
              {getRefundsList()}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomTabPanel>
    );
  };

  const panelContent = () => (
    <Box sx={{ width: '600px' }}>
      {panelHeader()}
      <Divider />
      {panelTabs()}
      {infosTab()}
      {refundsTab()}
    </Box>
  );

  const getRefundsList = () => {
    const cellStyle = { fontSize:"1.1em" }
    return refunds.map(r => <TableRow>
      <Cell width={200} sx={cellStyle} > { r.date ? formatDateWithSeconds(r.date) : <i>{translator('COMMON.NOT_DEFINED_DATE')}</i>}</Cell>
      <Cell width={100} sx={{...cellStyle, textAlign:'right'}}>{formatNumber(r.amount)}</Cell>
      <Cell></Cell>
    </TableRow>)
  }

  const toggleConfirmRefund = (flag) => {
    setShowConfirm(flag);
  }

  const displayAlert = () => {
    if(!showAlert) return null;
    const message = (showAlert === "error") ? translator('TRANSACTIONS.REFUND_FAIL') : translator('TRANSACTIONS.REFUND_SUCCESS');
    return <Alert severity={showAlert} pt={2}>{message}</Alert>;
  }

  const RefundBtn = () => {
    if(showConfirm) return null;
    return <Box style={{ flex: 1 }}>
      <Stack
        direction="row"
        alignContent='center'
        alignItems='center'
        justifyContent='center'
        spacing={2}
      >
        <FormControl variant="outlined">
          <OutlinedInput inputProps={{ style : {textAlign:'center'}}} disabled={(service === 'Edenred')} autoFocus value={inputValue} onChange={handleChangeAmountToRefund} sx={{ borderRadius:'25px', width:'200px', height:'50px'}} endAdornment={<InputAdornment position="end">€</InputAdornment>} />
        </FormControl>
        <LoadingButton 
          loading={isLoadingRefundCheck === transaction_id} 
          loadingPosition="center"
          key={transaction_id} 
          disabled={Number(inputValue) <= 0 && true}
          // onClick={handleRefundTransaction}
          onClick={() => toggleConfirmRefund(true)}
          sx={{...btnStyles.primary, borderRadius:'25px', fontWeight:"bold", height:'50px'}}
        >
          {translator('ACTIONS.REFUND')}
        </LoadingButton>
      </Stack>
    </Box>
  }

  const ConfirmRefund = () => {
    if(!showConfirm) return null;
    return <Box textAlign={'center'}>
      <Stack>
        <Typography mb={2} fontWeight={'bold'} fontSize={"1.1em"}>{translator('TRANSACTIONS.CONFIRM_REFUND', { amountToRefund: formatNumber(inputValue) })}</Typography>
        <Stack direction={'row'} alignItems={'center'} alignContent={'center'} justifyContent={'center'} spacing={2}>
            <LoadingButton 
              size='small'
              loading={isLoadingRefundCheck === transaction_id} 
              loadingPosition="center"
              key={transaction_id} 
              disabled={Number(inputValue) <= 0 && true}
              onClick={handleRefundTransaction}
              sx={{...btnStyles.success, margin: 0, padding: '16px 10px'}}
            >
              {translator('COMMON.YES')}
            </LoadingButton>
            <LoadingButton 
              size='small'
              loading={false} 
              loadingPosition="center"
              key={transaction_id} 
              onClick={() => toggleConfirmRefund(false)}
              sx={{...btnStyles.danger, margin: 0, padding: '16px 10px'}}
            >
              {translator('COMMON.NO')}
            </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  }

  return (<>
    <RestrictionModal title={translator('MODAL_RESTRICTION.REFUND_TITLE')} open={openModalRestrictions} onClose={handleCloseModalrestrictions} handleSubmit={handleSubmitModalrestrictions} />
    <SwipeableDrawer
      anchor={'right'}
      open={open}
      onClose={togglePanel(false)}
      onOpen={togglePanel(true)}
    >
      {panelContent()}
    </SwipeableDrawer>
  </>);
}

export default TransactionPanel;