import * as React from 'react';
import { useGetPermissions, useGetList } from 'react-admin';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Menu, PersonOutlineOutlined } from '@mui/icons-material';
import { btnStyles, appBarStyles } from '../styles/Styles';
import Button from '@mui/material/Button';
import { ListRestaurants, ListeResataurantsModal } from './ListRestaurants';
import AdminTopMenu from './AdminTopMenu';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import logo from '../assets/images/logo-banana-split.svg';
import Stack from '@mui/material/Stack';

const isBOAdmin = (role) => role !== 'user';

export default function MenuAppBar() {

  const [role, setRole] = React.useState();
  const getPermissions = useGetPermissions();
  React.useEffect(() => {
    getPermissions().then(p => {
      setRole(p.name);
    });
  });

  // handle open modal
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  
  const { data, isLoading } = useGetList('restaurants/list-by-user');
  const restaurantsSelector = (isSmall) => {
    
    if(!isLoading)
    {
      return (isSmall) ? 
      <Box>
        <Button sx={btnStyles.secondary} onClick={handleOpenModal}>
          <PersonOutlineOutlined />
        </Button>
        <ListeResataurantsModal open={openModal} onClose={handleCloseModal} restaurants={data} /> 
      </Box>
      : <ListRestaurants
        restaurants={data}
        variant="outlined"
        sx={btnStyles.secondary}
      />
    }
    else
      return null;
  }

  const isSM = useMediaQuery('(max-width:899px)');
  const isNotAdmin = (role === 'resp' || role === 'tech');
  const homeLink = isNotAdmin ? "/#/restaurants" : "/";
  return (
    <Box sx={appBarStyles.appBarContainer}>
      <AppBar position="fixed" >
        <Toolbar>
          <Grid container sx={appBarStyles.appBarContent}>
            <Grid item lg={6} md={6} sm={6} xs={6}  sx={appBarStyles.logoContainer} >
              <Stack direction="row" margin={'10px'}>
                {/* Menu toggle */}
                <MenuToggle isSM={isSM} isNotAdmin={isNotAdmin} />
                {/* Logo */}
                <Typography variant="h3" component="div" sx={appBarStyles.logo}>
                  <a href={homeLink} style={appBarStyles.logo.link}>
                    <img src={logo} alt="logo-banana-split" style={appBarStyles.logo.logoImage} />
                  </a>
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} sx={ appBarStyles.selectLogout} >
              {/* Bouton de profil */}
              {
                isBOAdmin(role)
                ? <AdminTopMenu
                variant="outlined"
                sx={btnStyles.secondary} /> 
                : restaurantsSelector(isSM)
                }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={appBarStyles.selectedRestaurant}>
        {localStorage.getItem('nameRestaurant')}
      </Box>
    </Box>
  );
}

const MenuToggle = ({isSM, isNotAdmin}) => {
  if(isNotAdmin) return null;
  
  return <IconButton size="large" edge="start" color="inherit"  aria-label="menu" sx={{color:'#959DB2' }} onClick={(isSM) ? window['menuMobileToggle'] : window['menuToggle']}>
            <Menu  />
         </IconButton>;
}
