import * as React from "react";
import { SimpleShowLayout, TextField } from 'react-admin';


const ReadContent = ({ permissions, ...props }) => {
  
  return (
    <SimpleShowLayout>
      <TextField source="nom" />
      <TextField source="code_postal" />
      <TextField source="ville" />
      <TextField source="numero_siret" />
      <TextField source="statut" />
      <TextField source="pourboire_active" />
      <TextField source="commissions" />
      <TextField source="id_caisse" />
      <TextField source="autres_cartes_acceptees" />
      <TextField source="identifiant_paygreen" />
      <TextField source="identifiant_payxpert" />
      <TextField source="jpay_api_jwt_id" />
      <TextField source="jpay_api_jwt_mdp" />
      <TextField source="identifiant_edenred" />
      <TextField source="acces_recents" />
    </SimpleShowLayout>
  );
};

export default ReadContent;