import * as React from 'react';
import { useState, useEffect } from 'react';
import { SimpleForm, useLogin, useNotify, Notification, useRedirect, useCreate, useTranslate } from 'react-admin';
import {Typography, Box, Grid, Button} from '@mui/material';
import GridItem from '../../styles/GridItem';
import CustomTextField from '../core/CustomTextField';
import { btnStyles, iconStyles, loginPageStyles } from '../../styles/Styles';
import logo from '../../assets/images/logo-banana-split.svg';
import RoleModal from './RoleModal';

const _ENV = process.env.REACT_APP_ENVIRONMENT;

const CustomToolbar = () => {
  return null;
}

const NonInput = React.memo(function NonInput({ children }) {
  return children;
});

const LoginPage = (props) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const notify = useNotify();
  const login = useLogin();
  const [authCheckEmail, { isLoading: isLoadingAuthCheckEmail, error: errorAuthCheckEmail, data: dataAuthCheckEmail }] = useCreate();
  const [forgotPassword, { isLoading: isLoadingForgotPassword, error: errorForgotPassword, data: dataForgotPassword }] = useCreate();

  useEffect(() => {
    if(!isLoadingAuthCheckEmail && !errorAuthCheckEmail && dataAuthCheckEmail)
    {
      if(dataAuthCheckEmail.error)
        return notify(`${dataAuthCheckEmail.msg}`, { type: 'warning' });
      else if(dataAuthCheckEmail.multiple_accounts === false)
        return confirmChangePassword(dataAuthCheckEmail.email, dataAuthCheckEmail.role);
      else if(dataAuthCheckEmail.multiple_accounts === true)
        return handleOpenModalRole(dataAuthCheckEmail.roles);
    }
  }, [isLoadingAuthCheckEmail])

  useEffect(() => {
    if(!isLoadingForgotPassword && !errorForgotPassword && dataForgotPassword)
    {
      if(dataForgotPassword && dataForgotPassword.error)
        return notify(dataForgotPassword.msg, { type: 'warning' });
      handleHideForgotPassword();
      notify(`${translate('RESTAURANTS.EMAIL_SENT_TO')} ${email}.`, { type: 'success' });
    }
  }, [isLoadingForgotPassword])

  const handleLogin = () => {
    return login({ username, password }).then((result) => {
      // Redirect user to dashboard after successful login
      // role tech, redirect to restaurants list
      const isNotAdmin = (result.role === 'tech' || result.role === 'resp');
      const url = isNotAdmin ? '/restaurants' : '/';
      redirect(url);
    }).catch((error) => {
      notify(`${translate('COMMON.ERROR_LOGIN_FAIL')}`, error)
    });
  }

  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);
  const handleShowForgotPassword = () => setShowPassword(true);
  const handleHideForgotPassword = () => setShowPassword(false);

  const [showModalRole, setShowModalRole] = useState(false);
  const handleOpenModalRole = (roles) => {
    setShowModalRole(true);
    setRoles(roles);
  }
  const handleCloseModalRole = () => setShowModalRole(false);

  const confirmChangePassword = (givenEmail, givenRole) => {
      forgotPassword(`authentication/forgot-password`, { data: { email: givenEmail, role: givenRole } });
  }

  const handleAskForgotPassword = () => {
    setRole("");
    if(!email)
      return notify(`${translate('VALIDATION.MANDATORY.MID')}`, { type: 'warning' });
      authCheckEmail(`authentication/check-email`, { data: { email: email } });
  }

  return (
    <Box>
      <Grid container spacing={2} sx={loginPageStyles.loginContainer} >
        <Grid item lg={4} md={3} sm={4} xs={12}  >
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}  >
          <GridItem position="middle" >
            <Typography variant="h3" component="div" sx={loginPageStyles.logo}>
              <img src={logo} alt="logo-banana-split" style={loginPageStyles.logoImage} />
            </Typography>

            {(showPassword)
              ? <ForgotPasswordForm
                handleEmailChange={setEmail}
                role={role}
                roles={roles}
                handleRoleChange={setRole}
                handleAskForgotPassword={handleAskForgotPassword}
                handleShowLogin={handleHideForgotPassword}
                translate={translate}
                showModalRole={showModalRole}
                handleCloseModalRole={handleCloseModalRole}
                confirmChangePassword={confirmChangePassword}
                email={email}
              />
              : <LoginForm
                username={username}
                password={password}
                handleUsernameChange={setUsername}
                handlePasswordChange={setPassword}
                handleLogin={handleLogin}
                handleShowForgotPassword={handleShowForgotPassword}
                translate={translate}
              />
            }
          </GridItem >
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12}  >
          <Notification />
        </Grid>
      </Grid>
    </Box>
  );
};

const LoginForm = ({ username, password, translate, ...props}) => {
  const handleUsernameChange = props.handleUsernameChange;
  const handlePasswordChange = props.handlePasswordChange;
  const handleLogin = props.handleLogin;
  const handleShowForgotPassword = props.handleShowForgotPassword;
  const isTEST = _ENV === 'DEV';
  const displayForgotBtn = isTEST ? 'none' : 'block';

  return (
    <SimpleForm toolbar={<CustomToolbar />} >
      <NonInput>
        <Typography variant="body1" sx={loginPageStyles.label}>{translate('COMMON.EMAIL_ADDRESS')}</Typography>
        <CustomTextField name="username" label="username" onChange={e => handleUsernameChange(e.target.value)} value={username} fullWidth={true} disabled={false} />
        <Typography variant="body1" sx={loginPageStyles.label}>{translate('COMMON.PASSWORD')}</Typography>
        <CustomTextField name="password" label="password" onChange={e => handlePasswordChange(e.target.value)} value={password} type="password" fullWidth={true} disabled={false} />
        <Button variant="outlined" sx={{ ...btnStyles.secondary, ...loginPageStyles.btn }} onClick={handleLogin}>
          {translate('ACTIONS.LOGIN')}
        </Button>
        <Button sx={{ ...iconStyles.danger, ...loginPageStyles.forgotPass, display: displayForgotBtn }} onClick={e => { e.preventDefault(); handleShowForgotPassword() }}>{translate('COMMON.PASSWORD_FORGOT')}</Button>
      </NonInput>
    </SimpleForm>
  );
};

const ForgotPasswordForm = ({ translate, role, roles, showModalRole, handleCloseModalRole, confirmChangePassword, email, ...props }) => {
  const handleEmailChange = props.handleEmailChange;
  const handleAskForgotPassword = props.handleAskForgotPassword;
  const handleShowLogin = props.handleShowLogin;
  const handleRoleChange= props.handleRoleChange;

  return (<>
    <RoleModal email={email} role={role} roles={roles} isOpen={showModalRole} onClose={handleCloseModalRole} handleRoleChange={handleRoleChange} confirmChangePassword={confirmChangePassword} translate={translate} />
    <SimpleForm toolbar={<CustomToolbar translate={translate}/>} >
      <NonInput>
        <Typography variant="body1" sx={loginPageStyles.label}>{translate('COMMON.TYPE_EMAIL')}</Typography>
        <CustomTextField name="username" label="username" onChange={e => handleEmailChange(e.target.value)} fullWidth={true} disabled={false} />
        <Button variant="outlined" sx={{ ...btnStyles.primary, ...loginPageStyles.btn }} onClick={handleAskForgotPassword} >
          {translate('ACTIONS.SEND')}
        </Button>
        <Button sx={{ ...iconStyles.danger, ...loginPageStyles.forgotPass }} onClick={e => { e.preventDefault(); handleShowLogin() }}>{translate('ACTIONS.CANCEL')}</Button>
      </NonInput>
    </SimpleForm>
    </>
  );
}

export default LoginPage;
