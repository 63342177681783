import { fr, es, enGB } from "date-fns/locale";
import { format } from 'mathjs';

export const convertFileToBase64 = file => 
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    }
);

const getMonday = (d) => {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6:1); // Ajuster quand le premier jour est dimanche
    const date = new Date(d.setDate(diff))
    date.setHours(5);
    date.setMinutes(0)
    return date;
}

/**
* Retourne les dates de début et fin pour filtrer la liste par défaut (semaine en cours)
* debut: 1er jour de la semaine (lundi)
* fin: date courante
*
* @returns {Object}
*/

export const defaultWeekDateRangeFilter = () => {
 const now = new Date();
 const endFilter = now.toISOString().slice(0, 10);
 const startFilter = getMonday(now).toISOString().slice(0, 10);
 return { startFilter, endFilter };
};


/**
 * Retourne les dates de début et fin pour filtrer la liste par défaut (mois en cours)
 * debut: 1er du mois
 * fin: date courante
 * 
 * @returns {Object}
 */
 export const defaultMonthDateRangeFilter = () => {
    const now = new Date();
    const endFilter = now.toISOString().slice(0, 10);
    // 1er jour du mois
    const firstOfMonth = new Date(now.setDate(1));
    const startFilter = new Date(firstOfMonth).toISOString().slice(0, 10);
    return { startFilter, endFilter };
}


export const getLocale = (locale) => {
    if (locale === 'es') return es;
    if (locale === 'en' || locale === 'en-EN') return es;

    return fr;
};

export const getRoleAndPermissions = (permissions, resource) => {
  if (!permissions) return { permissions: null };

  return {
    role: permissions.name,
    country: permissions.country,
    permissions: (permissions.resources) ? permissions.resources[resource.split('/:')[0]] : null,
  };
};

export const formatNumber = (value, precision = 2, unit = ' €') => {
    const numberVal = Number(value);
    if (isNaN(numberVal)) return null;
    const formatedVal = `${format(numberVal, { notation: 'fixed', precision })}${unit}`;
    return formatedVal;
}; 

export const isIntNumber = (n) => {
    return n % 1 === 0;
 }


export const formatDate = (givenDate) => {
  if (!givenDate) return null
  const date = new Date(givenDate);
  return date.toLocaleString("fr-FR", {
    day: "numeric",
    month: "numeric",
    year: "numeric"
  });
};

export const formatDateTime = (givenDate) => {
  if (!givenDate) return null
  const date = new Date(givenDate);
  return date.toLocaleString("fr-FR", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });
};

export const formatDateWithSeconds = (givenDate) => {
    if(!givenDate) return null
    const date = new Date(givenDate);
    return date.toLocaleString("fr-FR", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit"
    });
}

export const formatDateWithTimeZone = (dateValue) => {
    if (!dateValue) return false;
    if (typeof dateValue === 'undefined') return false;
  
    return dateValue
      .replace('T', ' ')
      .replace('Z', '+00');
};

export const clearSessionLocalStorage = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('country');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('idRestaurant');
    localStorage.removeItem('timer');
    localStorage.removeItem('multipleRestaurants');
    localStorage.removeItem('hasPayxpert');
    localStorage.removeItem('hasPayplug');
    localStorage.removeItem('hasPaygreen');
    localStorage.removeItem('hasEdenred');
    localStorage.removeItem('agence');
};

export const clearAuthLocalStorage = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('filterDateStart');
    localStorage.removeItem('filterDateEnd');
    localStorage.removeItem('role');
    localStorage.removeItem('country');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('idRestaurant');
    localStorage.removeItem('agence');
};

export const setAuthLocalStorage = (payload) => {
    const {
        token,
        multiple_restaurants,
        agence,
        role,
        restaurant,
        country,
    } = payload;
    localStorage.setItem('token', token);
    localStorage.setItem('multipleRestaurants', multiple_restaurants);
    localStorage.setItem('agence', JSON.stringify(agence));
    localStorage.setItem('role', role);
    localStorage.setItem('country', country);
    if (restaurant) {
        localStorage.setItem('idRestaurant', restaurant.id);
        localStorage.setItem('country', restaurant.country);
        localStorage.setItem('hasPayxpert', restaurant.hasPayxpert);
        localStorage.setItem('hasPayplug', restaurant.hasPayplug);
        localStorage.setItem('hasPaygreen', restaurant.hasPaygreen);
        localStorage.setItem('hasEdenred', restaurant.hasEdenred);
    }
};

export const getKeyPartNoonNight = (d) => {
    const _AFTERNOON_HOUR = 18;
    const _MORNING_HOUR = 5;
    const h = new Date(d).getHours();
    const isNoon = h > _MORNING_HOUR && h < _AFTERNOON_HOUR;
    return isNoon ? 'AM' : 'PM';
};
