import Joi from 'joi';
import i18nProvider from '../../../i18n';

export const accountSchema = Joi.object().keys({
  pin: Joi.string().regex(/^[0-9]{4}$/).max(4).required().messages({
    'string.base': i18nProvider.translate('VALIDATION.MANDATORY.RESTRICTION_CODE'),
    'string.empty': i18nProvider.translate('VALIDATION.MANDATORY.RESTRICTION_CODE'),
    'string.max': i18nProvider.translate('VALIDATION.TYPE.RESTRICTION_CODE_LENGTH'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.RESTRICTION_CODE'),
    'string.pattern.base': i18nProvider.translate('VALIDATION.TYPE.RESTRICTION_CODE_PATTERN'),
  }),
  pinConfirm: Joi.number().required().messages({
    'number.base': i18nProvider.translate('VALIDATION.TYPE.RESTRICTION_CODE_PATTERN'),
    'any.required': i18nProvider.translate('VALIDATION.MANDATORY.RESTRICTION_CODE')
  }),
}).unknown();
