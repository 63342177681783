import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { TextField } from '@mui/material';

const SearchField = ( {show, value, handleChangeKeyword }) => {
  const translate = useTranslate();
  const [keyword, setKeyword] = useState(value || '');

  if (!show) return null;

  const onChange = (e) => {
    const val = e.target.value;
    setKeyword(val);
    handleChangeKeyword(val);
  };

  return (
    <TextField
      key="keyword"
      label={translate('ACTIONS.SEARCH')}
      value={keyword}
      className="dashDateInput"
      onChange={onChange}
    />
  );
};

export default SearchField;
