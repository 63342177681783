import * as React from 'react';
import { useTranslate } from 'react-admin';
import CreateComponent from "../core/CreateComponent";
import CartesCreateContent from './CreateContent';


const CartesCreate = (props) => {
  const t = useTranslate();
  return (
    <CreateComponent
      title={t('CARDS.TITLE_CREATE')}
      breadcrumb={t('CARDS.TITLE_CREATE')}
      notifyname={t('CARDS.NAME')}
      notifyitem="nom"
      {...props}>
      <CartesCreateContent />
    </CreateComponent>
  );
};

export default CartesCreate;