import * as React from 'react';
import EditComponent from "../core/EditComponent";
import RestaurantEditContent from './EditContent';
import { useTranslate, useGetList, Loading } from 'react-admin';

const RestaurantEdit = (props) => {
  const translate = useTranslate();
  const { data: agences, isLoading: isLoadingAgences } = useGetList('agences');
  const { data: caisses, isLoading: isLoadingCaisses } = useGetList('caisses');
  const { data: restaurateurs, isLoading: isLoadingRestaurants } = useGetList('utilisateurs/list-restaurants');

  if (isLoadingAgences || !agences) return <Loading />;
  if (isLoadingCaisses || !caisses) return <Loading />;
  if (isLoadingRestaurants || !restaurateurs) return <Loading />;

  const handleTransform = (data, { previousData }) => {
    const activateTR = !previousData?.metas?.use_paygreen && data?.metas?.use_paygreen;
    if (activateTR) data.identifiant_paygreen.actif = true;
    const desactivateTr = previousData?.metas?.use_paygreen && !data?.metas?.use_paygreen;
    if (desactivateTr) data.identifiant_paygreen.actif = false;
    const activatePg = !previousData?.identifiant_paygreen?.actif && data?.identifiant_paygreen?.actif;
    if (activatePg && !data?.metas?.use_paygreen) data.metas.use_paygreen = true;
    
    return data;
  };

  return (
    <EditComponent
      title={translate('RESTAURANTS.TITLE_EDIT')}
      breadcrumb='RESTAURANTS.TITLE_EDIT'
      notifyname="RESTAURANTS.NAME"
      notifyitem="adresse_mail"
      actions={false}
      mutationMode="optimistic"
      transform={handleTransform}
      {...props}>
      <RestaurantEditContent
        translate={translate}
        agences={agences}
        caisses={caisses}
        restaurateurs={restaurateurs}
      />
    </EditComponent>
  )
};

export default RestaurantEdit;