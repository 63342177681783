import * as React from "react";
import {
  useNotify,
  useTranslate,
  useCreate,
} from 'react-admin';
import { Box, Select, MenuItem, Stack, IconButton } from '@mui/material';
import { downloadBase64ToPDF } from "../../utils/pdf";
import { btnStyles } from "../../styles/Styles";
import { QrCode2, Info } from '@mui/icons-material';
import { useEffect } from "react";
import { useSelectAppBarStyles, useMenuItemListStyles } from "../../styles/Classes";
import { listMenuItemStyle } from "../../styles/Styles";
import { colors } from "../../styles/Styles";



const DownloadMultipleQrCode = (props) => {
  const { selectedIds, disabled } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const classesSelect = useSelectAppBarStyles();
  const classesMenuItem = useMenuItemListStyles();
  const [downloadQrCodes, { isLoading: isLoadingDownloadQrCodes, error: errorDownloadQrCodes, data: dataDownloadQrCodes }] = useCreate();

  useEffect(() => {
    if (!isLoadingDownloadQrCodes && !errorDownloadQrCodes && dataDownloadQrCodes) {
      const base64String = dataDownloadQrCodes.pdf
      notify('QRCODE.DOWNLOAD', 'success', { messageArgs: { name: translate('QRCODE.DOWNLOAD') } });  // Fix warnings de trad
      // DL SELECTED QR-CODE
      downloadBase64ToPDF(base64String, `qrcode-${dataDownloadQrCodes.template}`);
    }
    else if (errorDownloadQrCodes)
      notify(translate('QRCODE.ERRORS.DOWNLOAD_FAIL'), { type: 'error' });
  }, [isLoadingDownloadQrCodes])

  const downloadQrCode = (template) => {
    const idRestaurant = localStorage.getItem('idRestaurant');
    downloadQrCodes(`qr-codes/get-pdf`, { data: { tables_ids: selectedIds, restaurant_id: idRestaurant, template } });
  }

  const renderItem = ({id, name, preview}) => {
    const itemStyle = listMenuItemStyle(colors.success);
    return <Box sx={itemStyle.box}>
      <Stack direction="row">
        <MenuItem value={id} classes={classesMenuItem} sx={btnStyles.success} onClick={() => downloadQrCode(id)}>{name}</MenuItem>
        { preview.actif && <Box sx={itemStyle.boxLink}>
          <IconButton color="neutral" onClick={() => props.toggleOpenModaleTemplate(id)}>
            <Info sx={itemStyle.infoIcon} />
          </IconButton>
        </Box> }
      </Stack>
    </Box>
  }

  const items = props.qrcodesTemplates.map(t => renderItem(t));

  return (
    <Select MenuProps={props.menuProps} classes={classesSelect} value="default" defaultValue={"default"} sx={{...btnStyles.success, '& .MuiOutlinedInput-notchedOutline': {border: 'none' }}} disabled={disabled}>
      <MenuItem value="default" sx={{ display: 'none' }} classes={classesMenuItem}  > <QrCode2 sx={{ marginRight: '8px' }} /> {props.label} </MenuItem>
      {items}
    </Select>
  );
};

export default DownloadMultipleQrCode;