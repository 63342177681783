import * as React from 'react';
import Breadcrumb from '../core/BreadCrumb';
import { Box } from '@mui/material';
import GestionPourboires from './Pourboires/index';
import { useDataProvider, useTranslate, useGetOne } from 'react-admin';
import { useState, useEffect } from 'react';

const Parametres = (props) => {
  const idRestaurant = localStorage.getItem('idRestaurant');
  const translate = useTranslate()

  const [tipsConfig, setTipsConfig] = useState(false);
  const filter = {id:idRestaurant};
  const { data, isLoading } = useGetOne(
    `settings/tips`,
    filter
  );

   useEffect(() => {
    if(!isLoading && data)
      setTipsConfig(data)
  }, [isLoading, data])

    
  return (
    <Box>
        <Breadcrumb label={translate('COMMON.TIPS')} path={`#`} />
        <GestionPourboires config={tipsConfig} translate={translate}/>
    </Box>
  );
}

export default Parametres;
