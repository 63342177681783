import * as React from "react";
import { Typography, Button, Box, Modal, Select, MenuItem } from '@mui/material';
import { modalBodyStyle, smallModalStyle } from '../../styles/Styles';
import { styled } from '@mui/material/styles';
import { loginPageStyles } from '../../styles/Styles';
import { useNotify } from 'react-admin';
import { _ROLES } from "../../constantes";

const RoleModal = ({ email, role, roles, isOpen, onClose, handleRoleChange, confirmChangePassword, translate }) => {
  const notify = useNotify();
  const DesignedSelectInput = styled(Select)({
    '&.MuiOutlinedInput-root': {
      marginBottom: 30,
      width: '100%',
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(3, 201, 215)',
      },
      '& .MuiSelect-select': {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
  });

  const onConfirm = () => {
    if (!email)
      return notify(`${translate('VALIDATION.MANDATORY.MID')}`, { type: 'warning' });
    if (!role)
      return notify(`${translate('VALIDATION.MANDATORY.ROLE')}`, { type: 'warning' });
    confirmChangePassword(email, role)
    onClose();
  }

  const userRoles = roles.reduce((prev, item) => {
    const role = _ROLES.find(r => r.id === item)?.name || 'RESTAURANTS.NAME';
    prev[item] = translate(role);
    return prev;
  }, {});
  
  const choices = roles.map(p => <MenuItem key={p} value={p}>{userRoles[p]}</MenuItem>);

  return <Modal
    open={isOpen}
    onClose={onClose}
  >
    <Box sx={smallModalStyle}>
      <Box sx={modalBodyStyle}>
        <Typography variant="body1" sx={loginPageStyles.label}>{translate('COMMON.YOU_ARE')}</Typography>
        <DesignedSelectInput name="role" value={role} onChange={e => handleRoleChange(e.target.value)} displayEmpty >
          <MenuItem disabled value="">
            <em>{translate('VALIDATION.SELECT.ROLE')}</em>
          </MenuItem>
          {choices}
        </DesignedSelectInput>
        <Box>
          <Button size="small" color="success" variant="contained" sx={{ m: 1 }} onClick={onConfirm}>{translate('ra.action.confirm')}</Button>
          <Button size="small" color="error" variant="contained" sx={{ m: 1 }} onClick={onClose}>{translate('ACTIONS.CANCEL')}</Button>
        </Box>
      </Box>
    </Box>
  </Modal>
};

export default RoleModal;