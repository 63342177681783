import * as React from "react";
import { useState, useEffect } from 'react';
import {
  Toolbar,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  useNotify,
  useCreate,
  useRecordContext,
  useUpdate,
  useRedirect,
  useRefresh
} from 'react-admin';
import { SaveOutlined, Check, Lock, ToggleOff, RestartAlt } from '@mui/icons-material';
import { Box, Typography, Stack, Divider, Chip, Grid } from '@mui/material';
import { adminForm, colors, btnStyles, disabledBtnStyles } from "../../styles/Styles";
import { editSchema } from "../../utils/Validations/schemas/restaurantSchema";
import errorSchemas from "../../utils/Validations/handleErrorSchemas/errorSchemas";
import { _ID_AGENCE_ES, _ID_CAISSE_ES, } from '../../constantes';
import ModalConfirmationEnableDisableAccount from "../core/ModalConfirmationEnableDisableAccount";
import ModalConfirmationSendIdentifiers from "../core/ModalConfirmationSendIdentifiers";
import { formatDate } from "../../utils/utils";


const NonInput = React.memo(function NonInput({ children }) {
  return children;
});


const RestaurantUpsertContent = ({ translate, isCreate, isEdit, agences, caisses, restaurateurs, permissions, country, role }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [disablePayxpert, setDisablePayxpert] = useState(false); // disabled pxp if payplug
  const [openModalConfirmAccount, setOpenModalConfirmAccount] = useState(false);
  const [openModalConfirmIdentifiers, setOpenModalConfirmIdentifiers] = useState(false);
  const [operationAccount, setOperationAccount] = useState('enable');
  const [hideMdp, setHideMdp] = useState(false);
  const [restaurants, setRestaurants] = useState(false);
  const [showPayxpert, setShowPayxpert] = useState(true);
  const [showPaygreen, setShowPaygreen] = useState(false);
  const [showMetasPaygreen, setShowMetasPaygreen] = useState(false);
  const [hasPaygreen, setHasPaygreen] = useState(false);
  const [updateRestaurant] = useUpdate();
  const paygreenNotActivatedbefore = isEdit && !record?.enabled_paygreen && !record?.raison_social;
  const isNotAdmin = (role === 'resp' || role === 'tech');
  
  const _IS_ADMIN_ES = country === 'ES';

  useEffect(() => {
    if (record?.compte_actif) setOperationAccount('disable');
    if (record?.enabled_payxpert) setShowPayxpert(true); else if(!record?.enabled_payxpert && !isCreate) setShowPayxpert(false);
    if (record?.enabled_paygreen) setShowPaygreen(true); else setShowPaygreen(false);
    if (record?.use_paygreen) setShowMetasPaygreen(true); else setShowMetasPaygreen(false);
  }, [record]);

  useEffect(() => {

  },[showPaygreen])


  const togglePaygreenForm = (e) => {
    setShowPaygreen(e.target.checked);
    if(paygreenNotActivatedbefore) setShowMetasPaygreen(e.target.checked);
  }
  const toggleTRForm = (e) => { 
    setShowMetasPaygreen(e.target.checked);
    togglePaygreenForm(e);
  };

  // Payexeprt validator
  const togglePayxpert = (e) => {
    setShowPayxpert(e.target.checked === true);
    // (e.target.checked === true) ? setDisablePayplug(true) : setDisablePayplug(false);
  }

  /**
   * Global validator
   * 
   * @param {Object} values : champs du formulaire
   * @returns tableau d'erreurs
   */
  const submitForm = (values) => {
    if (isCreate && _IS_ADMIN_ES) {
      values.id_agence = _ID_AGENCE_ES;
      values.caisse = _ID_CAISSE_ES;
    }
    const formValues = {
      ...values,
    };
    const errors = errorSchemas(editSchema, formValues);
    let returnedErrors = { ...errors };
    const isNameExists = uniqNameValidator(values.nom);
    if (isCreate && isNameExists) returnedErrors = { ...returnedErrors, nom: isNameExists };
    // champs payxpert: tous les champs doivent etre renseignés
    const errorPayxpert = controlPayxpert(values);
    if (errorPayxpert) returnedErrors = { ...returnedErrors, ...errorPayxpert };
    const errorPaygreen = controlPaygreen(values);
    if (errorPaygreen) returnedErrors = { ...returnedErrors, ...errorPaygreen };
    return returnedErrors;
  };

  const controlPayxpert = (values) => {
    const errors = {};
    if (values.id_payxpert && !values.cle_api_payxpert) errors['cle_api_payxpert'] = translate('VALIDATION.MANDATORY.API_KEY');
    if (!values.id_payxpert && values.cle_api_payxpert) errors['id_payxpert'] = translate('VALIDATION.MANDATORY.PAYXPERT_ID');
    return errors;
  };

  const controlPaygreen = (values) => {
    const errors = {};
    if ((values.secret_key_paygreen || values.public_key_paygreen) && !values.shop_id_paygreen) errors['shop_id_paygreen'] = translate('VALIDATION.MANDATORY.SHOP_ID');
    if ((values.shop_id_paygreen || values.public_key_paygreen) && !values.secret_key_paygreen) errors['secret_key_paygreen'] = translate('VALIDATION.MANDATORY.SECRET_KEY');
    if ((values.shop_id_paygreen || values.secret_key_paygreen) && !values.public_key_paygreen) errors['public_key_paygreen'] = translate('VALIDATION.MANDATORY.PUBLIC_KEY');
    
    return errors;
  };


  const handleTypeMailAddress = (e) => {
    if (restaurants) setRestaurants(false);
    const email = e.target.value.trim();
    const emailIndex = restaurateurs.findIndex(a => a.id === email);
    if (emailIndex > -1) {
      setRestaurants(restaurateurs[emailIndex].restaurants)
      setHideMdp(true);
    }
    else
      setHideMdp(false);
  };

  const handleOpenModalConfirmAccount = () => {
    setOpenModalConfirmAccount(true);
  };

  const handleCloseModalConfirmAccount = () => {
    setOpenModalConfirmAccount(false);
  };

  const handleOpenModalConfirmIdentifiers = () => {
    setOpenModalConfirmIdentifiers(true);
  };

  const handleCloseModalConfirmIdentifiers = () => {
    setOpenModalConfirmIdentifiers(false);
  };



  const getAllExistingRestaurants = () => {
    let restaurants = [];
    restaurateurs.map(l => l.restaurants.map(r => restaurants.push(r.nom)));
    return restaurants;
  }
  const allExistingRestaurants = getAllExistingRestaurants();

  const uniqNameValidator = (nom) => {
    if (!nom) return translate('VALIDATION.MANDATORY.NAME');
    const nomIndex = allExistingRestaurants.findIndex(r => {
      const given_name = nom.trim().toLowerCase();
      const formatted_given_name = given_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const formatted_existing_name = r.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      return formatted_existing_name === formatted_given_name;
    });
    if (nomIndex > -1) return translate('VALIDATION.EXISTS.NAME');
    return undefined;
  }
  const handleEdit = (values) => {
    const data = { ...values, enabled_paygreen: showPaygreen}
    if(paygreenNotActivatedbefore && values.enabled_paygreen) data.use_paygreen = true;
    updateRestaurant(`restaurants`, 
           { id: record?.id, data: data },
           {
            onSuccess: () => {
                refresh();
                redirect('/restaurants');
            }
          });
  }

  const formOptions = (isEdit) ? {onSubmit : handleEdit} : {};



  return (
    <>
      <ModalConfirmationEnableDisableAccount isOpen={openModalConfirmAccount} onClose={handleCloseModalConfirmAccount} nom={record?.nom} operation={operationAccount} id={record?.id} />
      <ModalConfirmationSendIdentifiers isOpen={openModalConfirmIdentifiers} onClose={handleCloseModalConfirmIdentifiers} id={record?.id} adresse_mail={record?.adresse_mail} />
      <SimpleForm
        mode="onChange"
        toolbar={<CustomToolbar
          isCreate={isCreate}
          translate={translate}
          record={record}
          handleOpenModalConfirmAccount={handleOpenModalConfirmAccount}
          handleOpenModalConfirmIdentifiers={handleOpenModalConfirmIdentifiers}
          permissions={(isCreate) ? permissions.create : permissions.edit}
          isNotAdmin={isNotAdmin}
        />}
        // translate={translate}
        validate={submitForm}
        {...formOptions}
      >
        <NonInput>
          <Divider sx={{ ...adminForm.divider, ...adminForm.divider.first, width: '100%' }} textAlign="left" variant="fullWidth">
            <Chip size="small" sx={adminForm.chip} color="primary" label={translate('RESTAURANTS.INFOS')} />
          </Divider>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6} lg={5}>
              <NameField
                label={translate('COMMON.ETABLISHMENT_NAME')}
                disabled={isEdit}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <EmailField
                t={translate}
                restaurants={restaurants}
                hideMdp={hideMdp}
                disabled={isEdit}
                onChange={handleTypeMailAddress}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <AgenceForm
                show={(!_IS_ADMIN_ES && isCreate) || isEdit}
                label={translate('COMMON.AGENCY')}
                choices={agences}
                disabled={_IS_ADMIN_ES || (isEdit && !permissions.edit.save) || isNotAdmin}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <CodeClient
                label={translate('COMMON.CLIENT_CODE')}
                disabled={(isEdit && !permissions.edit.save) || isNotAdmin}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <CaisseForm
                show={!_IS_ADMIN_ES}
                label={translate('COMMON.TILL_NAME')}
                choices={caisses}
                disabled={isEdit || isNotAdmin}
              />
            </Grid>
          </Grid>

          <Divider sx={{ ...adminForm.divider, ...adminForm.divider.first, width: '100%' }} textAlign="left" variant="fullWidth">
            <Chip size="small" sx={adminForm.chip} color="primary" label="Options" />
          </Divider>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={6} lg={5}>
              <TipsForm
                show={!_IS_ADMIN_ES}
                label={translate('COMMON.TIPS')}
                disabled={(isEdit && !permissions.edit.save) || isNotAdmin}
              />
            </Grid>
            <Grid item xs={6} lg={5}>
              <NpScoreField
                label={translate('COMMON.NP_SCORE')}
                disabled={(isEdit && !permissions.edit.save) || isNotAdmin}
              />
            </Grid>
            <Grid item xs={12}>
              <TicketRestaurantForm
                record={record}
                show={!_IS_ADMIN_ES}
                title={translate('COMMON.CLIENT_WISH_USE_PAYGREEN')}
                onChange={toggleTRForm}
                display={(showMetasPaygreen) ? 'block' : 'none'}
                isCreate={isCreate}
                paygreenNotActivatedbefore={paygreenNotActivatedbefore}
                disabled={isNotAdmin}
                t={translate}
              />
            </Grid>
          </Grid>
          
          <Divider sx={{ ...adminForm.divider, width: '100%' }} textAlign="left" variant="fullWidth">
            <Chip size="small" sx={adminForm.chip} color="primary" label={translate('COMMON.PAYMENTS_ID')} />
          </Divider>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6} lg={5}>
              <PayxpertForm
                t={translate}
                isCreate={isCreate}
                display={(showPayxpert) ? 'block' : 'none'}
                nbReminds={record.nbRemind_payxpert}
                requestDate={record.requestDate_payxpert}
                onChange={togglePayxpert}
                disabledToggle={disablePayxpert || (isEdit && !permissions.edit.save) || isNotAdmin}
                isNotAdmin={isNotAdmin}
                record={record}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <PaygreenForm
                t={translate}
                show={!_IS_ADMIN_ES}
                title={translate('COMMON.PAYGREEN_ID')}
                display={(showPaygreen) ? 'block' : 'none'}
                nbReminds={record.nbRemind_paygreen}
                requestDate={record.requestDate_paygreen}
                onChange={togglePaygreenForm}
                disabled={((isEdit && (!permissions.edit.save || !showMetasPaygreen)) && !paygreenNotActivatedbefore) || isNotAdmin}
                // disabled={(isEdit && !permissions.edit.save)}
                isPaygreenUsed={showPaygreen}
                isNotAdmin={isNotAdmin}
                record={record}
              />
            </Grid>
          </Grid>
          
          <InfosField
            title={translate('COMMON.FURTHER_INFORMATIONS')}
            label={translate('COMMON.COMMENT')}
            disabled={isNotAdmin}
          />

        </NonInput>
      </SimpleForm>
    </>
  );
};


//#region Toolbar

const CustomToolbar = (props) => {
  const resetPassword = ResetPasswordButton(props.record?.adresse_mail, props.record?.compte_actif, props.translate);
  const enableDisableAccount = EnableDisableAccountButton(props.record?.compte_actif, props.handleOpenModalConfirmAccount, props.translate);
  const sendIdentifiers = SendIdentifiers(props.record?.compte_actif, props.handleOpenModalConfirmIdentifiers, props.translate)
  const saveButton = props.isCreate
    ? <SaveButton label={props.translate('ACTIONS.SAVE')} sx={btnStyles.success} icon={<SaveOutlined />} />
    : props.permissions.save && <SaveButton label={props.translate('ACTIONS.SAVE')} sx={(props.record?.compte_actif) ? btnStyles.success : disabledBtnStyles.success} icon={<SaveOutlined />} disabled={!props.record?.compte_actif} />

  return (
    <Toolbar {...props}>
      {saveButton}
      {props.permissions.resetPassword && resetPassword}
      {props.permissions.sendIdentifiers && sendIdentifiers}
      {props.permissions.disableAccount && enableDisableAccount}
    </Toolbar>
  )
};

const ResetPasswordButton = (adresse_mail, flagCompteActif, translate) => {
  const notify = useNotify();
  const [resetPassword, { isLoading, error, data }] = useCreate();

  useEffect(() => {
    if (!isLoading && !error && data)
      notify(`${translate('RESTAURANTS.EMAIL_SENT_TO')} ${adresse_mail}.`, { type: 'success' });
  }, [isLoading])

  const handleResetPassword = () => {
    resetPassword(`authentication/forgot-password`, { data: { email: adresse_mail, role: 'user' } });
  }

  return (
    <Button label={translate('COMMON.PASSWORD_RENEW')} sx={(flagCompteActif) ? btnStyles.primary : disabledBtnStyles.primary} onClick={handleResetPassword} disabled={!flagCompteActif}>
      <Lock />
    </Button>
  );
};

const EnableDisableAccountButton = (compte_actif, onClick, translate) => {
  return (
    <Button label={translate((compte_actif) ? 'COMMON.ACCOUNT_DESACTIVATE' : 'COMMON.ACCOUNT_ACTIVATE')} sx={btnStyles.danger} onClick={onClick}>
      <ToggleOff />
    </Button>
  );
};

const SendIdentifiers = (flagCompteActif, onClick, translate) => {
  return (
    <Button label={translate('RESTAURANTS.SEND_IDS')} sx={(flagCompteActif) ? btnStyles.warning : disabledBtnStyles.warning} onClick={onClick} disabled={!flagCompteActif}>
      <RestartAlt />
    </Button>
  );
};

//#endregion Toolbar

//#region Form

const AgenceForm = ({ show, label, choices, disabled }) => {
  if (!show) return null;

  return (
    <SelectInput
      fullWidth
      label={label}
      source="id_agence"
      optionText="nom"
      disabled={disabled}
      choices={choices} />
  );
};

const CodeClient = ({ label, disabled }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
      <TextInput fullWidth source="code_client" label={label} disabled={disabled} />
    </Stack>
  );
};

const EmailField = ({ t, restaurants, hideMdp, disabled, onChange }) => {
  return (
    <Stack direction="column" sx={{ width: '100%' }} alignItems="baseline">
      <Stack direction="row" sx={{ width: '100%' }} alignItems="center">
        <TextInput fullWidth label={t('COMMON.EMAIL_ADDRESS')} source="adresse_mail" onChange={onChange} disabled={disabled} />
        {(hideMdp) && <Check sx={{ color: colors.success }} />}
      </Stack>
      <AssociatedRestaurants restaurants={restaurants} />
    </Stack>
  )
};

const AssociatedRestaurants = ({ restaurants }) => {
  if (!restaurants) return null;

  return <Box>
    <Typography>Restaurants déjà associés à cet email : </Typography>
    <Box marginLeft={10}>
      {restaurants.map(r => {
        return <p key={r.nom} fontSize={15}>{r.nom}</p>;
      })}
    </Box>
  </Box>
};

const NameField = ({ label, disabled }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
      <TextInput fullWidth source="nom" label={label} disabled={disabled} />
    </Stack>
  );
};

const CaisseForm = ({ show, label, choices, disabled }) => {
  if (!show) return null;

  return (
    <SelectInput fullWidth label={label} source="caisse" optionText="nom" choices={choices} disabled={disabled} />
  );
};

const TipsForm = ({ show, label, disabled }) => {
  if (!show) return null;

  return (
    <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
      <BooleanInput label="" source="pourboire_active" disabled={disabled} />
      <Typography>{label}</Typography>
    </Stack>
  );
};

const PaygreenForm = ({ t, show, title, display, onChange, disabled, isPaygreenUsed, nbReminds, requestDate, isNotAdmin, record }) => {
  if (!show) return null;

  const shop_id_paygreen = (record.shop_id_paygreen) ? record.shop_id_paygreen.replace(/./g, '*') : (record.identifiant_paygreen?.shop_id) ? record.identifiant_paygreen.shop_id.replace(/./g, '*') : "";
  const secret_key_paygreen = (record.secret_key_paygreen) ? record.secret_key_paygreen.replace(/./g, '*') : (record.identifiant_paygreen?.secret_key) ? record.identifiant_paygreen.secret_key.replace(/./g, '*') : "";
  const public_key_paygreen = (record.public_key_paygreen) ? record.public_key_paygreen.replace(/./g, '*') : (record.identifiant_paygreen?.public_key) ? record.identifiant_paygreen.public_key.replace(/./g, '*') : "";
  
  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source="enabled_paygreen" fullWidth onChange={onChange} disabled={disabled} checked={isPaygreenUsed} options={{checked: isPaygreenUsed}} />
        <Typography>{title}</Typography>
      </Stack>
      <Grid container>
        <RemindDate requestDate={requestDate} nbReminds={nbReminds} style={{ display }} />
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.SHOP_ID')} style={{ display }} source={isNotAdmin ? "" : "shop_id_paygreen"} defaultValue={shop_id_paygreen} disabled={disabled} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.KEY_SECRET')} style={{ display }} source={isNotAdmin ? "" : "secret_key_paygreen"} defaultValue={secret_key_paygreen} disabled={disabled} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.KEY_PUBLIC')} style={{ display }} source={isNotAdmin ? "" : "public_key_paygreen"} defaultValue={public_key_paygreen} disabled={disabled} />
        </Grid>
      </Grid>
    </>
  );
};

const PayxpertForm = ({ t, display, isCreate, onChange, disabledToggle, nbReminds, requestDate, isNotAdmin, record }) => {
  const id_payxpert = (record.id_payxpert) ? record.id_payxpert.replace(/./g, '*') : (record.identifiant_payxpert?.id) ? record.identifiant_payxpert.id.replace(/./g, '*') : "";
  const cle_api_payxpert = (record.cle_api_payxpert) ? record.cle_api_payxpert.replace(/./g, '*') : (record.identifiant_payxpert?.cle_api) ? record.identifiant_payxpert.cle_api.replace(/./g, '*') : "";
  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source="enabled_payxpert" fullWidth onChange={onChange} disabled={disabledToggle || isCreate} defaultValue={true} />
        <Typography>{t('COMMON.PAYXPERT_ID')}</Typography>
      </Stack>
      <Grid container>
        <RemindDate requestDate={requestDate} nbReminds={nbReminds} style={{ display }} />
        <Grid item xs={12}>
          <TextInput fullWidth label="Id" source={isNotAdmin ? "" : "id_payxpert"} defaultValue={id_payxpert} style={{ display }} disabled={disabledToggle} />
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth label={t('COMMON.KEY_API')} source={isNotAdmin ? "" : "cle_api_payxpert"} defaultValue={cle_api_payxpert} style={{ display }} disabled={disabledToggle} />
        </Grid>
      </Grid>
    </>
  );
};

const RemindDate = ({ requestDate, nbReminds, style }) => {
  if (!nbReminds) return null;

  const date = formatDate(requestDate);

  return (
    <Grid item xs={12} mb={2}>
      <Typography style={style}>Date Relance ({nbReminds}): {date}</Typography>
    </Grid>
  );
};

const NpScoreField = ({ label, disabled}) => {
  return (
    <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
      <BooleanInput label="" source="nps_score_actif" disabled={disabled} />
      <Typography>{label}</Typography>
    </Stack>
  );
};

const TicketRestaurantForm = ({ show, t, title, display, onChange, isCreate, record, paygreenNotActivatedbefore, disabled}) => {
  if (!show) return null;
  const source_paygreen = (isCreate || (paygreenNotActivatedbefore)) ? "enabled_paygreen" : "use_paygreen";
  return (
    <>
      <Stack direction="row" sx={{ width: '100%' }} alignItems="baseline">
        <BooleanInput label="" source={source_paygreen} onChange={onChange} disabled={disabled} />
        <Typography>{title}</Typography>
      </Stack>
      <Grid container spacing={{xs: 2, md: 4}}>
        <Grid item xs={12} md={6} lg={5}>
          <TextInput fullWidth label={t('COMMON.SIGNATORY_FULLNAME')} style={{ display }} source="client_name" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <TextInput fullWidth label={t('COMMON.ETABLISHMENT_BUSINESS_NAME')} style={{ display }} source="raison_social" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <TextInput fullWidth label={t('COMMON.SIGNATORY_EMAIL')} style={{ display }} source="signataire_email" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextInput fullWidth label={t('COMMON.SIGNATORY_MOBILE_PHONE')} style={{ display }} source="signataire_phone" disabled={disabled} />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextInput fullWidth label={t('COMMON.SIRET_NUM')} style={{ display }} source="numero_siret" disabled={disabled} />
        </Grid>
      </Grid>
    </>
  );
};

const InfosField = ({title, label, disabled}) => {

  return (
    <Grid container spacing={{ xs: 2, md: 4 }}>
      <Grid item xs={12} lg={10}>
        <Divider sx={{ ...adminForm.divider, width: '100%' }} textAlign="left" variant="fullWidth">
          <Chip size="small" sx={adminForm.chip} color="primary" label={title} />
        </Divider>
        <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="baseline">
          <TextInput
            label={label}
            source="comment"
            size="large"
            multiline={true}
            fullWidth={true}
            disabled={disabled}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

//#endregion Form


export default RestaurantUpsertContent;