import { defaultTheme } from "react-admin";
import { createTheme } from '@mui/material/styles';
import { colors } from "../styles/Styles";


export const theme = createTheme(
   {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: "rgb(3, 201, 215)",
        light: "rgba(3, 201, 215, 0.5)",
      },
      secondary: {
        main: 'rgb(251, 150, 120)',
        light: 'rgba(251, 150, 120, 0.5)',
      },
      warning: {
        main: 'rgb(254, 201, 15)',
        light: 'rgba(254, 201, 15, 0.5)',
      },
      danger: {
        main: 'rgb(228, 106, 118)',
        light: 'rgba(228, 106, 118, 0.5)',
      },
      success: {
        main: 'rgb(0, 194, 146)',
        light: 'rgba(0, 194, 146, 0.5)',
      },
      neutral: {
        main: 'rgb(255, 255, 255)'
      }
    },
    typography: {
      fontFamily: "DM Sans",
    },
    components: {
      ...defaultTheme.components,
      RaMenuItemLink: {
          styleOverrides: {
            root: {
                backgroundColor: "transparent",
                margin: 0,
                fontWeight: 300,
                fontSize: '1rem',
                lineHeight: 3,
                marginLeft: 5,
                marginRight: 5,
                marginTop: 10,
                marginBottom: 10,
                padding:'0 10px',
                borderRadius: '9px',
                color:'white',
                "& .RaMenuItemLink-icon": {
                  color: "white",
                },
                "&.RaMenuItemLink-active": {
                  color: 'white'
                }
            }
         }
      },
      RaToolbar: {
        styleOverrides: {
          root: {
             backgroundColor:'white'
          }
        }
      },
      RaCreate: {
        styleOverrides: {
          root: {
            '& .RaCreate-card': {
              boxShadow: 'none !important'
            }
          },
          
        }
      },
      RaEdit: {
        styleOverrides: {
          root: {
            '& .RaEdit-card': {
              boxShadow: 'none !important'
            }
          },
          
        }
      },
      RaList : {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            padding: "5px",
            boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
            borderRadius: 20,
            color: 'rgb(148, 157, 178)',
            '& .RaList-main': {
              overflowX: 'scroll'
            },
            '& .RaList-content': {
              boxShadow:'none'
            }
          }
        }
      },
      RaDatagrid : {
        styleOverrides: {
          root: {
            boxShadow:'none',
            '& .RaDatagrid-table': {
              border: 'none',
              padding: "30px",
            },
            '& .RaDatagrid-headerCell': {
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: "DM Sans",
              fontWeight: 600,
              fontSize: "0.875rem",
              lineHeight: "1.5rem",
              padding: 15,
              whiteSpace: 'nowrap',
              '&.MuiTableCell-alignRight': { flexDirection: 'row'},
              '& .MuiTableSortLabel-root svg' : { display: 'inline'},
              '& .MuiTableSortLabel-icon': { fontSize: '18px'}
            },
            '& .RaDatagrid-rowCell': {
              color: '#555555', 
              padding: "15px",
              whiteSpace: 'nowrap',
              '& .firstEl': {
                textAlign: 'right !important'
              },
              '&.MuiTableCell-alignRight': { paddingRight: '42px'},
              '& .redFlag': {
                color: `${colors.success} !important`
              },
              '& .greenFlag': {
                color: `${colors.danger} !important`
              },
            },
            '& .RaDatagrid-headerRow': {
              '& th:nth-of-type(1) .MuiCheckbox-root': { 
                color: "transparent",
                border: "1px solid rgb(0 0 0 / 35%)",
                width: 18,
                height: 18,
                borderRadius: 5,
                },
              '& th:nth-of-type(1) .MuiCheckbox-colorPrimary.Mui-checked': {
                color: 'rgb(3, 201, 215)',
              }
            }
          },
        }
      },
      RaListToolbar:{
        styleOverrides: {
          root: {
            alignItems: 'center'
          }
        }
      },
      RaFilterForm: {
        styleOverrides: {
          root: {
            alignItems: 'center'
          }
        }
      },
      MuiAppBar : {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            boxShadow:"rgb(90 114 123 / 11%) 0px 7px 30px 0px !important",
            color:'rgb(33 33 33) !important',
            paddingLeft: '1%',
            paddingRight: '1%'
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            // height:'64px'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            background: 'white'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            // '& .MuiOutlinedInput-notchedOutline': {borderColor: 'transparent !important'} 
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingRight: '42px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedSuccess': {
              color:'white'
            }
          } 
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.MuiIconButton-colorPrimary': {
              backgroundColor: 'rgb(61 201 215 / 25%)'
            },
            '&.MuiIconButton-colorWarning': {
              backgroundColor: 'rgb(242 192 2 / 25%)'
            },
            '&.MuiIconButton-colorError': {
              backgroundColor: 'rgb(212 46 46 / 25%)'
            },
            '&.MuiIconButton-colorSuccess': {
              backgroundColor: 'rgb(47 194 146 / 25%)'
            }
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '& span:first-letter': {
              textTransform: 'uppercase'
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides : {
          root: {
            '& .MuiInputBase-adornedStart': {
              paddingRight: '39px !important'
            },
            '& .MuiAutocomplete-input': {
              minWidth: '10px !important'
            }
          },
        }
      }
    }
  }
);