import * as React from "react";
import CreateComponent from "../core/CreateComponent";
import UtilisateurUpsertContent from "./UpsertContent";
import { useTranslate } from 'react-admin';

const AdminUsersCreate = props => {
  const translate = useTranslate();

  return(
    <CreateComponent
      title={translate('USERS.TITLE')}
      breadcrumb="USERS.TITLE_CREATE"
      notifyname="USERS.NAME"
      notifyitem="adresse_mail"
      {...props}
    >
      <UtilisateurUpsertContent translate={translate}/>
    </CreateComponent>
  )
};

export default AdminUsersCreate;