import React from 'react';
import Chart from 'react-apexcharts';
import { dashboardStyles } from '../../styles/Styles';
import {useTranslate} from 'react-admin';
import { isIntNumber, formatNumber } from '../../utils/utils';

const formatDataForScoresStats = (data, translate) => {
  const note = (typeof data !== 'undefined') ? data : 0;
  const formattedNote =  (note * 100) / 5
    const result = [formattedNote];
    return {
        labels: [translate('SCORES.AVERAGE')],
        series: {
            values : result
        }
    }
}


const ScoresDonut = (props) => {
  const translate = useTranslate()
  const formatedData = formatDataForScoresStats(props.data, translate);
  
  const options = {
    noData: {
      text: translate('COMMON.NO_DATA_ON_RANGE'),
      align: 'center',
      verticalAlign: 'top',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    },
    labels: formatedData.labels,
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            fontSize: '30px',
            color: 'gray',
            formatter: function (val) {
              const value = ((val * 5) / 100);
              const precision = (isIntNumber(value)) ? 0 : 2;
              return formatNumber(value, precision, '');
            }
          }
        }
      }
  },
  };
  return (
    <div className="donut">
      <Chart className="graphContent" options={options} series={formatedData.series.values} type="radialBar" style={dashboardStyles.statsGraph.donutPie} />
    </div>
  );
};

export default ScoresDonut;