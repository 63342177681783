import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { stringify } from 'query-string';
import { convertFileToBase64 } from './utils';

const apiUrl = process.env.REACT_APP_URL_API;

const RESOURCES_WITH_INPUT_FILE = [
  'cartes/:idRestaurant'
]

const httpClient = (url, options = {}) => {
  const token = options.token || localStorage.getItem('token');
  options.headers = new Headers({
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
    'x-from-origin': 'radmin',
  });
  // add your own headers here
  // options.headers.set('Access-Control-Allow-Origin', '*');
  // options.headers.set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,OPTIONS');

  return fetchUtils.fetchJson(url, options);
};

// Additionnal method for request the api
const post = async (resource, params = {}) => {
  const body = (params.data) ? JSON.stringify(params.data) : null;
  const token = params.token || localStorage.getItem('token');
  try {
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body,
      token,
    });
    return ({
      data: json,
    });
  } catch (err) {
    throw (err);
  }
};

const create = async (resource, params) => {
  let body = (params.data) ? params.data : null;
  const token = (params.meta?.token) || localStorage.getItem('token');
  try {
    if(RESOURCES_WITH_INPUT_FILE.includes(resource))
    {
      return Promise.resolve(convertFileToBase64(params.data.inputFile)).then(async result => {
        body.inputFile.base64 = result;
        body = JSON.stringify(body);
        const { json } = await httpClient(`${apiUrl}/${resource}`, {
          method: 'POST',
          body,
          token,
        });
        return ({
          data: json,
        });
      })
    }
    else
    {
      body = JSON.stringify(body);
      const { json } = await httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body,
        token,
      });
      return ({
        data: json,
      });
    }
    
    
  } catch (err) {
    throw (err);
  }
}

const update = async (resource, params) => {
  let body = (params.data) ? params.data : null;
  const token = params.token || localStorage.getItem('token');
  try {
    if(RESOURCES_WITH_INPUT_FILE.includes(resource) && params.data.inputFile)
    {
      return Promise.resolve(convertFileToBase64(params.data.inputFile)).then(async result => {
        body.inputFile.base64 = result;
        body = JSON.stringify(body);
        const { json } = await httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
          method: 'PUT',
          body,
          token,
        });
        return ({
          data: json,
        });
      })
    }
    else
    {
      body = JSON.stringify(body);
      const { json } = await httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
        method: 'PUT',
        body,
        token,
      });
      return ({
        data: json,
      });
    }
  } catch (err) {
    throw (err);
  }
}

const get = async (resource, params = {}) => {
  const body = (params.data) ? JSON.stringify(params.data) : null;
  try {
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: 'GET',
      body,
    });

    return ({
      data: json,
    });
  } catch (err) {
    throw (err);
  }
};

/**
 * Custom getList pour traiter les appel de type "maResource/:idQuelconque"
 * Pour l'instant on n'utilises que idRestaurant stocké dans le localStorage
 * 
 * @param {String} resource : nom de la resource
 * @param {Object} params : params des liste, obligatoire
 * @returns un string pour appeller la route de l'api
 */
const getList = async (resource, params = {}) => {
  // construction des query params
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const query = {
    _start: (page && perPage) ? (page - 1) * perPage : 0,
    _end: (page && perPage) ? page * perPage : null,
    _order: order,
    _sort: field,
    ...params.filter,
    ...params.pagination,
  };
  // détermination de l'url avec params
  const urlWithQuery = getUrlWithQueryByResource(resource);
  
  try {
    const { json, headers } = await httpClient(`${apiUrl}/${urlWithQuery}?${stringify(query)}`, {
      method: 'GET',
    });

    if (!headers.has('x-total-count')) {
      throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
    }

    return ({
      data: json,
      total: Number(headers.get('x-total-count')),
    });
  } catch (err) {
    throw (err);
  }
};

/**
 * Remplace les params par leur valeur. Pour l'instant il n'y a que idRestaurant qui est passé en param
 * Cette valeur est stocké dans le localStorage
 * 
 * @param {String} url : nom de la resource
 * @returns la bonne url avec le éventuel param valorisé
 */
const getUrlWithQueryByResource = (url) => {
  const splitedUrl = url.split('/');
  const resource = splitedUrl[0];
  const args = splitedUrl.map(a => {
    if (a === resource) return false;
    if (a.indexOf(':') > -1) return localStorage.getItem(a.slice(1));
    return a;
  }).filter(q => q);
  const params = args.join('/');

  return args.length > 0 ? `${resource}/${params}` : resource;
}

const dataProvider = jsonServerProvider(apiUrl, httpClient);
dataProvider.post = post;
dataProvider.get = get;
dataProvider.getList = getList;
dataProvider.create = create;
dataProvider.update = update;

export default dataProvider;