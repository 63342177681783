import * as React from "react";
import {
  Toolbar,
  SaveButton,
  SimpleForm,
  TextInput,
  SelectInput,
  useTranslate,
  useGetList,
  Loading
} from 'react-admin';
import { btnStyles, inputStyles } from '../../styles/Styles';
import { SaveOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import errorSchemas from "../../utils/Validations/handleErrorSchemas/errorSchemas";
import { joiTablesRestaurantSchema } from "../../utils/Validations/schemas/tablesRestaurantSchema";
import { useState, useEffect } from "react";

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label={props.translate('ACTIONS.SAVE')} sx={btnStyles.success} icon={<SaveOutlined />} />
    </Toolbar>
  )
};

const NonInput = React.memo(function NonInput({ children }) {
  return children;
});

const TablesRestaurantCreateContent = props => {
  const idRestaurant = localStorage.getItem('idRestaurant');
  const [tables, setTables] = useState();
  const filter = {restaurant_id : idRestaurant}
  const { data, isLoading } = useGetList(
    'qr-codes/list-all',
    {
      filter
    }
  );
  const t = useTranslate();

  useEffect(() => {
    if (!isLoading) setTables(data);
  },[isLoading, data]);
  
  const filterRestaurants = { noPagination: true };
  const { data: restaurants, isLoading: isLoadingRestaurants } = useGetList(
    'restaurants',
    {
      filter: filterRestaurants
    }
  );

  const convertStringToNumber = value => {
    const float = parseFloat(value);
    return isNaN(float) ? null : float;
  };

  const uniqTableValidator = (table) => {
    if (!table || !tables) return 'VALIDATION.MANDATORY.TABLE_NUM';
    const tableExist = tables.some((t) => t.id_table === table);
    if (tableExist) return 'VALIDATION.EXISTS.TABLE_NUM';
    return undefined;
  };

  const validateCreation = (values) => {
    const idTableToString = values?.id_table?.toString();

    const isTableExist = uniqTableValidator(idTableToString);
    if(isTableExist) return { id_table: isTableExist };

    const vals = {...values, id_table: idTableToString};
    return errorSchemas(joiTablesRestaurantSchema, vals);
  }
  if (isLoadingRestaurants || !restaurants) return <Loading />;
  return (
    <SimpleForm mode="onChange" toolbar={<CustomToolbar translate={t} />} {...props} validate={validateCreation}>
      <NonInput>
        <SelectInput label="Restaurant" source="id_restaurant" optionText="nom" choices={restaurants} defaultValue={idRestaurant} sx={inputStyles.hidden} />
        <Typography>{t('COMMON.TABLE_NUM_QR_CODE')}</Typography>
        <TextInput label={t('COMMON.TABLE_NUM_FULL')} source="id_table" parse={convertStringToNumber} />
      </NonInput>
    </SimpleForm>
  );
};

export default TablesRestaurantCreateContent;