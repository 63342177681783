import * as React from "react";
import {
  TextField,
  EditButton,
  FunctionField,
  useTranslate,
  useRecordContext,
  usePermissions,
  useResourceContext
} from 'react-admin';
import { Box } from "@mui/material";
import ListComponent from "../core/ListComponent";
import { muiIconStyles } from "../../styles/Styles";
import {
  EmailOutlined,
  NotesOutlined,
  Settings,
  LocalOffer,
  AutorenewOutlined,
  FiberManualRecord,
  Business,
} from '@mui/icons-material';
import { _ROLES } from "../../constantes";
import { getRoleAndPermissions } from "../../utils/utils";
import ListCellHeader from "../core/ListCellHeader";


const UtilisateursList = () => {
  const globalPermissions = usePermissions();
  const resource = useResourceContext();
  const t = useTranslate();
  const { permissions } = getRoleAndPermissions(globalPermissions.permissions, resource);
  const roleChoices = _ROLES.map(p => ({ id: p.id, name: t(p.name) }));

  if (!permissions) return null;

  return (
    <ListComponent
      sort={{ field: 'adresse_mail', order: 'ASC' }}
    >
      <TextField source="adresse_mail" label={<ListCellHeader icon={<EmailOutlined sx={muiIconStyles.primary} />} text={t('COMMON.EMAIL_ADDRESS')} />} />
      <TextField source="nom" label={<ListCellHeader icon={<NotesOutlined sx={muiIconStyles.secondary} />} text={t('COMMON.NAME')} />} />
      <TextField source="prenom" label={<ListCellHeader icon={<NotesOutlined sx={muiIconStyles.warning} />} text={t('COMMON.FIRSTNAME')} />} />
      <Rolefield source="role" label={<ListCellHeader icon={<LocalOffer sx={muiIconStyles.success} />} text={t('COMMON.ROLE')} />} choices={roleChoices} />
      <TextField source="nom_agence" label={<ListCellHeader icon={<Business sx={muiIconStyles.warning} />} text={t('COMMON.AGENCY')} />} />
      <StatusField translate={t} label={<ListCellHeader icon={<AutorenewOutlined sx={muiIconStyles.secondary} />} text={t('COMMON.STATUS')} />} />
      {permissions.edit && <EditField label={<ListCellHeader icon={<Settings sx={muiIconStyles.primary} />} text={t('ACTIONS.EDIT')} />} />}
    </ListComponent>
  );
};

const Rolefield = props => <FunctionField {...props} render={r => props.choices.find(p => p.id === r.role)?.name} />;
const StatusField = ({ translate }) => {
  const record = useRecordContext();
  const status = (record.compte_actif) 
    ? translate('COMMON.ACTIVE')
    : translate('COMMON.DISABLED');
  const color = (record.compte_actif) 
    ? '#04cad6'
    : '#00000042';
  

  return (
    <Box style={{ display: "flex", alignItems: "center", color, }} sx={{ '& span:first-letter': { textTransform: 'uppercase' } }}>
      <FiberManualRecord />
      <span style={{ whiteSpace: "nowrap" }}>{status}</span>
    </Box>
  );
};
const EditField = ({ label, ...props }) => <EditButton label="" {...props} />;


export default UtilisateursList;