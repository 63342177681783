import { Typography, Modal, Button, Box } from '@mui/material';
import { useNotify, BooleanInput, SimpleForm, useRedirect, useTranslate, useCreate, useResourceContext } from 'react-admin';
import { modalBodyStyle, smallModalStyle } from '../../styles/Styles';
import { useState, useEffect } from "react";

const ModalConfirmationDisableAccount = ({ isOpen, onClose, nom, operation, id }) => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();
  const [hideAccount, setHideAccount] = useState(false);
  const [toggleAccount, { isLoading: isLoadingToggleAccount, error: errorToggleAccount, data: dataToggleAccount }] = useCreate();

  useEffect(() => {
    if (!isLoadingToggleAccount && !errorToggleAccount && dataToggleAccount) {
      const message = (operation === 'enable')
        ? 'USERS.ACCOUNT_ACTIVATED'
        : 'USERS.ACCOUNT_DESACTIVATED';
      notify(`${translate(message, { account: nom })}`, { type: 'success' });
      onClose();
      redirect('list', resource);
    }
  }, [isLoadingToggleAccount]);

  const handleChangeCheckbox = (v) => {
    setHideAccount(v.target.checked)
  }
  const RenderCheckbox = () => {
    return <BooleanInput id='disableAccountCheckbox' source="hide_account" label={translate('USERS.ACCOUNT_NOMORE_SHOW')} onChange={handleChangeCheckbox} />
  }
  const handleEnableDisableAccount = () => {
    const compteActif = (operation === 'enable') ? true : false;
    const hideAccountValue = (operation === 'enable') ? false : hideAccount;
    toggleAccount(`${resource}/toggle-compte-actif`, { data: { compteActif, hideAccountValue, id, nom } });
  }

  const action = translate((operation === 'enable') ? 'ACTIONS.ACTIVATE' : 'ACTIONS.DESACTIVATE');
  const message = translate('USERS.CONFIRM_ACTION_ACCOUNT', { action });

  return <Modal
    open={isOpen}
    onClose={onClose}
  >
    <Box sx={smallModalStyle}>
      <Box sx={modalBodyStyle}>
        <Typography variant="h6">{message}</Typography>
        {(operation === 'disable') && <SimpleForm toolbar={false}><RenderCheckbox /></SimpleForm>}
        <Box>
          <Button size="small" color="success" variant="contained" sx={{ m: 1 }} onClick={handleEnableDisableAccount}>{translate('ACTIONS.SUBMIT')}</Button>
          <Button size="small" color="error" variant="contained" sx={{ m: 1 }} onClick={onClose}>{translate('ACTIONS.CANCEL')}</Button>
        </Box>
      </Box>
    </Box>
  </Modal>
};



export default ModalConfirmationDisableAccount;