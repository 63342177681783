import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GridItem from '../../styles/GridItem';
import Grid from '@mui/material/Grid';
import { SimpleForm, TextInput, Toolbar, SaveButton, useNotify, Notification, useRedirect, useTranslate, useCreate } from 'react-admin';
import { btnStyles, loginPageStyles, inputStyles } from '../../styles/Styles';
import logo from '../../assets/images/logo-banana-split.svg';
import { useLocation } from "react-router-dom";

const ResetPassowrd = (props) => {
  const { pathname } = useLocation();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [formErrors, setFormErrors] = useState(false);
  const [resetPassword, { isLoading: isLoadingResetPassword, error: errorResetPassword, data: dataResetPassword }] = useCreate();

  useEffect(() => {
    if(!isLoadingResetPassword && !errorResetPassword && dataResetPassword)
    {

      if (dataResetPassword) {
        if(dataResetPassword.success === false)
          return notify(dataResetPassword.message, { type: 'error' });
        notify(translate('COMMON.PASSWORD_UPDATED'), { type: 'success' });
        redirect('/login');
      }
      else {
        notify(translate('COMMON.ERROR_PASSWORD_RESET'), { type: 'error' });
      }
    } 
    else if(errorResetPassword)
    {
      notify(translate('COMMON.ERROR_PASSWORD_RESET'), { type: 'error' });
      redirect('/login');
    }

  }, [isLoadingResetPassword]);

  const currentUrl = pathname;
  const token = currentUrl.substring(currentUrl.indexOf("password/") + 9);
  const handleSendPassword = (data) => {
    if(!formErrors)
      resetPassword(`authentication/password/change`, { data: { password: password  }, meta :{token}  });
  };

  const CustomToolbar = (props) => {
    const handleSubmit =  handleSendPassword;
    return (
      <Toolbar {...props} style={{ backgroundColor: 'transparent', padding: '0 16px', margin: 0 }}>
        <SaveButton label={translate('ACTIONS.SAVE')} onClick={handleSubmit} {...props} variant="outlined" sx={{ ...btnStyles.secondary, ...loginPageStyles.btn, margin: 0 }} />
      </Toolbar>
    );
  };

  const validateChange =(values) =>{
    const errors= {};

    if (!values.password) errors.password = 'VALIDATION.MANDATORY.PASSWORD';
    else if (values.password && values.password.length < 6) errors.password = 'VALIDATION.TYPE.PASSWORD_MIN';
    else if (values.password && values.password.length > 50) errors.password = 'VALIDATION.TYPE.PASSWORD_MAX';

    if (!values.confirmPassword) errors.confirmPassword = 'VALIDATION.MANDATORY.PASSWORD_CONFIRM';
    else if (values.confirmPassword !== values.password) errors.confirmPassword = 'VALIDATION.TYPE.PASSWORD_DIFFERENT';

    if(Object.keys(errors).length > 0)
      setFormErrors(true);
    else
      setFormErrors(false);
      
    return errors;
  }
  const helperPassword = translate('VALIDATION.TYPE.PASSWORD_LENGHT');
  
  return (
    <Box>
      <Grid container spacing={2} sx={loginPageStyles.loginContainer} >
        <Grid item lg={4} md={3} sm={4} xs={12}  >
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}  >
          <GridItem position="middle" >
            <Typography variant="h3" component="div" sx={loginPageStyles.logo}>
              <img src={logo} alt="logo-banana-split" style={loginPageStyles.logoImage} />
            </Typography>
            <SimpleForm mode="onChange" toolbar={<CustomToolbar />} validate={validateChange}>
              <Typography variant="overline" sx={loginPageStyles.label}>{translate('COMMON.RESET_PASSWORD_REQUEST')}</Typography>
              <br />
              <Typography variant="body1" sx={loginPageStyles.label}>{translate('COMMON.PASSWORD_NEW')}</Typography>
              <TextInput autoComplete="new-password" autoFocus={false} fullWidth={true} variant="outlined" source="password" label="" onChange={e => setPassword(e.target.value)} value={password} type="password"  helperText={helperPassword} sx={inputStyles.withHelper} />
              <Typography variant="body1" sx={loginPageStyles.label}>{translate('COMMON.PASSWORD_CONFIRM')}</Typography>
              <TextInput autoComplete="new-password" autoFocus={false} fullWidth={true} variant="outlined" source="confirmPassword" label="" onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} type="password"  />
            </SimpleForm>
          </GridItem>
        </Grid>
        <Grid item lg={4} md={3} sm={12} xs={12}  >
          <Notification />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPassowrd;
