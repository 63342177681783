const errorSchemas = (schema, values) => {
  let errors = {};
  const result = schema.validate(values, { abortEarly: false });
    if(typeof result.error !== "undefined") {
      result.error.details.map(index => {
        // console.log('index-------', index) // voir le type des err joi pour les traiter
        const message = {
          [index.path.join('.')]: index.message 
        }
        // console.log("err------", errors)
        errors = {...errors, ...message};
        return errors;
      })
    }
    return errors; 
}

export default errorSchemas;
