import * as React from "react";
import {
  Toolbar,
  SaveButton,
  SimpleForm,
  TextInput,
  useTranslate,
  FileInput,
  FunctionField,
  useRecordContext,
  useGetList
} from 'react-admin';
import { btnStyles, inputStyles } from '../../styles/Styles';
import { SaveOutlined } from '@mui/icons-material';
import { Typography, Stack, Box } from '@mui/material';
import { useState } from "react";
import { joiCartesSchema } from "../../utils/Validations/schemas/cartesSchema";
import errorSchemas from "../../utils/Validations/handleErrorSchemas/errorSchemas";

const NonInput = React.memo(function NonInput({ children }) {
  return children;
});

const CartesEditContent = props => {
  const record = useRecordContext();
  const isLoggedInByRoleTech = (localStorage.getItem('loggedInByRole') === 'tech');
  const idRestaurant = localStorage.getItem('idRestaurant');
  const t = useTranslate();
  const [apercu, setApercu] = useState(`${record.url}#toolbar=0&navpanes=0&scrollbar=0&page=1&view=fitV`);
  const [nomMenu, setNomMenu] = useState("");
  const { data: allMenus, isLoading: isLoadingAllMenus } = useGetList(`cartes/${idRestaurant}`);

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton label={props.translate('ACTIONS.SAVE')} sx={btnStyles.success} icon={<SaveOutlined />} disabled={isLoggedInByRoleTech} />
      </Toolbar>
    )
  };

  const generateView = (record) => {
    setApercu(`${record.src}#toolbar=0&navpanes=0&scrollbar=0&page=1&view=fitV`);
    setNomMenu(record.rawFile.name);
  }

  const clearView = (value) => {
    if (!value)
      setApercu(`${record.url}#toolbar=0&navpanes=0&scrollbar=0&page=1&view=fitV`)
  }

  const uniqueNameValidator = (nom) => {
    if(allMenus.filter(m => m.id !== record.id).find(m => m.nom.toLowerCase().trim() === nom.toLowerCase().trim()))
      return t('VALIDATION.EXISTS.NAME')
  }

  const validateEdition = (values) => {
    const isNameExists = uniqueNameValidator(values.nom);
    const errors = errorSchemas(joiCartesSchema, values);
    if(isNameExists)
      return {...errors, nom: isNameExists};
    return errors;
  }

  if (isLoadingAllMenus) return null;

  return (<SimpleForm mode="onChange" toolbar={<CustomToolbar translate={t} />} {...props} validate={validateEdition} >
    <Stack
      direction="row"
      spacing={2}
      width={'100%'}
    >
      <Box style={{ flex: 1 }}>
        <NonInput>
          <Typography>Nom menu</Typography>
          <TextInput source="nom" label="" style={{ display: 'block' }} disabled={isLoggedInByRoleTech} />
          <FileInput fullWidth={false} source="inputFile" sx={inputStyles.main} label="Fichier" accept="application/pdf" multiple={false} onChange={e => { clearView(e) }} placeholder={t('COMMON.CLICK_TO_CHANGE_FILE')} options={{disabled: isLoggedInByRoleTech}} >
            <FunctionField label="Name" render={record => { generateView(record); return nomMenu; }} />
          </FileInput>
          <TextInput source="id_restaurant" sx={inputStyles.hidden} disabled={isLoggedInByRoleTech} />
          <TextInput source="url" sx={inputStyles.hidden} disabled={isLoggedInByRoleTech} />
        </NonInput>
      </Box>
      <Box style={{ flex: 1 }}>
        {apercu && <Box>
          <Typography>{t('COMMON.PREVIEW')}</Typography>
          <iframe src={apercu} frameBorder="0" width={'70%'} height={400} />
        </Box>}
      </Box>
    </Stack>
  </SimpleForm>
  );
};

export default CartesEditContent;