import * as React from 'react';
import {
  useTranslate,
  useResourceContext
} from 'react-admin';
import {
  Typography,
  Stack
} from '@mui/material';
import Restrictions from './Restrictions';
import TransactionsStatement from './TransactionsStatement';
import Box from '@mui/material/Box';
import Breadcrumb from '../../core/BreadCrumb';
import ListTitle from '../../core/ListTitle';

const AccountSettings = (props) => {
  const t = useTranslate();
  const resource = useResourceContext();


  return (
   <Box sx={{ flexGrow: 1 }}>
    <CustomBreadcrumb resource={resource} title={t('COMMON.ACCOUNT_SETTINGS')} breadcrumb={'_self'}  t={t} />
    <ListTitle content={t('COMMON.ACCOUNT_SETTINGS')} />
    <Stack direction={'column'} spacing={2}>
      <Setting index={1} children={<Restrictions title={t('SETTINGS.ACCOUNT.RESTRICTIONS')} />} />
      <Setting index={2} children={<TransactionsStatement title={t('SETTINGS.STATEMENT.TRANSACTIONS_STATEMENT')} />} />
    </Stack>
    </Box>
  );
};

const Setting = ({ index, title, children }) => {
  const id =`panel${index}`;
  return (<Box key={id}>
      <Typography>{title}</Typography>
        {children}
      </Box>
  );
};

const CustomBreadcrumb = ({ resource, title, breadcrumb, t }) => {
    if (!breadcrumb) return false;
    const path = (breadcrumb === '_self') ? '' : `#/${resource}`;
    const child = (breadcrumb === '_self') ? null : t(breadcrumb);
    return <Breadcrumb label={title} path={path} child={child} />;
  };


export default AccountSettings;