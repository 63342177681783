import * as React from "react";
import EditComponent from "../core/EditComponent";
import UtilisateurUpsertContent from "./UpsertContent";
import { useTranslate } from 'react-admin';

const AdminUsersEdit = props => {
  const translate = useTranslate();

  return (
    <EditComponent
      title={translate('USERS.TITLE_EDIT')}
      breadcrumb='USERS.TITLE_EDIT'
      notifyname="USERS.NAME"
      notifyitem="adresse_mail"
      actions={false}
      {...props}
    >
      <UtilisateurUpsertContent translate={translate} />
    </EditComponent>
  );
};

export default AdminUsersEdit;