import React, { useState } from "react";
import { TextField, TextInput, DateField, DateInput, FunctionField, useTranslate, Loading, usePermissions, useResourceContext } from 'react-admin';
import ListComponent from "../core/ListComponent";
import { transactionsListStyles, muiIconStyles } from "../../styles/Styles";
import { EventOutlined, InfoOutlined, EuroOutlined, ReceiptOutlined } from '@mui/icons-material';
import { defaultWeekDateRangeFilter, formatNumber, getRoleAndPermissions } from "../../utils/utils";
import ListCellHeader from "../core/ListCellHeader";
import PayxpertDetails from "../Transactions/PayxpertDetails";

const AllTransactionsList = props => {
  const globalPermissions = usePermissions();
  const resource = useResourceContext();
  const { startFilter, endFilter } = defaultWeekDateRangeFilter();
  const [startDate, setStartDate] = useState(startFilter);
  const [endDate, setEndDate] = useState(endFilter);
  const { permissions } = getRoleAndPermissions(globalPermissions.permissions, resource);
  const translate = useTranslate();

  const handleChangeStartDate = (e, i) => {
    setStartDate(e.target.value);
  }
  const handleChangeEndDate = (e, i) => {
    setEndDate(e.target.value);
  }
  const mobileList = {
    primaryText: record => record.orderId,
    secondaryText: record => formatNumber(record.amount),
    tertiaryText: record => record.status,
  };

  const rowStyle = (record) => {
    let background;
    switch (record.statusKey) {
      case 'success':
        background = `rgb(190 248 181 / 59%)`;
        break;
      case 'refund':
        background = `papayawhip`;
        break;
      case 'partly_refunded':
        background = `papayawhip`;
        break;
      case 'refuse':
        background = `#ffdfdf`;
        break;
      default:
        background = '';
    }
    return { background };
  };

  const dateFilters = [
    <TextInput
      source="keyword"
      label={translate('ACTIONS.SEARCH')}
      alwaysOn
      sx={transactionsListStyles.searchInput}
      className="raTextInput"
      id="generalSearch"
      key="keyword"
    />,
    <DateInput
      source="start"
      label={translate('COMMON.START')}
      onChange={handleChangeStartDate}
      alwaysOn
      className="raDateInput"
      key="start"
    />,
    <DateInput
      source="end"
      label={translate('COMMON.END')}
      onChange={handleChangeEndDate}
      alwaysOn
      className="raDateInput"
      key="end"
    />
  ];

  if (!permissions) return null;

  return (
    <ListComponent {...props}
      mobilelist={mobileList}
      filters={dateFilters}
      perPage={25}
      sort={{ field: 'date_transaction', order: 'DESC' }}
      filterDefaultValues={{ start: startDate, end: endDate }}
      rowStyle={rowStyle}
      expand={<PayxpertDetails />}
      isRowExpandable={row => row.transactions?.length > 0}    
    >
      <DateField locales="fr-FR" source="date_transaction" showTime label={<ListCellHeader icon={<EventOutlined sx={muiIconStyles.primary} />} text={translate('COMMON.DATE')} />} />
      <TextField source="numero_restaurant" label={<ListCellHeader icon={<ReceiptOutlined sx={muiIconStyles.warning} />}  text={translate('RESTAURANTS.NUM')} />} />
      <TextField source="nom_restaurant" label={<ListCellHeader icon={<ReceiptOutlined sx={muiIconStyles.success} />}  text={translate('RESTAURANTS.NAME')} />} />
      <TextField source="service" label={<ListCellHeader icon={<ReceiptOutlined sx={muiIconStyles.primary} />}  text={translate('COMMON.SERVICE')} />} />
      <FunctionField source="montant" render={record => formatNumber(record.montant)} label={<ListCellHeader icon={<EuroOutlined sx={muiIconStyles.danger} />} text={translate('COMMON.AMOUNT')} />} textAlign="right" />
      <TextField source="statut" label={<ListCellHeader icon={<InfoOutlined sx={muiIconStyles.secondary} />} text={translate('COMMON.STATUS')} />} />
    </ListComponent>
  );
};

export default AllTransactionsList;